import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import TokenList from './TokenList';
import NewToken from './NewToken';
import { toast } from 'react-toastify';
import { ApiToken } from '../../../endpoints';

const TokenPage = () => {
  const [tokens, setTokens] = useState([]);
  const [displayCreator, setDisplayCreator] = useState(false);

  const receiveTokens = () => ApiToken.getAll().then(r => setTokens(r.sort((a,b) => b.id - a.id)));
  const hideCreator = () => setDisplayCreator(false);
  const showCreator = () => setDisplayCreator(true);

  useEffect(() => {
    receiveTokens();
  }, []);

  const handleCreateToken = ({ name, owner }) => {
    if (!name) {
      toast.error('Name is required');
      return;
    }
    if (!owner) {
      toast.error('Owner is required');
      return;
    }

    ApiToken.create(name, owner.id).then(() => {
      toast.success('Token created');
      receiveTokens();
    });
  };

  return (
    <Container>
      <h3>Developer tokens</h3>
      {displayCreator ? (
        <NewToken onCancel={hideCreator} onCreate={handleCreateToken} />
      ) : (
        <Button variant='success' onClick={showCreator}>
          New developer token
        </Button>
      )}
      <TokenList onChange={receiveTokens} tokens={tokens} />
    </Container>
  );
};

export default TokenPage;
