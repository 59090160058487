import React, { Component } from 'react';
import { Modal, Form, Button, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { login } from '../../actions/auth';
import { connect } from 'react-redux';
import { isNotEmpty } from '../../validators';

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) =>
    dispatch(login.request({ username: username, password: password })),
});

class Login extends Component {
  state = {
    username: '',
    password: '',
  };

  handleLogin = (e) => {
    e.preventDefault()
    this.props.login(this.state.username, this.state.password);
  };

  isValidForm = () => {
    return isNotEmpty(this.state.username) && isNotEmpty(this.state.password);
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  render() {
    const disabled = !this.isValidForm() || this.props.isLoading;
    return (
      <div>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Error!</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.errorMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant='primary' onClick={this.handleOk}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>

        <Form>
          <Form.Row className='justify-content-md-center mt-2'>
            <Form.Group as={Col} md='4' controlId='username'>
              <Form.Label>Username</Form.Label>
              <Form.Control
                type='text'
                autoComplete='username'
                placeholder='Enter username'
                value={this.state.username}
                onChange={this.handleChange}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row className='justify-content-md-center'>
            <Form.Group as={Col} md='4' controlId='password'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                value={this.state.password}
                onChange={this.handleChange}
                placeholder='Password'
                type='password'
              />

              <Form.Text>
                <Link to='/reset'>Forgot username or password?</Link>
              </Form.Text>
            </Form.Group>
          </Form.Row>

          <Form.Row className='justify-content-md-center'>
            <Form.Group as={Col} md='4'>
              <Button
                className='mt-3'
                type='submit'
                block
                variant={disabled ? 'outline-primary' : 'primary'}
                disabled={disabled}
                onClick={this.handleLogin}>
                Sign in
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
