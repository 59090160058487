import { createStore, applyMiddleware } from "redux";
import createSagaMiddlewere from "redux-saga";
import rootSaga from "../sagas";
import rootReducer from "../reducers";

const sagaMiddleware = createSagaMiddlewere();

export const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);
