import { testPassword } from '../tools';

export const isEmpty = (value) => !value || value.trim().length === 0;
export const isNotEmpty = (value) => !isEmpty(value);

export const isLatinName = (value) =>
  isNotEmpty(value) && value.match(/^[a-zA-Z ]+$/);

export const isEmail = (email) =>
  isNotEmpty(email) && !!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

export const isStrongPassword = (password) =>
  isNotEmpty(password) && testPassword(password);

export const isDate = (date) =>
  isNotEmpty(date) && !!date.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/);

export const isUsername = (username) =>
  isNotEmpty(username) &&
  username.toLowerCase() === username &&
  !!username.match(/^[a-z0-9]+$/i);
