import React from 'react';
import axios from '../../config/api';

import { Button, Container, Spinner } from 'react-bootstrap';

export class Verification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasResponse: false,
      responseType: 'success',
      responseMessage: 'Account successfully activated',
    };

    this.showResponse = this.showResponse.bind(this);
  }
  componentDidMount() {
    axios
      .get(
        process.env.REACT_APP_BACKURL +
          '/auth/confirmRegistration?token=' +
          this.props.match.params.token,
      )
      .then((res) => {
        alert('Success!');
        this.props.history.push('/auth');
      })
      .catch((error) => {
        if (error.response === undefined) {
          alert('An error occured. Please, try again later');
        } else if (error.response.status === 404) {
          this.showResponse(
            'error',
            // eslint-disable-next-line
            "Token not found in system.\
                    \n Possible reasons and solutions:\
                    \n - Maybe you've already activated account. Please, try sign-in.\
                    \n - Maybe the link is incorrect. Try copy text link from the letter and paste it to the browser.\
                    \n If this didn't help, please, contact administator ( hello@idao.world ). Please, don't forget to write your username",
          );
        } else {
          this.showResponse(
            'error',
            'An error occured. Please, contact administrator',
          );
        }
      });
  }

  showResponse(type, message) {
    this.setState({
      hasResponse: true,
      responseType: type,
      responseMessage: message,
    });
  }

  render() {
    return (
      <Container className='d-flex justify-content-center'>
        {this.state.hasResponse ? (
          <div id='verification-response' style={{ 'white-space': 'pre-wrap' }}>
            {this.state.responseMessage}
            {this.state.responseType === 'success' ? (
              <div>
                Please,{' '}
                <a href={process.env.PUBLIC_URL + '/#/auth/login'}>
                  reload page
                </a>
              </div>
            ) : null}
          </div>
        ) : (
          <Button variant='primary' disabled>
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
            Loading... Please, wait
          </Button>
        )}
      </Container>
    );
  }
}
