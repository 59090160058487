import React, { Component, useState, useEffect } from 'react';
import RichTextEditor from 'react-rte';

const TextEditor = ({ value, onChange, markup, placeholder, readOnly }) => {
  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createValueFromString(value, markup),
  );

  const handleChange = (v) => {
    setEditorValue(v);
    onChange(v.toString(markup));
  };

  return (
    <RichTextEditor
      value={editorValue}
      readOnly={readOnly}
      onChange={handleChange}
      placeholder={placeholder}
    />
  );
};

TextEditor.defaultProps = {
  value: '',
  markup: 'markdown',
  readOnly: false,
};

export default TextEditor;
