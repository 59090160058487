import React, { useEffect } from 'react';
import { Button, Form, Badge } from 'react-bootstrap';
import TextEditor from '../TextEditor';
import { isEmail } from '../../validators';
import {
  getContestById,
  getOpenedContests,
  getClosedContests,
} from '../../actions/contests';
import { connect } from 'react-redux';
import { Mail } from '../../endpoints/mail';
import { toast } from 'react-toastify';

const mapStateToProps = (state) => ({
  contests: state.contests.contests,
  opened: state.contests.opened,
  closed: state.contests.closed,
});

const mapDispatchToProps = (dispatch) => ({
  getContestById: (id) => dispatch(getContestById.request(id)),
  getOpened: () => dispatch(getOpenedContests.request()),
  getClosed: () => dispatch(getClosedContests.request()),
});

class SendMail extends React.Component {
  state = {
    subject: '',
    body: '',
    selected: 'EVERYONE',
    contestSelected: null,
    group: [],
    groupInput: '',
  };

  handleSendClick(event) {
    event.preventDefault();

    const { selected, contestSelected, group, subject, body } = this.state;
    let req;
    const mail = { subject: subject, body: body };
    switch (selected) {
      case 'EVERYONE': {
        req = Mail.sendToEvery(mail);
        break;
      }
      case 'CONTEST': {
        req = Mail.sendToContest(contestSelected, mail);
        break;
      }
      case 'SPECIFIC': {
        req = Mail.sendToList(group, mail);
        break;
      }
    }
    req.then(() => toast.success('Mail sent'));
  }

  handleChangeGroup = (groupKey) => {
    const prev = this.state.selected;
    if (this.state.selected === groupKey) {
      return;
    }
    switch (prev) {
      case 'CONTEST': {
        this.setState({ contestSelected: null });
        break;
      }
      case 'SPECIFIC': {
        this.setState({ groupInput: '', group: [] });
        break;
      }
    }
    this.setState({ selected: groupKey });
  };

  handleChangeSpecific = (value) => {
    if (value.trim() === '') value = '';
    const group = value
      .split(';')
      .map((v) => v.trim())
      .filter(isEmail);
    this.setState({
      group: group,
      groupInput: value,
    });
  };

  componentDidMount() {
    this.props.getOpened();
    this.props.getClosed();
  }

  render() {
    const { selected, contestSelected, group } = this.state;
    const { opened, closed, contests, getContestById } = this.props;

    const contestList = [...opened, ...closed]
      .filter((id) => contests[id])
      .map((c) => contests[c]);

    const topLevel = [
      { key: 'EVERYONE', label: 'Every user' },
      { key: 'CONTEST', label: 'Contest' },
      { key: 'SPECIFIC', label: 'Specific list' },
    ];

    return (
      <div>
        <h3>Send email</h3>
        <div className='mt-3 mb-2'>
          <h4>Send to</h4>
          {topLevel.map((t) => (
            <Button
              onClick={() => this.handleChangeGroup(t.key)}
              variant={selected === t.key ? 'warning' : 'outline-dark'}>
              {t.label}
            </Button>
          ))}

          {selected === 'CONTEST' && (
            <div>
              {contestList.map((c) => (
                <Button
                  onClick={() => this.setState({ contestSelected: c.id })}
                  variant={
                    contestSelected === c.id ? 'warning' : 'outline-dark'
                  }>
                  {c.name}
                </Button>
              ))}
            </div>
          )}
          {selected === 'SPECIFIC' && (
            <div>
              <div>
                {group.map((g) => (
                  <Badge variant='secondary'>
                    {g}
                    <span
                      onClick={() => {
                        this.handleChangeSpecific(
                          this.state.groupInput.replace(
                            new RegExp(`${g}[\s*;]{0,1}`),
                            '',
                          ),
                        );
                      }}
                      style={{
                        maxHeight: '5px',
                        maxWidth: '5px',
                        color: 'black',
                      }}>
                      x
                    </span>
                  </Badge>
                ))}{' '}
              </div>
              <input
                className='w-100'
                type='text'
                value={this.state.groupInput}
                onChange={(e) => this.handleChangeSpecific(e.target.value)}
                placeholder='abc@example.com; cba@example.com'
              />
            </div>
          )}
        </div>
        <Form className='mt-3'>
          <Form.Group>
            <Form.Label>
              <h4>Subject</h4>
            </Form.Label>
            <Form.Control
              placeholder='Enter subject...'
              value={this.state.subject}
              onChange={(event) =>
                this.setState({ subject: event.target.value })
              }
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <h4>Body</h4>
            </Form.Label>
            <TextEditor
              placeholder='Enter body...'
              markup='html'
              onChange={(value) => this.setState({ body: value })}
            />
          </Form.Group>
        </Form>

        <Button
          className='mt-2'
          variant='dark'
          onClick={this.handleSendClick.bind(this)}>
          Send
        </Button>
        <ContestLoader
          contests={contests}
          contestList={[...opened, ...closed]}
          getContest={getContestById}
        />
      </div>
    );
  }
}

const ContestLoader = ({ contests, contestList, getContest }) => {
  useEffect(() => {
    contestList.filter((c) => !contests[c]).forEach((c) => getContest(c));
  }, [contestList.length, getContest]);
  return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(SendMail);
