import { REGISTER } from '../actions/register';
import { REQUEST, FAILURE, SUCCESS } from '../actions/helpers';

const defaultState = {
  isSuccess: false,
  isLoading: false,
  error: '',
};

const registerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER[REQUEST]:
      return { ...state, isLoading: true };

    case REGISTER[FAILURE]:
      return { ...state, isLoading: false, isSuccess: false };

    case REGISTER[SUCCESS]:
      return { ...state, isLoading: false, isSuccess: true };

    default:
      return state;
  }
};

export default registerReducer;
