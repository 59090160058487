import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import '../styles.css';
import { isAdmin } from '../../tools';

class NavigationBar extends Component {
  onMenuItemClick = (link) => {
    this.props.history.push(link);
  };

  render() {
    const user = this.props.user;
    const signedAsLabel = 'Signed in as: ' + user.username;

    return (
      <Navbar fixed='top' expand='lg' bg='light'>
        <Navbar.Brand>
          <div title='IDAO Personal Account' className='btn btn-outline-info'>
              IPA
          </div>
        </Navbar.Brand>

        <Navbar.Collapse className='responsive-navbar-nav'>
          <Link
            key='home-page'
            className='navbar-link nav-link'
            to='/'>
            Home
          </Link>
          <a
            key='landing-page'
            className='navbar-link nav-link'
            href='https://idao.world'
            target='_blank'
            rel='noopener noreferrer'>
            About
          </a>

          {menuItems.map((menuItem) => (
            <NavDropdown
              key={menuItem.id}
              title={menuItem.label}
              id='collapsible-nav-dropdown'>
              {menuItem.content
                .filter((subItem) => user.roles.includes(subItem.role))
                .map((subItem) => (
                  <NavDropdown.Item
                  className='dropdown-item'
                    id={subItem.id}
                    key={subItem.id}
                    onClick={this.onMenuItemClick.bind(this, subItem.link)}>
                    {subItem.name}
                  </NavDropdown.Item>
                ))}
            </NavDropdown>
          ))}
          <Nav className='mr-auto' />
        </Navbar.Collapse>

        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse className='justify-content-end'>
          <Nav className='mr-auto' />
          <div className='row mt-2'>
            <a
              href='https://www.facebook.com/IntDataAnalysisOlympiad/'
              target='_blank'
              rel='noopener noreferrer'>
              <div className='col-sm'>
                <img
                  src={require('./img/fb.png')}
                  alt='Facebook'
                  className='d-inline-block align-top'
                  width='20'
                  height='20'
                />
              </div>
            </a>

            <a
              href='https://vk.com/public173225079'
              target='_blank'
              rel='noopener noreferrer'>
              <div className='col-sm'>
                <img
                  src={require('./img/vk.png')}
                  alt='VKontakte'
                  className='d-inline-block align-top'
                  width='20'
                  height='20'
                  border='5'
                />
              </div>
            </a>
            <a href='mailto:hello@idao.world'>
              <div className='col-sm'>
                <img
                  src={require('./img/mail.png')}
                  alt='Email'
                  className='d-inline-block align-top'
                  width='20'
                  height='20'
                />
              </div>
            </a>
          </div>

          <NavDropdown title={signedAsLabel} id='collapsible-nav-dropdown'>
            {isAdmin(user) ? (
              <NavDropdown.Item
                onClick={this.onMenuItemClick.bind(this, '/admin')}>
                System control
              </NavDropdown.Item>
            ) : (
              <NavDropdown.Item
                onClick={this.onMenuItemClick.bind(this, `/profile/${user.id}`)}>
                Profile
              </NavDropdown.Item>
            )}
            <NavDropdown.Item onClick={this.props.logout}>
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default withRouter(NavigationBar);

const menuItems = [
  {
    id: 'teams',
    label: 'Teams',
    content: [
      {
        id: 'create-team',
        name: 'Create a team',
        link: '/teams/new',
        role: 'ROLE_USER',
      },

      {
        id: 'your-teams',
        name: 'Your teams',
        link: '/teams/users',
        role: 'ROLE_USER',
      },
    ],
  },
  {
    id: 'contests',
    label: 'Contests',
    content: [
      {
        id: 'create-contest',
        name: 'Create a contest',
        link: '/contests/new',
        role: 'ROLE_ADMIN',
      },
      {
        name: 'Manage contests',
        link: '/contests/edit',
        role: 'ROLE_ADMIN',
      },
      {
        id: 'my-contests',
        name: 'Current contests',
        link: `/contests/my-active`,
        role: 'ROLE_USER',
      },
      {
        id: 'join-contest',
        name: 'Join a contest',
        link: '/contests/',
        role: 'ROLE_USER',
      },
    ],
  },
];
