import React, { useState, useRef } from 'react';
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import {
  BootstrapReboot,
  Clipboard,
  Eye,
  EyeSlash,
} from 'react-bootstrap-icons';
import { generatePassword } from '../../tools';
import { toast } from 'react-toastify';
import { PersonalData } from '../../endpoints/users';
import { isStrongPassword } from '../../validators';

const ChangePassword = ({ id }) => {
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isStrongPassword(password)) {
      toast.error('Password is not strong enough');
      return;
    }

    PersonalData.changePassword(id, password).then(() =>
      toast.success('Password saved'),
    );
  };

  return (
    <>
      <h4 className='mt-3'>Change password</h4>
      <PasswordInput value={password} onChange={setPassword} />
      <Button
      block
        className='mt-2'
        variant='secondary'
        onClick={handleSubmit}
        type='submit'>
        Save password
      </Button>
    </>
  );
};

const hiddenToButton = {
  true: { title: 'Show password', icon: <Eye /> },
  false: { title: 'Hide password', icon: <EyeSlash /> },
};

const PasswordInput = ({ value, onChange }) => {
  const [hidden, setHidden] = useState(true);
  const button = hiddenToButton[hidden];

  const textAreaRef = useRef(null);

  function copyToClipboard() {
    if (hidden) {
      toast.warn('Can not copy hidden value');
      return;
    }
    textAreaRef.current.select();
    document.execCommand('copy');
    toast.success('Copied to clipboard');
  }

  const handleGenerate = () => {
    onChange(generatePassword(16));
    toast.success('Generated password')
  }
  return (
    <InputGroup className='mt-2'>
      <FormControl
        placeholder='Enter new strong password...'
        ref={textAreaRef}
        type={hidden ? 'password' : 'text'}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <InputGroup.Append>
        <Button
          onClick={() => setHidden(!hidden)}
          size='sm'
          variant='light'
          title={button.title}>
          {button.icon}
        </Button>
        <Button
          title='Copy to clipboard'
          onClick={copyToClipboard}
          size='sm'
          variant='secondary'>
          <Clipboard />
        </Button>
        <Button
          title='Generate random'
          onClick={handleGenerate}
          size='sm'
          variant='dark'>
          <BootstrapReboot />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
};

export default ChangePassword;
