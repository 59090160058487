import api from '../config/api';

export const Contest = {
  getById: (id) => api.get(`/contests/${id}/`),
  getByStatus: (status) => api.get(`/contests/status/${status}`),
  getByUser: (userId) => api.get(`/contests/users/${userId}`),
  create: (contest, settings, stages) =>
    api.post('/contests/', {
      contest: contest,
      settings: settings,
      stages: stages,
    }),

  delete: (id) => api.delete(`/contests/${id}/`),
  edit: (id, contest) => api.put(`/contests/${id}/`, contest),
  updateStatus: (id, status) => api.put(`/contests/${id}/status/${status}`),
  join: (contestId, teamId) =>
    api.post(`/contests/${contestId}/teams/${teamId}`),

  getSettings: (id) => api.get(`/contests/${id}/settings`),
  editSettings: (id, settings) => api.put(`/contests/${id}/settings`, settings),
};

export const Stage = {
  getById: (id) => api.get(`/contests/stages/${id}`),
  getByContest: (id) => api.get(`/contests/${id}/stages`),
  edit: (id, stage) => api.put(`/contests/stages/${id}`, stage),
  delete: (id) => api.delete(`/contests/stages/${id}/`),

  promoteTeams: (stageId, nextStageId, winners) =>
    api.post(`/contests/promote/${stageId}/${nextStageId}`, winners),
  finishContest: (stageId, winners) =>
    api.post(`/contests/finish/${stageId}`, winners),

  removeTeam: (stageId, teamId) =>
    api.delete(`/contests/stages/${stageId}/teams/${teamId}/`),
  addKeys: (id, keys) => api.post(`/contests/stages/${id}/keys/`, keys),
  getTeamKeys: (stageId, teamId) =>
    api.get(`/contests/stages/${stageId}/teams/${teamId}/keys`),
  getTeams: (stageId) => api.get(`/contests/stages/${stageId}/teams`),
};
