import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { isAdmin } from '../../tools';

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
  user: state.auth.user,
});

const UnauthorizedOnlyRoute = ({ isAuth, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuth ? <Component {...props} /> : <Redirect to='/' />
    }
  />
);
export const UnauthorizedRoute = connect(
  mapStateToProps,
  null,
)(UnauthorizedOnlyRoute);

const AuthorizedOnlyRoute = ({ isAuth, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuth ? <Component {...props} /> : <Redirect to='/auth' />
    }
  />
);
export const ProtectedRoute = connect(
  mapStateToProps,
  null,
)(AuthorizedOnlyRoute);

const AdminOnlyRoute = ({ user, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAdmin(user) ? <Component {...props} /> : <Redirect to='/' />
    }
  />
);
export const AdminRoute = connect(mapStateToProps)(AdminOnlyRoute);
