import { createRequestTypes, createRequestActions } from './helpers';

const createTypes = (name) => createRequestTypes('PROFILE', name);

export const GET_ACCOUNT = createTypes('GET_ACCOUNT');
export const GET_ACCOUNTS = createTypes('GET_ACCOUNTS');
export const EDIT_ACCOUNT = createTypes('EDIT_ACCOUNT');
export const GET_PERSONAL_DATA = createTypes('GET_PERSONAL_DATA');
export const EDIT_PERSONAL_DATA = createTypes('EDIT_PERSONAL_DATA');
export const DELETE_ACCOUNT = createTypes('DELETE_ACCOUNT');

export const getAccount = createRequestActions(GET_ACCOUNT);
export const getAccounts = createRequestActions(GET_ACCOUNTS);
export const editAccount = createRequestActions(EDIT_ACCOUNT);
export const getPersonalData = createRequestActions(GET_PERSONAL_DATA);
export const editPersonalData = createRequestActions(EDIT_PERSONAL_DATA);
export const deleteAccount = createRequestActions(DELETE_ACCOUNT);
