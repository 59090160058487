import React, { useEffect, useState } from 'react';
import { Button, Table, Container, Form, InputGroup } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { getAccount, getAccounts } from '../../actions/profile';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Blacklist } from '../../endpoints/users';

const mapStateToProps = (state) => ({
  accounts: state.profile.accounts,
});
const mapDispatchToProps = (dispatch) => ({
  getAccount: (id) => dispatch(getAccount.request(id)),
  getAccounts: () => dispatch(getAccounts.request()),
});

const BlockUsers = ({ accounts, getAccount, getAccounts }) => {
  const [blacklist, setBlacklist] = useState([]);

  const [selectedUser, setSelectedUser] = useState(null);
  const [reason, setReason] = useState('');

  const receiveBlacklist = () => {
    Blacklist.getAll().then((response) => {
      const blacklist = response.sort((a, b) => {
        let aDate = a.blockDate;
        let bDate = b.blockDate;
        return -aDate.localeCompare(bDate);
      });

      blacklist.forEach((u) => {
        if (!accounts[u.userId]) getAccount(u.userId);
      });

      setBlacklist(blacklist);
    });
  };

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);
  useEffect(() => {
    receiveBlacklist();
  }, []);

  const handleBlock = (user, reason) => {
    Blacklist.block(user.id, reason).then(() =>
      toast.success(`${user.username} blocked`),
    ).then(receiveBlacklist);
  };

  const handleUnblock = (userId) => {
    Blacklist.unblock(userId).then(() =>
      toast.success(`User restored from blacklist`),
    ).then(receiveBlacklist);
  };

  const handleUserChange = (selected) => {
    let user = selected[0];
    if (!user) return;
    setSelectedUser(user);
  };

  const handleSubmit = () => {
    if (!selectedUser) {
      toast.error(`Select user to block`);
      return;
    }
    if (!reason) {
      toast.error(`Enter reason to block user`);
      return;
    }
    handleBlock(selectedUser, reason);
  };

  return (
    <Container>
      <h3>Block user</h3>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>User</InputGroup.Text>
        </InputGroup.Prepend>
        <Typeahead
          id='user selector'
          placeholder='Start typing username or name...'
          labelKey={(option) => `${option.name} (${option.username})`}
          options={Object.values(accounts)}
          onChange={handleUserChange}
        />
        <InputGroup.Prepend>
          <InputGroup.Text>Reason</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          type='text'
          onChange={(e) => setReason(e.target.value)}
          placeholder='Reason for blocking'
        />
        <InputGroup.Append>
          <Button variant='secondary' onClick={handleSubmit}>
            Block
          </Button>
        </InputGroup.Append>
      </InputGroup>

      {!blacklist.length ? (
        <div className='mt-3'>No blocked users yet :D</div>
      ) : (
        <div className='mt-3'>
          <h3>Blacklist</h3>
          <Table className='mt-3' bordered>
            <thead className='thead-dark'>
              <tr>
                <th>User</th>
                <th>Reason</th>
                <th>Date</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {blacklist.map((u) => (
                <tr key={u.userId}>
                  <td>
                    {!accounts[u.userId] ? (
                      <Skeleton />
                      ) : (
                      accounts[u.userId].username
                    )}
                  </td>
                  <td>{u.reason}</td>
                  <td>{u.blockDate}</td>
                  <td>
                    <Button
                      size='sm'
                      variant='dark'
                      onClick={() => handleUnblock(u.userId)}>
                      Unblock
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </Container>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockUsers);
