import React, { useEffect } from 'react';
import { Badge, Container, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getAllTeams } from '../../actions/teams';

const mapStateToProps = (state) => ({
  teams: state.teams.teams,
});

const mapDispatchToProps = (dispatch) => ({
  getAll: () => dispatch(getAllTeams.request()),
});

const ViewAllTeams = ({ teams, getAll }) => {
  const teamIds = Object.keys(teams);
  useEffect(() => {
    if (!teamIds.length) getAll();
  }, [getAll]);

  return (
    <Container>
      <h3>All teams</h3>
      <h5>
        <Badge variant='success'>Total count: {teamIds.length}</Badge>
      </h5>
      <Table className='mt-3' striped bordered hover>
        <thead className='thead-dark'>
          <tr>
            <th>id</th>
            <th>name</th>
            <th>members</th>
            <th>created</th>
            <th>status</th>
          </tr>
        </thead>
        <tbody>
          {teamIds.map((id) => (
            <TeamRow key={id} id={id} />
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

const TeamRow = ({ team }) => {
  return (
    <tr>
      <td>{team.id}</td>
      <td>{team.name}</td>
      
      <td>{team.registrationDate}</td>
      <td>{team.status}</td>
    </tr>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllTeams);
