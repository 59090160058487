import { combineWatchers, createWatcher } from './helpers';
import { call, put } from 'redux-saga/effects';
import { Team, Members, Invites } from '../endpoints/teams';
import {
  createTeam,
  editTeam,
  getTeamById,
  getAllTeams,
  getTeamsByUser,
  getTeamMembers,
  removeMember,
  inviteUser,
  acceptInvite,
  declineInvite,
  getInvites,
  CREATE_TEAM,
  EDIT_TEAM,
  DELETE_TEAM,
  GET_ALL_TEAMS,
  GET_TEAM_BY_ID,
  GET_TEAMS_BY_USER,
  GET_TEAM_MEMBERS,
  REMOVE_MEMBER,
  INVITE_USER,
  ACCEPT_INVITE,
  DECLINE_INVITE,
  GET_INVITES,
  deleteTeam,
} from '../actions/teams';

// TEAMS
function* callCreate(action) {
  const team = action.payload;
  try {
    const response = yield call(Team.create, team);
    yield put(createTeam.success(response, team));
    yield put(getTeamById.request(response.id))
    yield put(getTeamMembers.request(response.id))
  } catch (error) {
    yield put(createTeam.failure(error, team));
  }
}

function* callEdit(action) {
  const team = action.payload;
  try {
    const response = yield call(Team.edit, team.id, team);
    yield put(editTeam.success(response, team));
    yield put(getTeamById.request(team.id))
  } catch (error) {
    yield put(editTeam.failure(error, team));
  }
}

function* callDelete(action) {
  const id = action.payload;
  try {
    const response = yield call(Team.delete, id);
    yield put(deleteTeam.success(response, id));
  } catch (error) {
    yield put(deleteTeam.failure(error, id));
  }
}

function* callGetAll() {
  try {
    const response = yield call(Team.getAll);
    yield put(getAllTeams.success(response));
  } catch (error) {
    yield put(getAllTeams.failure(error));
  }
}

function* callGetById(action) {
  const id = action.payload;
  try {
    const response = yield call(Team.getById, id);
    yield put(getTeamById.success(response, id));
  } catch (error) {
    yield put(getTeamById.failure(error, id));
  }
}

function* callGetByUser(action) {
  const userId = action.payload;
  try {
    const response = yield call(Team.getByUserId, userId);
    yield put(getTeamsByUser.success(response, userId));
  } catch (error) {
    yield put(getTeamsByUser.failure(error, userId));
  }
}

// MEMBERS
function* callGetMembers(action) {
  const teamId = action.payload;
  try {
    const response = yield call(Members.getAllByTeamId, teamId);
    yield put(getTeamMembers.success(response, teamId));
  } catch (error) {
    yield put(getTeamMembers.failure(error, teamId));
  }
}

function* callRemoveMember(action) {
  const { teamId, userId } = action.payload;
  try {
    const response = yield call(Members.remove, teamId, userId);
    yield put(removeMember.success(response, action.payload));
    yield put(getTeamMembers.request(teamId))
  } catch (error) {
    yield put(removeMember.failure(error, action.payload));
  }
}

// INVITES
function* callInviteUser(action) {
  const { teamId, userId } = action.payload;
  try {
    const response = yield call(Invites.invite, teamId, userId);
    yield put(inviteUser.success(response, action.payload));
    yield put(getTeamMembers.request(teamId))
  } catch (error) {
    yield put(inviteUser.failure(error, action.payload));
  }
}

function* callAcceptInvite(action) {
  const { teamId, userId } = action.payload;
  try {
    const response = yield call(Invites.accept, teamId, userId);
    yield put(acceptInvite.success(response, action.payload));
  } catch (error) {
    yield put(acceptInvite.failure(error, action.payload));
  }
}

function* callDeclineInvite(action) {
  const { teamId, userId } = action.payload;
  try {
    const response = yield call(Invites.decline, teamId, userId);
    yield put(declineInvite.success(response, action.payload));
  } catch (error) {
    yield put(declineInvite.failure(error, action.payload));
  }
}

function* callGetInvites(action) {
  const userId = action.payload;
  try {
    const response = yield call(Invites.getByUserId, userId);
    yield put(getInvites.success(response, action.payload));
  } catch (error) {
    yield put(getInvites.failure(error, action.payload));
  }
}

const watchCreate = createWatcher(CREATE_TEAM, callCreate);
const watchEdit = createWatcher(EDIT_TEAM, callEdit, 'all');
const watchCallDelete = createWatcher(DELETE_TEAM, callDelete);
const watchGetAll = createWatcher(GET_ALL_TEAMS, callGetAll);
const watchGetById = createWatcher(GET_TEAM_BY_ID, callGetById, 'all');
const watchGetByUser = createWatcher(GET_TEAMS_BY_USER, callGetByUser, 'all');

const watchGetMembers = createWatcher(GET_TEAM_MEMBERS, callGetMembers, 'all');
const watchRemoveMember = createWatcher(REMOVE_MEMBER, callRemoveMember, 'all');

const watchGetInvites = createWatcher(GET_INVITES, callGetInvites);
const watchInviteUser = createWatcher(INVITE_USER, callInviteUser, 'all');
const watchAcceptInvite = createWatcher(ACCEPT_INVITE, callAcceptInvite, 'all');
const watchDeclineInvite = createWatcher(
  DECLINE_INVITE,
  callDeclineInvite,
  'all',
);

export const watchTeams = combineWatchers(
  watchCreate,
  watchEdit,
  watchCallDelete,
  watchGetAll,
  watchGetById,
  watchGetByUser,
  watchGetMembers,
  watchRemoveMember,
  watchInviteUser,
  watchAcceptInvite,
  watchDeclineInvite,
  watchGetInvites,
);
