import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Table,
  Form,
} from 'react-bootstrap';
import { Download, Search } from 'react-bootstrap-icons';
import { Account, PersonalData } from '../../endpoints/users';
import { getAccount, getPersonalData } from '../../actions/profile';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { generateCsv } from '../../tools';
import ExportUsersButton from './ExportUsersButton';
import { Reports } from '../../endpoints/reports';

const mapStateToProps = (state) => ({
  accounts: state.profile.accounts,
  personalData: state.profile.personalData,
});

const mapDispatchToProps = (dispatch) => ({
  getAccount: (id) => dispatch(getAccount.request(id)),
  getPersonalData: (id) => dispatch(getPersonalData.request(id)),
});


const setDate = (date) => {
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
  return date
}

const now = setDate(new Date())

const requestFullInfo = (from, to) => {
  Reports.getActiveForPeriod(from, to).then((response) => {
    const header =
      'ID,USERNAME,REGISTRATION DATE,NAME,EMAIL,BIRTHDAY,PHONE NUMBER,GENDER,COUNTRY OF RESIDENCE,UNIVERSITY,STUDY PROGRAM,LEVEL OF STUDY,COMPANY,LOGIN DATE';
    const data = response
      .map((obj) => {
        const u = obj.account;
        const pd = obj.personalData;
        const act = obj.activity;

        return [
          u.id,
          u.username,
          pd.registrationDate,
          u.name,
          pd.email,
          pd.birthday,
          pd.phoneNumber,
          pd.gender,
          pd.countryOfResidence,
          pd.university,
          pd.studyProgram,
          pd.levelOfStudy,
          pd.company,
          act.loginDate,
        ].join();
      })
      .join('\n');
    generateCsv(`${header}\n${data}`);
  });
}

const ActivityRow = ({ userActivity, accounts }) => {
  const account = accounts[userActivity.userId]

  const date = userActivity.loginDate.toISOString().slice(0, 16).replace('T', ' at ')
  const device = userActivity.device.slice(0, 20) + '...'

  return <tr>
    <td><Link to={`/profile/${userActivity.userId}`}>{userActivity.userId}</Link></td>
    <td><Link to={`/profile/${userActivity.userId}`}>{account ? account.username : <Skeleton />}</Link></td>
    <td>{date}</td>
  </tr>
}

const ViewUsersActivity = ({ accounts, getAccount }) => {
  const [usersToShow, setUsersToShow] = useState([])
  const [fromDate, setFromDate] = useState((new Date(now.getTime() - 1000 * 60 * 60 * 24 * 30)).toISOString().slice(0, 16))
  const [toDate, setToDate] = useState(now.toISOString().slice(0, 16))

  const handleShow = (e) => {
    Account.activeInPeriod(fromDate, toDate).then(r => {
      const users = r
      users.forEach(u => {
        u.loginDate = new Date(Date.parse(u.loginDate))
        if (!accounts[u.userId]) {
          getAccount(u.userId)
        }
      })
      users.sort((a, b) => b.loginDate - a.loginDate)

      setUsersToShow(users)
    })
  }  

  return <Container>
    <h3>Users activity</h3>
    <Form>
      <Form.Group className='d-flex flex-row'>
        <div className='mr-2'>
          <Form.Label>Start</Form.Label>
          <Form.Control type='datetime-local' value={fromDate} onChange={obj => setFromDate(obj.target.value)} />
        </div>
        <div>
          <Form.Label>End</Form.Label>
          <Form.Control type='datetime-local' value={toDate} onChange={obj => setToDate(obj.target.value)} />
        </div>
      </Form.Group>
      <Button className='mr-2' onClick={handleShow}><Search /> Show</Button>
      <ExportUsersButton requestFullInfo={() => requestFullInfo(fromDate, toDate)} />
    </Form>

    {
      usersToShow.length > 0 ? <div className='mt-3'>
        <h4>Users</h4>
        <Table striped bordered hover>
          <thead className='thead-dark'>
            <tr>
              <th>ID</th>
              <th>Username</th>
              <th>Last login</th>
            </tr>
          </thead>
          <tbody>
            {usersToShow.map(u => <ActivityRow key={u.userId} userActivity={u} accounts={accounts} />)}
          </tbody>
        </Table>
      </div> : <p>No data to show</p>
    }
  </Container>
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewUsersActivity);
