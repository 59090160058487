import { call, put } from 'redux-saga/effects';
import {
  getNews,
  editArticle,
  deleteArticle,
  GET_NEWS,
  EDIT_ARTICLE,
  DELETE_ARTICLE,
  CREATE_ARTICLE,
  createArticle,
} from '../actions/news';
import { createWatcher, combineWatchers } from './helpers';
import { News } from '../endpoints/news';

export const watchGetNews = createWatcher(GET_NEWS, callGetNews);

export function* callGetNews() {
  try {
    const news = yield call(News.getAll);
    yield put(getNews.success(news));
  } catch (error) {
    yield put(getNews.failure(error));
  }
}

export const watchCreateArticle = createWatcher(
  CREATE_ARTICLE,
  callCreateArticle,
);

export function* callCreateArticle(action) {
  const article = action.payload;

  try {
    yield call(News.create, article);
    yield put(createArticle.success(`Published '${article.header}'`, article));
    yield put(getNews.request());
  } catch (error) {
    yield put(createArticle.failure(error, article));
  }
}

export const watchEditArticle = createWatcher(EDIT_ARTICLE, callEditArticle);

export function* callEditArticle(action) {
  const article = action.payload;

  try {
    yield call(News.update, article);
    yield put(editArticle.success(`Saved '${article.header}'`, article));
    yield put(getNews.request());
  } catch (error) {
    yield put(editArticle.failure(error, article));
  }
}

export const watchDeleteArticle = createWatcher(
  DELETE_ARTICLE,
  callDeleteArticle,
);

export function* callDeleteArticle(action) {
  const articleID = action.payload;

  try {
    yield call(News.delete, articleID);
    yield put(deleteArticle.success(`Article was deleted`, articleID));
    yield put(getNews.request());
  } catch (error) {
    yield put(deleteArticle.failure(error, articleID));
  }
}

export const watchNews = combineWatchers(
  watchGetNews,
  watchCreateArticle,
  watchEditArticle,
  watchDeleteArticle,
);
