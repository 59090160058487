import { SUCCESS } from '../actions/helpers';
import {
  CREATE_TEAM,
  GET_TEAM_BY_ID,
  GET_ALL_TEAMS,
  GET_TEAMS_BY_USER,
  GET_TEAM_MEMBERS,
  GET_INVITES,
  DELETE_TEAM,
  EDIT_TEAM,
  INVITE_USER,
  REMOVE_MEMBER,
  ACCEPT_INVITE,
  DECLINE_INVITE,
} from '../actions/teams';
import { toast } from 'react-toastify';

const defaultState = {
  all: [], //ids
  teams: {}, // teamId to team
  members: {}, // teamId to members
  users: {}, // userId to teamIds
  invites: {}, // userId to teamIds
};

const teamReducer = (state = defaultState, action) => {
  switch (action.type) {
    case EDIT_TEAM[SUCCESS]: {
      toast.success(`Saved as '${action.payload.requested.name}'`);
      return state;
    }
    case INVITE_USER[SUCCESS]: {
      toast.success('User is invited');
      return state;
    }
    case ACCEPT_INVITE[SUCCESS]: {
      const { teamId } = action.payload.requested;
      toast.success(`Invite to '${state.teams[teamId].name}' accepted`);
      return state;
    }
    case DECLINE_INVITE[SUCCESS]: {
      const { teamId } = action.payload.requested;
      toast.warn(`Invite to '${state.teams[teamId].name}' declined`);
      return state;
    }
    case REMOVE_MEMBER[SUCCESS]: {
      toast.success('Member is removed');
      return state;
    }
    case CREATE_TEAM[SUCCESS]:
      toast.success(`Team '${action.payload.requested.name}' is created`);
      return state;
    case GET_TEAM_BY_ID[SUCCESS]: {
      const team = action.payload.response;
      const teams = { ...state.teams, [team.id]: team };
      return { ...state, teams };
    }
    case DELETE_TEAM[SUCCESS]: {
      const deletedTeamId = action.payload.requested;
      const teams = { ...state.teams };
      const team = teams[deletedTeamId];
      const members = { ...state.members };
      const users = {};
      Object.keys(state.users).forEach((userId) => {
        users[userId] = state.users[userId].filter(
          (teamId) => teamId !== deletedTeamId,
        );
      });

      delete teams[deletedTeamId];
      delete members[deletedTeamId];
      toast.success(`Team '${team.name}' is removed`);
      return { ...state, members: members, teams: teams, users: users };
    }
    case GET_ALL_TEAMS[SUCCESS]: {
      const teams = action.payload.response.reduce((map, team) => {
        map[team.id] = team;
        return map;
      }, {});
      return { ...state, teams, all: action.payload.response };
    }
    case GET_TEAMS_BY_USER[SUCCESS]: {
      const userId = action.payload.requested;
      const userTeams = action.payload.response;
      const users = { ...state.users, [userId]: userTeams };
      return { ...state, users: users };
    }
    case GET_TEAM_MEMBERS[SUCCESS]: {
      const teamId = action.payload.requested;
      const members = { ...state.members, [teamId]: action.payload.response };
      return { ...state, members: members };
    }
    case GET_INVITES[SUCCESS]: {
      const userId = action.payload.requested;
      const teamIds = action.payload.response;
      const invites = { ...state.invites, [userId]: teamIds };
      return { ...state, invites: invites };
    }
    default:
      return state;
  }
};

export default teamReducer;
