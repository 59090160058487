import api from '../config/api';

export const Account = {
  getById: (id) => api.get(`/accounts/${id}`),
  getAll: () => api.get('/accounts/'),
  // getAllRegistered
  edit: (id, account) => api.put(`/accounts/${id}`, account),
  delete: (id) => api.delete(`/accounts/${id}`),
  activate: (id) => api.put(`/admin/users/${id}/activate`),
  activeInPeriod: (from, to) => api.get(`/admin/active-users?from=${from}&to=${to}`)
};

export const Blacklist = {
  getAll: () => api.get('/blacklist/'),
  block: (userId, reason) =>
    api.post('/blacklist/', { userId: userId, reason: reason }),
  unblock: (userId) => api.delete(`/blacklist/${userId}`),
};

export const PersonalData = {
  getById: (id) => api.get(`/personal-data/${id}`),
  edit: (id, data) => api.put(`/personal-data/${id}`, data),
  // getAll
  searchByEmail: (email) => api.get(`/personal-data/search/email/${email}`),
  changePassword: (id, password) =>
    api.put(`/password/${id}`, { value: password }),
};

export const Unauthorized = {
  login: (credentials) => api.post('/auth/login', credentials),
  register: (newUser) => api.post('/auth/register', newUser),
  remindUsername: (email) =>
    api.post('/auth/remind/username', { value: email }),
  resetPassword: (email) => api.post('/auth/password/reset', { value: email }),
};
