export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export function createRequestTypes(prefix, base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
    acc[type] = `@${prefix}/${base}_${type}`;
    return acc;
  }, {});
}

export function action(type, payload = {}) {
  return { type, payload: payload };
}

export function createRequestActions(actionType) {
  return {
    request: (requested) => action(actionType[REQUEST], requested),
    success: (response, requested) =>
      action(actionType[SUCCESS], { requested: requested, response: response }),
    failure: (error, requested) =>
      action(actionType[FAILURE], { requested: requested, error: error }),
  };
}
