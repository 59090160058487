import React from 'react';
import { PageItem, Pagination } from 'react-bootstrap';
import PropTypes from 'prop-types';

const PageNavigation = ({ current, total, onChange }) => {
  const prev = () => onChange(current - 1);
  const next = () => onChange(current + 1);
  const first = () => onChange(1);
  const last = () => onChange(total);

  const pages = Array.of(-2, -1, 0, 1, 2)
    .map((offset) => current + offset)
    .filter((num) => num > 0 && num <= total)
    .map((num) => (
      <PageItem
        key={num}
        active={current === num}
        onClick={() => onChange(num)}>
        {num}
      </PageItem>
    ));

  return (
    <Pagination className='justify-content-center'>
      <Pagination.Prev onClick={prev} />
      {current > 3 ? (
        <div className='d-flex'>
          <PageItem onClick={first}>{1}</PageItem>
          <Pagination.Ellipsis disabled />
        </div>
      ) : null}

      {pages}

      {total - current > 2 && (
        <div className='d-flex'>
          <Pagination.Ellipsis disabled />
          <PageItem onClick={last}>{total}</PageItem>
        </div>
      )}
      <Pagination.Next onClick={next} />
    </Pagination>
  );
};

PageNavigation.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PageNavigation;
