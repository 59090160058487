import React from 'react';
import { Container, Spinner, Button } from 'react-bootstrap';
import {
  Bar,
  Cell,
  LineChart,
  Line,
  PieChart,
  Pie,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  BarChart,
  ResponsiveContainer,
} from 'recharts';
import axios from '../../config/api';

const GRAPH_HEADER = 'text-uppercase text-center font-weight-bold graph-title';

export default class Statistics extends React.Component {
  state = {
    data: [],
  };

  componentDidMount() {
    axios.get(`/reports/users`).then((response) => {
      const data = response.map((e) => e.personalData);
      this.setState({ data: data });
    });
  }

  render() {
    return (
      <Container className='mb-2'>
        <h3>Statistics</h3>
        {this.state.data.length !== 0 ? (
          <div className='d-flex align-content-center flex-wrap'>
            <RegistrationActivityLineGraph data={this.state.data} />
            <CountryBarChart data={this.state.data} />
            <GenderPieChart data={this.state.data} />

            <AgeBarChart data={this.state.data} />
          </div>
        ) : (
          <Button style={{ marginLeft: '50%' }} disabled variant='info'>
            <Spinner size='sm' animation='border' variant='light' />
            <small className='ml-2'>LOADING...</small>
          </Button>
        )}
      </Container>
    );
  }
}

class RegistrationActivityLineGraph extends React.Component {
  render() {
    const rawData = this.props.data;

    const data = Object.entries(groupBy(rawData, 'registrationDate')).map(
      ([key, list]) => ({
        name: key,
        date: new Date(key),
        value: list.length,
      }),
    );

    data.sort((a, b) => a.date - b.date);
    return (
      <div style={{ height: '400px', width: '100%' }}>
        <div className={GRAPH_HEADER}>Registration activity</div>
        <ResponsiveContainer>
          <LineChart
            width={800}
            height={400}
            data={data}
            margin={{ top: 5, right: 20, left: 10, bottom: 20 }}>
            <XAxis dataKey='name' />
            <YAxis />

            <Tooltip />
            <CartesianGrid stroke='#f5f5f5' />
            <Line
              type='monotone'
              dataKey='value'
              stroke='#ff7300'
              yAxisId={0}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

class GenderPieChart extends React.Component {
  render() {
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    const data = Object.entries(groupBy(this.props.data, 'gender')).map(
      ([key, list]) => ({
        name: key,
        value: list.length,
      }),
    );

    return (
      <div>
        <div className={GRAPH_HEADER}>Genders</div>
        <PieChart width={400} height={400} label='Gender pie'>
          <Pie isAnimationActive={true} data={data} cx='50%' cy='50%' label>
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </div>
    );
  }
}

class CountryBarChart extends React.Component {
  state = {
    data: [],
  };

  componentDidMount() {
    // const numOfBars = 6;
    const groupedData = Object.entries(
      groupBy(this.props.data, 'countryOfResidence'),
    ).map(([key, list]) => ({
      name: key,
      value: list.length,
    }));

    groupedData.sort((a, b) => b.value - a.value);

    // const data = groupedData.slice(0, numOfBars);
    // data[numOfBars - 1] = {
    //   name: 'Other',
    //   value: groupedData
    //     .slice(numOfBars)
    //     .reduce((acc, cur) => acc + cur.value, 0),
    // };
    this.setState({ data: groupedData });
  }

  render() {
    const data = this.state.data;
    const colors = [
      '#00C49F',
      '#FFBB28',
      '#FF8042',
      '#9691ff',
      '#23cece',
      '#ed908b',
    ];

    const height = 130 * data.length

    return (
      <div style={{ width: '100%', height: '800px', overflowY: 'scroll' }}>
        <div className={GRAPH_HEADER}>Countries</div>
          <BarChart
          layout='vertical'
            width={1100}
            height={height}
            data={data}
            margin={{ top: 20, left: 100, bottom: 20, right: 100}}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis type='number' />
            <YAxis dataKey='name' type="category"/>
            <Bar
              isAnimationActive={true}
              dataKey='value'
              label={{ position: 'right' }}>
              {data.map((d, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Bar>
            <Tooltip cursor={false} />
          </BarChart>
      </div>
    );
  }
}

class AgeBarChart extends React.Component {
  state = {
    data: [],
  };

  groupByAge = (data) => {
    const getGroupNum = (age) => {
      if (age < 18) return 0;
      if (age >= 18 && age < 25) return 1;
      if (age >= 25 && age < 30) return 2;
      return 3;
    };

    const now = new Date();

    const agedData = this.props.data.map((d) => {
      let bDay = new Date(d.birthday);
      let age = now.getFullYear() - bDay.getFullYear();
      d.age = age;
      return d;
    });

    agedData.forEach((d) => (d.ageG = getGroupNum(d.age)));
    const d = groupBy(agedData, 'ageG');

    return d;
  };

  componentDidMount() {
    const labels = ['< 18', '18-25', '25-30', '30+'];

    const groupedData = Object.entries(this.groupByAge(this.props.data)).map(
      ([key, list]) => ({
        name: labels[key],
        value: list.length,
      }),
    );

    // groupedData.sort((a, b) => b.value - a.value);

    this.setState({ data: groupedData });
  }

  render() {
    const data = this.state.data;
    const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    return (
      <div>
        <div className={GRAPH_HEADER}>Ages</div>
        <BarChart width={400} height={400} data={data}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis />
          <Bar
            isAnimationActive={true}
            dataKey='value'
            label={{ position: 'top' }}>
            {data.map((d, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % 4]} />
            ))}
          </Bar>
          <Tooltip cursor={false} />
        </BarChart>
      </div>
    );
  }
}

function groupBy(objectArray, property) {
  return objectArray.reduce(function (acc, obj) {
    let key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}
