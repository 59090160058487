import React, { useState, useRef, useEffect } from 'react';
import { Table, Button, Badge, FormControl, InputGroup } from 'react-bootstrap';
import {
  Clipboard,
  CheckCircle,
  QuestionCircle,
  XCircle,
  Eye,
  EyeSlash,
} from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { getAccount } from '../../../actions/profile';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApiToken } from '../../../endpoints';

function activateToken(tokenID, listener) {
  ApiToken.activate(tokenID)
    .then(() => toast.success('token activated'))
    .then(() => listener());
}

function deactivateToken(tokenID, listener) {
  ApiToken.deactivate(tokenID)
    .then(() => toast.success('token deactivated'))
    .then(() => listener());
}

function deleteToken(tokenID, listener) {
  ApiToken.delete(tokenID)
    .then(() => toast.success('token deleted'))
    .then(() => listener());
}

const mapStateToProps = (state) => ({
  accounts: state.profile.accounts,
  activated: state.profile.activated,
});

const mapDispatchToProps = (dispatch) => ({
  getAccount: (id) => dispatch(getAccount.request(id)),
});

const TokenList = ({ tokens, getAccount, accounts, onChange }) => {
  useEffect(() => {
    tokens.forEach((t) => !accounts[t.ownerID] && getAccount(t.ownerID));
  }, [tokens.length, getAccount]);

  return (
    <div>
      <Table className='mt-2' striped hover>
        <thead>
          <th></th>
          <th>name</th>
          <th>owner</th>
          <th>issued at</th>
          <th>value</th>
          <th>actions</th>
        </thead>
        <tbody>
          {tokens.map((t) => (
            <tr key={t.id}>
              <td>
                <StatusBadge status={t.status} />
              </td>
              <td>{t.name}</td>
              <td>
                <Link to={`/profile/${t.ownerID}`}>
                  {accounts[t.ownerID] ? (
                    <>{accounts[t.ownerID].username}</>
                  ) : (
                    t.ownerID
                  )}
                </Link>
              </td>
              <td>{t.issuedDate}</td>
              <td>
                <HiddenValue value={t.value} />
              </td>
              <td>
                <Button
                  className='mr-1'
                  size='sm'
                  variant='secondary'
                  onClick={deactivateToken.bind(this, t.id, onChange)}>
                  Disable
                </Button>
                <Button
                  className='mr-1'
                  size='sm'
                  variant='success'
                  onClick={activateToken.bind(this, t.id, onChange)}>
                  Enable
                </Button>
                <Button
                  size='sm'
                  variant='danger'
                  onClick={deleteToken.bind(this, t.id, onChange)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const StatusBadge = ({ status }) => {
  const badge = statusesToBadge[status];
  return (
    <Badge
      title={`Status: ${badge.title}`}
      pill
      variant={badge.color}
      style={{ width: '100%', height: '100%' }}>
      {badge.icon}
    </Badge>
  );
};

const statusesToBadge = {
  ACTIVE: { color: 'success', icon: <CheckCircle />, title: 'Active' },
  DEACTIVATED: { color: 'secondary', icon: <XCircle />, title: 'Disabled' },
  UNKNOWN: { color: 'warning', icon: <QuestionCircle />, title: 'Unknown' },
};

const hiddenToButton = {
  true: { title: 'Show', icon: <Eye /> },
  false: { title: 'Hide', icon: <EyeSlash /> },
};

function HiddenValue({ value }) {
  const [hidden, setHidden] = useState(true);
  const button = hiddenToButton[hidden];

  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    toast.success('Copied to clipboard');
  }

  return (
    <InputGroup>
      <FormControl
        ref={textAreaRef}
        type={hidden ? 'password' : 'text'}
        value={value}
      />
      <InputGroup.Append>
        <Button
          onClick={() => setHidden(!hidden)}
          size='sm'
          variant='light'
          title={button.title}>
          {button.icon}
        </Button>
        <Button
          title='Copy to clipboard'
          disabled={hidden}
          onClick={copyToClipboard}
          size='sm'
          variant='secondary'>
          <Clipboard />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TokenList);
