import { call, put } from 'redux-saga/effects';
import { Unauthorized } from '../endpoints/users';
import { createWatcher } from './helpers';
import { LOGIN, login } from '../actions/auth';

export const watchLogin = createWatcher(LOGIN, callLogin);

export function* callLogin(action) {
  const credentials = action.payload;
  try {
    const response = yield call(Unauthorized.login, {
      username: credentials.username,
      password: credentials.password,
    });

    yield put(login.success(`${response.tokenType} ${response.accessToken}`));
  } catch (error) {
    yield put(login.failure(error));
  }
}
