import { createRequestTypes, createRequestActions } from './helpers';

const createTypes = (name) => createRequestTypes('REGISTER', name);

// export const REGISTER_REQUEST = 'REGISTER_REQUEST';
// export const registerRequest = (
//   account,
//   personalData,
//   password,
//   notificationsEnabled,
// ) => ({
//   type: REGISTER_REQUEST,
//   payload: {
//     account: account,
//     password: password,
//     personalData: personalData,
//     notificationsEnabled: notificationsEnabled,
//   },
// });

// export const REGISTER_FAILURE = 'REGISTER_FAILURE';
// export const registerFailure = () => ({
//   type: REGISTER_FAILURE,
// });

// export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
// export const registerSuccess = () => ({
//   type: REGISTER_SUCCESS,
// });

export const REGISTER = createTypes('REGISTER');

export const register = createRequestActions(REGISTER);
