import React from 'react';

const PersonalDataAgreement = () => (
    <div>
        <p>
            I, pursuant to Federal Law No. 152-FZ “On Personal Data”, dated July 27, 2006, hereby grant my consent
            for the processing of my personal data by National Research University Higher School of Economics
            (hereinafter, HSE University), located at: 20 Myasnitskaya Ulitsa, Moscow, Russian Federation.
        </p>
        <p>
            The list of personal data (hereinafter, the PD) includes: last name, first name, patronymic/middle name,
            gender, citizenship, date of birth, place of study and related address, place of employment and related
            address, status, registration address and postal address, (mobile/home/office) phone number, e-mail address,
            residence address, serial number of passport (ID), its date of issue and issuing authority, personal photos,
            photos and video, information on participation in academic Olympiads, contests, competitions and other
            events held by HSE University and/or other institutions, and related achieved results, as well as other
            information submitted to HSE University with respect to participation in academic Olympiads, contests,
            competitions and other events held by HSE University or with its involvement.
        </p>
        <p>
            Personal data shall be processed for the following purposes:
            <ol>
                <li>
                    execution of applicable legal acts and other regulations, as well as resolutions, instructions and
                    enquires by state authorities and officials acting by order, or on behalf, of such authorities;
                </li>
                <li>
                    organizing and holding International Data Analysis Olympiad (IDAO) (including related photographs
                    and video-recording during the event, as well as their distribution at the PD Subject’s consent) at
                    HSE University or with its involvement;
                </li>
                <li>
                    the PD Subject’s personal identification for the purposes of his/her participation in International
                    Data Analysis Olympiad (IDAO);
                </li>
                <li>
                    accumulating information on persons interacting with HSE University, as well as archive storage of
                    such information.
                </li>
            </ol>
        </p>
        <p>
            Personal data shall be processed (including automated and manual processing) in the following ways:
            collection, recording, systematization, accumulation, storage, clarification (updates and modifications),
            retrieval, use, transfer (presentation, and provision of access), depersonalization, blocking, deletion, and
            destruction. This consent shall be valid for 5 (five) years after the date of this consent. This Consent for
            data processing may be withdrawn in the event of violation of the rules for personal data processing, and in
            other cases stipulated by Federal Law No. 152-FZ “On Personal Data”, dated July 27, 2006, on the basis of
            the PD Subject’s request submitted to HSE University.
        </p>
        <p>
            I hereby allow HSE University to submit personal data provided in this PD Consent to the following
            organizations:
            <ol>
                <li>
                    PJSC Bank “FC Otkritie” (OGRN 1027739019208, registration entry date: July 26, 2002; General License
                    of the Bank of Russia No. 2209, dated November 24, 2014; INN 7706092528, KPP 770501001, address:
                    115114, 2 Letnikovskaya Ulitsa, bldg. 4, Moscow, e-mail: info@open.ru);
                </li>
                <li>
                    LLC Yandex (OGRN 1027700229193, registration entry date: September 18, 2002; INN 7736207543, KPP
                    770401001, address: 119021, 16 Ulitsa Lva Tolstogo, Moscow, e-mail: client-service@yandex-team.ru);
                </li>
            </ol>
        </p>
        <p>
            If any other partner organizations are added to the above-mentioned list of organizations, the list and
            related details shall be forwarded to email addresses of all participants of the event, including my contact
            email, and, therefore, I shall be entitled and able to familiarize myself with information about such
            organizations.
        </p>
        <p>
            PD will be transferred to these organizations for further processing by such organizations for the purposes
            of sending me their proposals for cooperation, including by contacting me directly using the specified means
            of communication within 3 years after the date of signing this consent. These actions can be performed using
            automation tools. I have been informed that the consent to my personal data processing by organizations can
            be revoked on the basis of a notification to be provided to the official corporate e-mails of the
            organizations.
        </p>
        <p>
            HSE University has the right to make photographs and video records of the International Data Analysis
            Olympiad (IDAO) events and post them in social networks and on HSE University website (portal) for
            information purposes. The PD Subject provides his/her consent to the use and disclosure of his/her image in
            such videos and photographs, as per the meaning of Article 152.1 of the Civil Code of the Russian
            Federation. HSE University has the right to obtain the PD Subject’s additional consent to the publication of
            his/her images in social networks or in any other ways of communication with the PD Subject during the
            Olympiad.
        </p>
    </div>
);

export default PersonalDataAgreement;
