import { takeEvery, takeLatest, all, call } from 'redux-saga/effects';

import { REQUEST } from '../actions/helpers';

export function createWatcher(actionType, executor, takeOption = 'latest') {
  switch (takeOption) {
    case 'latest':
      return function* () {
        yield takeLatest(actionType[REQUEST], executor);
      };
    case 'all':
      return function* () {
        yield takeEvery(actionType[REQUEST], executor);
      };
    default:
      return function* () {
        yield takeLatest(actionType[REQUEST], executor);
      };
  }
}

export function combineWatchers(...sagas) {
  return function* () {
    yield all(sagas.map((s) => call(s)));
  };
}
