import React from 'react';
import axios from '../../config/api';

import { Button, Container, Spinner } from 'react-bootstrap';

export default class PasswordResetProcessor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasResponse: false,
      responseType: 'loading',
      responseMessage: 'Loading...',
    };

    this.showResponse = this.showResponse.bind(this);
  }
  componentDidMount() {
    axios
      .get('/auth/password/reset?token=' + this.props.match.params.token)
      .then((res) => {
        this.showResponse(
          'success',
          'Password was successfully reset. Please, check your email',
        );
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          this.showResponse(
            'error',
            // eslint-disable-next-line
            "Token not found in system.\
                        \n Possible reasons and solutions:\
                        \n - Maybe the reset token is expired.\
                        \n - Maybe you've already reset your password. Please, check email and try sign-in.\
                        \n - Maybe the link is incorrect. Try copy text link from the letter and paste it to the browser.\
                        \n If this didn't help, please, contact administator ( hello@idao.world ). Please, don't forget to write your username",
          );
        } else {
          this.showResponse(
            'unknown-error',
            'Please, try again or report issue if it doesn`t help',
          );
        }
      });
  }

  showResponse(type, message) {
    this.setState({
      hasResponse: true,
      responseType: type,
      responseMessage: message,
    });
  }

  render() {
    return (
      <Container className='d-flex justify-content-center'>
        {this.state.hasResponse ? (
          <div id='verification-response' style={{ 'white-space': 'pre-wrap' }}>
            {this.state.responseMessage}
          </div>
        ) : (
          <Button variant='primary' disabled>
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
            Loading... Please, wait
          </Button>
        )}
      </Container>
    );
  }
}
