import { combineReducers } from 'redux';
import authReducer from './auth';
import registerReducer from './register';
import newsReducer from './news';
import profileReducer from './profile';
import teamReducer from './teams';
import contestReducer from './contests';

export default combineReducers({
  register: registerReducer,
  auth: authReducer,
  profile: profileReducer,
  news: newsReducer,
  teams: teamReducer,
  contests: contestReducer,
});
