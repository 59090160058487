import { put } from 'redux-saga/effects';
import { createWatcher } from './helpers';
import { initApp, INIT_APP } from '../actions/init';
import { getNews } from '../actions/news';
import { getAccounts } from '../actions/profile';

export const watchInit = createWatcher(INIT_APP, callInit);

export function* callInit(action) {
  //   const user = action.payload;
  try {
    yield put(getNews.request());
    yield put(getAccounts.request());
    yield put(initApp.success());
  } catch (error) {
    yield put(initApp.failure(error));
  }
}
