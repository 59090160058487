import React, { useState, useEffect } from 'react';
import { Container, Form, Col, Button } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import PhoneInput from 'react-phone-input-2';

import { countries } from '../../properties';
import { connect } from 'react-redux';
import {
  getPersonalData,
  editPersonalData,
  getAccount,
  editAccount,
} from '../../actions/profile';
import Skeleton from 'react-loading-skeleton';
import { isAdmin } from '../../tools';
import ChangePassword from './ChangePassword';
import { Redirect } from 'react-router-dom';

const mapStateToProps = (state) => ({
  user: state.auth.user,
  accounts: state.profile.accounts,
  personalData: state.profile.personalData,
});

const mapDispatchToProps = (dispatch) => ({
  getAccount: (id) => dispatch(getAccount.request(id)),
  getData: (id) => dispatch(getPersonalData.request(id)),
  editAccount: (id, account) =>
    dispatch(editAccount.request({ id: id, account: account })),
  editData: (id, data) =>
    dispatch(editPersonalData.request({ id: id, personalData: data })),
});

const ProfileSettings = ({
  user,
  accounts,
  personalData,
  getAccount,
  editAccount,
  getData,
  editData,
  match,
}) => {
  const id = parseInt(match.params.id);

  useEffect(() => {
    getData(id);
    getAccount(id);
  }, [getData, getAccount, id]);

  const account = accounts[id];
  const data = personalData[id];

  if (user.id !== id && !isAdmin(user)) {
    return <Redirect to='not-found' />;
  }

  return (
    <Container>
      <h3>Profile settings</h3>
      {!account || !data ? (
        <Skeleton height={50} />
      ) : (
        <>
          {isAdmin(user) && account && (
            <>
              <AccountEditor account={account} onSubmit={editAccount} />
              <hr />
            </>
          )}
          <PersonalDataEditor
            data={data}
            onSubmit={editData}
            allFields={isAdmin(user)}
          />
          <hr />
          <ChangePassword id={account.id} />
        </>
      )}
    </Container>
  );
};

const AccountEditor = ({ account, onSubmit }) => {
  const [username, setUsername] = useState(account.username);
  const [name, setName] = useState(account.name);
  return (
    <>
      <h4>Edit account</h4>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Username</Form.Label>
          <Form.Control
            type='text'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Form.Group>
      </Form.Row>
      <Button
        block
        variant='secondary mb-2'
        onClick={() =>
          onSubmit(account.id, { ...account, username: username, name: name })
        }>
        Save account
      </Button>
    </>
  );
};

const PersonalDataEditor = ({ data, onSubmit, allFields }) => {
  const [email, setEmail] = useState(data.email);
  const [phoneNumber, setPhoneNumber] = useState(data.phoneNumber);
  const [countryOfResidence, setCountryOfResidence] = useState(
    data.countryOfResidence,
  );
  const [company, setCompany] = useState(data.company);
  const [university, setUniversity] = useState(data.university);
  const [studyProgram, setStudyProgram] = useState(data.studyProgram);
  const [levelOfStudy, setLevelOfStudy] = useState(data.levelOfStudy);
  const [occupation, setOccupation] = useState(
    data.university ? 'student' : 'employee',
  );

  const setCountry = (selected) => {
    if (selected.length !== 1) {
      return;
    }

    setCountryOfResidence(selected[0]);
  };

  return (
    <Form>
      <h4>Edit personal data</h4>
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Phone number</Form.Label>
          <PhoneInput
            placeholder='+1(234)567-89-01'
            showCountrySelect={false}
            value={phoneNumber}
            onChange={setPhoneNumber}
          />
        </Form.Group>
        {allFields && (
          <>
            <Form.Group as={Col}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='user@example.com'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </>
        )}
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Country of residence</Form.Label>
          <Typeahead
            id='country'
            placeholder='Country'
            defaultSelected={[countryOfResidence]}
            options={countries}
            onChange={setCountry}
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Current occupation</Form.Label>
          <Form.Control
            as='select'
            value={occupation}
            onChange={(e) => setOccupation(e.target.value)}>
            <option value='student'>Student</option>
            <option value='employee'>Employee</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          {occupation === 'employee' ? (
            <>
              <Form.Label>Company</Form.Label>
              <Form.Control
                controlid='company'
                value={company}
                onChange={(event) => setCompany(event.target.value)}
                type='text'
                placeholder='Company'
              />
            </>
          ) : (
            <>
              <Form.Label>University</Form.Label>
              <Form.Control
                controlid='university'
                value={university}
                onChange={(event) => setUniversity(event.target.value)}
                type='text'
                placeholder='University'
              />
              <Form.Control
                className='mt-2'
                value={studyProgram}
                onChange={(event) => setStudyProgram(event.target.value)}
                type='text'
                placeholder='Study program'
              />
              <Form.Group>
                <Form.Control
                  as='select'
                  className='mt-2'
                  value={levelOfStudy}
                  onChange={(event) => setLevelOfStudy(event.target.value)}>
                  <option value='Bachelor'>Bachelor</option>
                  <option value='Master'>Master</option>
                  <option value='PhD'>PhD</option>
                </Form.Control>
              </Form.Group>
            </>
          )}
        </Form.Group>
      </Form.Row>

      <Button
        block
        variant='secondary'
        onClick={() => {
          onSubmit(data.userId, {
            ...data,
            email: email,
            phoneNumber: phoneNumber,
            countryOfResidence: countryOfResidence,
            company: company,
            university: university,
            studyProgram: studyProgram,
            levelOfStudy: levelOfStudy,
          });
        }}>
        Save personal data
      </Button>
    </Form>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);
