import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  InputGroup,
  ProgressBar,
  Table,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { BoxArrowLeft, Eye, EyeSlash } from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { getTeamById } from '../../actions/teams';
import {
  getContestById,
  getStageTeams,
  getStageById,
  getTeamStageKeys,
  removeTeamFromStage,
} from '../../actions/contests';

const mapStateToProps = (state) => ({
  contests: state.contests.contests,
  stages: state.contests.stages,
  stageTeams: state.contests.stageTeams,
  teams: state.teams.teams,
  keys: state.contests.keys,
});

const mapDispatchToProps = (dispatch) => ({
  getStageById: (stageId) => dispatch(getStageById.request(stageId)),
  getContestById: (contestId) => dispatch(getContestById.request(contestId)),
  getTeamById: (teamId) => dispatch(getTeamById.request(teamId)),
  getStageTeams: (stageId) => dispatch(getStageTeams.request(stageId)),
  getStageTeamKeys: (stageId, teamId) =>
    dispatch(getTeamStageKeys.request({ stageId: stageId, teamId: teamId })),
  removeTeamFromStage: (stageId, teamId) =>
    dispatch(removeTeamFromStage.request({ stageId: stageId, teamId: teamId })),
});

const ViewStageTeams = ({
  match,
  stages,
  contests,
  teams,
  stageTeams,
  keys,
  getStageById,
  getContestById,
  getTeamById,
  getStageTeams,
  getStageTeamKeys,
  removeTeamFromStage,
}) => {
  const { contestId, stageId } = match.params;
  const contest = contests[contestId];
  const stage = stages[stageId];
  const [search, setSearch] = useState('');
  const [actionsEnabled, setActionsEnabled] = useState(false);

  const [keysVisible, setKeysVisible] = useState(false);

  const stageKeys = keys[stageId] ? keys[stageId] : {};

  useEffect(() => {
    if (!contest) {
      getContestById(contestId);
    }
  }, [contestId, !contest]);

  useEffect(() => {
    if (!stage) {
      getStageById(stageId);
    }
  }, [stageId, !stage, getStageById]);

  useEffect(() => {
    if (!stageTeams[stageId]) {
      getStageTeams(stageId);
    }
  }, [stageId, getStageTeams]);

  useEffect(() => {
    const teamList = stageTeams[stageId];
    if (teamList) {
      teamList.forEach((teamId) => {
        const team = teams[teamId];
        if (!team) {
          getTeamById(teamId);
        }
      });
    }
  }, [stageId, getTeamById, !!stageTeams[stageId]]);

  const handleChangeSearch = (event) => setSearch(event.target.value);
  const handleChangeActionsEnabled = () => setActionsEnabled(!actionsEnabled);

  if (!contest || !stage || !stageTeams[stageId]) {
    return <Skeleton />;
  }

  const teamList = stageTeams[stageId]
    .map((t) => teams[t])
    .filter((t) => t !== undefined);
  const filteredTeams = search
    ? teamList.filter((t) => t.name.toLowerCase().match(search.toLowerCase()))
    : teamList;

  const total = stageTeams[stageId].length;

  return (
    <Container>
      <h3>
        <span>{contest.name}</span> /{' '}
        <span style={{ color: '#247ba0' }}>{stage.name}</span>
      </h3>
      <p>Total {total}</p>
      {teamList.length !== total && (
        <ProgressBar
          animated
          variant='primary'
          now={(teamList.length / total) * 100}
          label={`${teamList.length} / ${total}`}
        />
      )}
      <Form.Check
        checked={actionsEnabled}
        onChange={handleChangeActionsEnabled}
        type='checkbox'
        label='Enable actions'
      />
      <Form.Check
        checked={keysVisible}
        onChange={() => setKeysVisible(!keysVisible)}
        type='checkbox'
        label='Show keys'
      />

      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>Search</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          value={search}
          onChange={handleChangeSearch}
          type='text'
          placeholder='Enter team name'
        />
      </InputGroup>

      {search && (
        <p>Found {filteredTeams.length}</p>
      )}
      <Table responsive stripped hover bordered>
        <thead className='thead-dark'>
          <th>ID</th>
          <th>Team name</th>
          {keysVisible && <th>Keys</th>}
          {actionsEnabled && <th>Actions</th>}
        </thead>
        <tbody>
          {filteredTeams.map((team) => (
            <TeamRow
              stageId={stageId}
              keysVisible={keysVisible}
              stageKeys={stageKeys}
              withActions={actionsEnabled}
              team={team}
              getStageTeamKeys={getStageTeamKeys}
              removeTeamFromStage={removeTeamFromStage}
            />
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

const TeamRow = ({
  stageId,
  team,
  withActions,
  keysVisible,
  getStageTeamKeys,
  stageKeys,
  removeTeamFromStage,
}) => {
  const handleRemove = () => {
    removeTeamFromStage(stageId, team.id);
  };

  const teamKeys = stageKeys[team.id];

  useEffect(() => {
    if (keysVisible && !teamKeys) {
      getStageTeamKeys(stageId, team.id);
    }
  }, [stageId, keysVisible, getStageTeamKeys]);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const hidePassword = () => setPasswordVisible(false);
  const showPassword = () => setPasswordVisible(true);

  return (
    <tr>
      <td>{team.id}</td>
      <td>{team.name}</td>
      {keysVisible && (
        <td>
          {teamKeys ? (
            <div>
              {teamKeys.login}
              <br />
              {passwordVisible ? (
                <>
                  <span>{teamKeys.password}</span>
                  <Button
                    className='ml-2'
                    size='sm'
                    variant='light'
                    onClick={hidePassword}>
                    <Eye />
                  </Button>
                </>
              ) : (
                <>
                  <span>
                    {new Array(teamKeys.password.length).fill('*').join('')}
                  </span>
                  <Button
                    className='ml-2'
                    size='sm'
                    variant='light'
                    onClick={showPassword}>
                    <EyeSlash />
                  </Button>
                </>
              )}
            </div>
          ) : (
            <Skeleton />
          )}
        </td>
      )}
      {withActions && (
        <td>
          <Button onClick={handleRemove} variant='danger' size='sm'>
            <BoxArrowLeft />
          </Button>
        </td>
      )}
    </tr>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewStageTeams);
