import { createRequestTypes, createRequestActions } from './helpers';

const createTypes = (name) => createRequestTypes('CONTESTS', name);

export const CREATE_CONTEST = createTypes('CREATE_CONTEST');
export const EDIT_CONTEST = createTypes('EDIT_CONTEST');
export const EDIT_SETTINGS = createTypes('EDIT_SETTINGS');
export const EDIT_STAGE = createTypes('EDIT_STAGE');
export const UPLOAD_KEYS = createTypes('UPLOAD_KEYS');
export const PROMOTE_TEAMS = createTypes('PROMOTE_TEAMS');

export const GET_CONTEST_BY_ID = createTypes('GET_CONTEST_BY_ID');
export const GET_SETTINGS = createTypes('GET_SETTINGS');
export const GET_DRAFT_CONTESTS = createTypes('GET_DRAFT_CONTESTS');
export const GET_OPENED_CONTESTS = createTypes('GET_OPENED_CONTESTS');
export const GET_CLOSED_CONTESTS = createTypes('GET_CLOSED_CONTESTS');
export const GET_ARCHIVED_CONTESTS = createTypes('GET_ARCHIVED_CONTESTS');
export const GET_USER_CONTESTS = createTypes('GET_USER_CONTESTS');

export const GET_STAGES_BY_CONTEST = createTypes('GET_STAGES_BY_CONTEST');
export const GET_STAGE_BY_ID = createTypes('GET_STAGE_BY_ID');
export const GET_STAGE_TEAMS = createTypes('GET_STAGE_TEAMS');

export const GET_TEAM_STAGE_KEYS = createTypes('GET_TEAM_STAGE_KEYS');

export const REMOVE_TEAM_FROM_STAGE = createTypes('REMOVE_TEAM_FROM_STAGE');

export const OPEN_CONTEST = createTypes('OPEN_CONTEST');
export const CLOSE_CONTEST = createTypes('CLOSE_CONTEST');
export const ARCHIVE_CONTEST = createTypes('ARCHIVE_CONTEST');
export const DELETE_CONTEST = createTypes('DELETE_CONTEST');

export const getContestById = createRequestActions(GET_CONTEST_BY_ID);
export const getDraftContests = createRequestActions(GET_DRAFT_CONTESTS);
export const getOpenedContests = createRequestActions(GET_OPENED_CONTESTS);
export const getClosedContests = createRequestActions(GET_CLOSED_CONTESTS);
export const getArchivedContests = createRequestActions(GET_ARCHIVED_CONTESTS);
export const getUserContests = createRequestActions(GET_USER_CONTESTS);
export const getStagesByContest = createRequestActions(GET_STAGES_BY_CONTEST);
export const getStageById = createRequestActions(GET_STAGE_BY_ID);
export const getStageTeams = createRequestActions(GET_STAGE_TEAMS);
export const getTeamStageKeys = createRequestActions(GET_TEAM_STAGE_KEYS);
export const removeTeamFromStage = createRequestActions(REMOVE_TEAM_FROM_STAGE);

export const getSettings = createRequestActions(GET_SETTINGS);

export const openContest = createRequestActions(OPEN_CONTEST);
export const closeContest = createRequestActions(CLOSE_CONTEST);
export const archiveContest = createRequestActions(ARCHIVE_CONTEST);
export const deleteContest = createRequestActions(DELETE_CONTEST);

export const JOIN_CONTEST = createTypes('JOIN_CONTEST');

export const joinContest = createRequestActions(JOIN_CONTEST);

export const editContest = createRequestActions(EDIT_CONTEST);
export const editSettings = createRequestActions(EDIT_SETTINGS);
export const editStage = createRequestActions(EDIT_STAGE);
