import React, { useEffect } from 'react';
import AdminContestCard from './AdminContestCard';
import {
  getDraftContests,
  getArchivedContests,
  getClosedContests,
  getOpenedContests,
} from '../../actions/contests';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  drafts: state.contests.drafts,
  opened: state.contests.opened,
  closed: state.contests.closed,
  archived: state.contests.archived,
});

const mapDispatchToProps = (dispatch) => ({
  getDrafts: () => dispatch(getDraftContests.request()),
  getOpened: () => dispatch(getOpenedContests.request()),
  getClosed: () => dispatch(getClosedContests.request()),
  getArchived: () => dispatch(getArchivedContests.request()),
});

const ManageContests = ({
  drafts,
  opened,
  closed,
  archived,
  getDrafts,
  getOpened,
  getClosed,
  getArchived,
}) => {
  useEffect(() => {
    if (!drafts.length) getDrafts();
  }, [drafts.length]);

  useEffect(() => {
    if (!opened.length) getOpened();
  }, [opened.length]);

  useEffect(() => {
    if (!closed.length) getClosed();
  }, [closed.length]);

  useEffect(() => {
    if (!archived.length) getArchived();
  }, [archived.length]);

  const contestLists = [
    { label: 'Drafts', list: drafts },
    { label: 'Registration open', list: opened },
    { label: 'Registration closed', list: closed },
    { label: 'Archived', list: archived },
  ];

  return (
    <div className='container'>
      <h3>
        Manage contests
        <Link to='/contests/new' className='ml-2 btn btn-outline-dark btn-sm'>
          +
        </Link>
      </h3>
      {contestLists
        .filter((e) => e.list.length)
        .map((el) => (
          <ContestList header={el.label} contests={el.list} />
        ))}
    </div>
  );
};

const ContestList = ({ header, contests }) => (
  <div className='mt-2 mb-2'>
    <h4>{header}</h4>
    <div className='list'>
      {contests.map((id) => (
        <div key={header + id} className='list-item mr-2 ml-2'>
          <AdminContestCard id={id} />
        </div>
      ))}
    </div>
  </div>
);

export default connect(mapStateToProps, mapDispatchToProps)(ManageContests);
