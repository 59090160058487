import React, { Component } from 'react';
import zxcvbn from 'zxcvbn';
import { ProgressBar } from 'react-bootstrap'

class PasswordStrengthMeter extends Component {

  createPasswordLabel = (result) => {
    switch (result.score) {
      case 0:
        return 'Weak';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return 'Weak';
    }
  }

  createProgressBarColor = (result) => {
    switch (result.score) {
      case 0:
        return 'danger';
      case 1:
        return 'danger';
      case 2:
        return 'warning';
      case 3:
        return 'info';
      case 4:
        return 'success';
      default:
        return 'danger';
    }
  }

  testPassword = (password) => {
    return (password.match(/[a-z]/g) && password.match(
      /[A-Z]/g) && password.match(
        /[0-9]/g) && password.match(
          /[^a-zA-Z\d]/g) && password.length >= 8)
  }

  validatePassword = (password) => {
    if (password.length < 1)
      return { score: 0 }
    if (!this.testPassword(password))
      return { score: 1 }
    return zxcvbn(password)
  }

  render() {
    const { password } = this.props;
    const testedResult = this.validatePassword(password);
    return (
        <ProgressBar
          animated
          striped
          variant={this.createProgressBarColor(testedResult)}
          now={testedResult.score * 25}
          label={this.createPasswordLabel(testedResult)}
        />
    );
  }
}

export default PasswordStrengthMeter;