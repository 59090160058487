import { call, put } from 'redux-saga/effects';
import {
  getAccount,
  GET_ACCOUNT,
  getPersonalData,
  GET_PERSONAL_DATA,
  editPersonalData,
  EDIT_PERSONAL_DATA,
  editAccount,
  EDIT_ACCOUNT,
  getAccounts,
  GET_ACCOUNTS,
  deleteAccount,
  DELETE_ACCOUNT,
} from '../actions/profile';
import { createWatcher, combineWatchers } from './helpers';
import { Account, PersonalData } from '../endpoints/users';

function* callGetAccount(action) {
  const id = action.payload;
  try {
    const response = yield call(Account.getById, id);
    yield put(getAccount.success(response, id));
  } catch (error) {
    yield put(getAccount.failure(error, id));
  }
}

function* callGetData(action) {
  const id = action.payload;
  try {
    const response = yield call(PersonalData.getById, id);
    yield put(getPersonalData.success(response, id));
  } catch (error) {
    yield put(getPersonalData.failure(error, id));
  }
}

function* callEditData(action) {
  const { id, personalData } = action.payload;
  try {
    yield call(PersonalData.edit, id, personalData);
    yield put(editPersonalData.success(`Changes saved`));
    yield put(getPersonalData.request(id));
  } catch (error) {
    yield put(editPersonalData.failure(error, id));
  }
}

function* callEditAccount(action) {
  const { id, account } = action.payload;
  try {
    yield call(Account.edit, id, account);
    yield put(editAccount.success(`Changes saved`));
    yield put(getAccount.request(id));
  } catch (error) {
    yield put(editAccount.failure(error, id));
  }
}

function* callGetAccounts() {
  try {
    const response = yield call(Account.getAll);
    yield put(getAccounts.success(response));
  } catch (error) {
    yield put(getAccounts.failure(error));
  }
}

function* callDeleteAccount(action) {
  const id = action.payload;
  try {
    const response = yield call(Account.delete, id);
    yield put(deleteAccount.success(response, id));
  } catch (error) {
    yield put(deleteAccount.failure(error, id));
  }
}

const watchGetAccount = createWatcher(GET_ACCOUNT, callGetAccount, 'all');
const watchGetData = createWatcher(GET_PERSONAL_DATA, callGetData, 'all');
const watchEditData = createWatcher(EDIT_PERSONAL_DATA, callEditData);
const watchEditAccount = createWatcher(EDIT_ACCOUNT, callEditAccount);
const watchGetAccounts = createWatcher(GET_ACCOUNTS, callGetAccounts);
const watchDeleteAccount = createWatcher(
  DELETE_ACCOUNT,
  callDeleteAccount,
  'all',
);

export const watchProfile = combineWatchers(
  watchGetAccounts,
  watchGetAccount,
  watchEditAccount,
  watchGetData,
  watchEditData,
  watchDeleteAccount,
);
