import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

const SuccessRegisterPage = () => {
  const [countDown, setCountDown] = useState(10);

  useEffect(() => {
    const timer = setTimeout(() => setCountDown((c) => c - 1), 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {!countDown && <Redirect to='/auth/login' />}
      <div
        style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
        }}>
        <div>
          <h3>Congratulations!</h3>
          <div>
            You successfully created account.
            <br />
            Please, check your email to complete registration
            <p>
              Don't see the activation letter? Please, check your spam folder
            </p>
            <Link to='/auth/login'>Go to login page</Link>
            <p>Auto redirect in {countDown}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessRegisterPage;
