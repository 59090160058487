import { createRequestTypes, createRequestActions } from './helpers';

const createTypes = (name) => createRequestTypes('NEWS', name);

export const GET_NEWS = createTypes('GET_NEWS');
export const CREATE_ARTICLE = createTypes('CREATE_ARTICLE');
export const EDIT_ARTICLE = createTypes('EDIT_ARTICLE');
export const DELETE_ARTICLE = createTypes('DELETE_ARTICLE');

export const getNews = createRequestActions(GET_NEWS);
export const createArticle = createRequestActions(CREATE_ARTICLE);
export const editArticle = createRequestActions(EDIT_ARTICLE);
export const deleteArticle = createRequestActions(DELETE_ARTICLE);
