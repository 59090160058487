import React from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import ViewArticle from './ViewArticle';

import { connect } from 'react-redux';
import { isAdmin } from '../../tools';

const mapStateToProps = (state) => ({
  isAdmin: isAdmin(state.auth.user),
  news: state.news.news,
  isLoading: state.news.isLoading,
});

const ViewNews = ({ isAdmin, news, isLoading }) => (
  <>
    <h3>
      News
      {isAdmin && (
        <Link
          className='ml-2 btn btn-outline-dark btn-sm'
          to='/news/new'
          title='Add new article'>
          +
        </Link>
      )}
    </h3>
    {isLoading ? (
      <Skeleton count={10} height={50} />
    ) : (
      <div>
        {news.map((a) => (
          <ViewArticle key={a.id} article={a} showControls={isAdmin} />
        ))}
      </div>
    )}
  </>
);

export default connect(mapStateToProps)(ViewNews);
