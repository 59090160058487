import React, { useState, useEffect } from 'react';
import { Badge, Container, Table, Form } from 'react-bootstrap';

import axios from '../../config/api';
import UserRow from './UserRow';
import { getNumberOfPages } from '../../tools';
import PageNavigation from './PageNavigation';

const resultPerPage = 30;

const ViewRegisteredUsers = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [showActions, setShowActions] = useState(false);
  useEffect(() => {
    axios.get('/admin/registered-users').then((response) => {
      const users = response.sort((a, b) => b - a);
      setUsers(users);
      setLastPage(getNumberOfPages(users.length, resultPerPage));
    });
  }, [setUsers]);

  const handlePageChange = (num) => {
    if (num > 0 && num <= lastPage) {
      setPage(num);
    }
  };

  const idsToDisplay = users.slice(
    (page - 1) * resultPerPage,
    page * resultPerPage,
  );

  return (
    <Container>
      <div>
        <h3>Registered users</h3>
      </div>
      <h5>
        <Badge variant='success'>Total count: {users.length}</Badge>
      </h5>
      <Form.Check
        checked={showActions}
        onChange={() => setShowActions(!showActions)}
        type='checkbox'
        label='Enable actions'
      />
      <Table className='mt-3' striped bordered hover>
        <thead className='thead-dark'>
          <tr>
            <th>id</th>
            <th>username</th>
            <th>email</th>
            <th>name</th>
            <th>country</th>
            <th>birthday</th>
            <th>occupation</th>
            <th>registered</th>
            {showActions && <th>actions</th>}
          </tr>
        </thead>
        <tbody>
          {idsToDisplay.map((id) => (
            <UserRow key={id} id={id} showControls={showActions} showActivate />
          ))}
        </tbody>
      </Table>
      <PageNavigation
        current={page}
        total={lastPage}
        onChange={handlePageChange}
      />
    </Container>
  );
};

export default ViewRegisteredUsers;
