import { REQUEST, FAILURE, SUCCESS } from '../actions/helpers';
import {
  GET_PERSONAL_DATA,
  EDIT_PERSONAL_DATA,
  GET_ACCOUNT,
  EDIT_ACCOUNT,
  GET_ACCOUNTS,
  DELETE_ACCOUNT,
} from '../actions/profile';
import { toast } from 'react-toastify';

const defaultState = {
  activated: [],
  accounts: {},
  personalData: {},
  isLoading: false,
  error: '',
};

const profileReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_ACCOUNT[REQUEST]:
    case GET_ACCOUNTS[REQUEST]:
    case GET_PERSONAL_DATA[REQUEST]:
    case EDIT_ACCOUNT[REQUEST]:
    case EDIT_PERSONAL_DATA[REQUEST]:
      return { ...state, isLoading: true };
    case GET_ACCOUNT[FAILURE]:
    case GET_ACCOUNTS[FAILURE]:
    case DELETE_ACCOUNT[FAILURE]:
    case GET_PERSONAL_DATA[FAILURE]:
      return { ...state, isLoading: false };
    case EDIT_ACCOUNT[FAILURE]:
    case EDIT_PERSONAL_DATA[FAILURE]:
      return { ...state, isLoading: false, error: action.payload.error };
    case GET_PERSONAL_DATA[SUCCESS]:
      const personalData = {
        ...state.personalData,
        [action.payload.requested]: action.payload.response,
      };
      return {
        ...state,
        personalData: personalData,
        isLoading: false,
      };
    case EDIT_ACCOUNT[SUCCESS]:
    case EDIT_PERSONAL_DATA[SUCCESS]:
      toast.success(action.payload.response);
      return {
        ...state,
        isLoading: false,
      };
    case GET_ACCOUNT[SUCCESS]:
      const accounts = {
        ...state.accounts,
        [action.payload.requested]: action.payload.response,
      };
      return {
        ...state,
        isLoading: false,
        accounts: accounts,
      };
    case GET_ACCOUNTS[SUCCESS]:
      const data = action.payload.response.reduce((map, object) => {
        map[object.id] = object;
        return map;
      }, {});

      const activated = action.payload.response.map(u => u.id)
      return { ...state, accounts: { ...data, ...state.accounts }, activated: activated };
    case DELETE_ACCOUNT[SUCCESS]: {
      const id = action.payload.requested;
      const accounts = state.accounts;
      toast.success(`Account '${accounts[id].username}' was removed`);
      delete accounts[id];
      const data = state.personalData;
      delete data[id];
      return { ...state, accounts: accounts, personalData: data };
    }
    default:
      return state;
  }
};

export default profileReducer;
