import React, { useState } from 'react';
import { Card, Button, Modal } from 'react-bootstrap';
import { Trophy, ShieldLockFill, FlagFill } from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import StageKeyLoader from './StageKeyLoader';
import TeamPromoter from './TeamPromoter';
import { useStage } from '../../loaders/contests';
import { Stage } from '../../endpoints/contests';
import { useDispatch } from 'react-redux';
import { editStage } from '../../actions/contests';

const EditStage = ({ id, nextStageId, isLast }) => {
  const dispatch = useDispatch();
  const [stage, loaded] = useStage(id);

  const [keys, setKeys] = useState([]);
  const [showKeysLoader, setShowKeysLoader] = useState(false);
  const handleUploadKeys = () => {
    if (keys.length)
      Stage.addKeys(id, keys).then(() => toast.success('Keys added'));
  };
  const resetKeys = () => {
    setShowKeysLoader(false);
    setKeys([]);
  };
  const [finalists, setFinalists] = useState([]);
  const [showFinalists, setShowFinalists] = useState(false);
  const [showWinners, setShowWinners] = useState(false);
  const resetFinalists = () => {
    setFinalists([]);
    setShowFinalists(false);
    setShowWinners(false);
  };
  const promoteFinalists = () => {
    Stage.promoteTeams(id, nextStageId, finalists).then(() => {
      toast.success('Teams promoted')
      setShowFinalists(false)

    })
  };

  const finishContest = () => {
    Stage.finishContest(id, finalists).then(() => {
      toast.success('Contest finished')
      setShowWinners(false)
    })
  };


  const saveStage = (stage) => {
    dispatch(editStage.request(stage));
  };

  if (!loaded) {
    return <Skeleton />;
  }

  return (
    <>
      <Modal show={showKeysLoader} onHide={resetKeys}>
        <Modal.Header closeButton>
          <Modal.Title>Keys for '{stage.name}'</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StageKeyLoader onUploaded={setKeys} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={resetKeys}>
            Cancel
          </Button>
          <Button disabled={!keys.length} onClick={handleUploadKeys}>
            Upload keys
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showFinalists} onHide={resetFinalists}>
        <Modal.Header closeButton>
          <Modal.Title>{`Add '${stage.name}' finalists`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TeamPromoter
            onUploaded={(value) => setFinalists(value)}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant='danger' onClick={resetFinalists}>
            Cancel
          </Button>
          <Button disabled={!finalists.length} onClick={promoteFinalists}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showWinners} onHide={resetFinalists}>
        <Modal.Header closeButton>
          <Modal.Title>{`Set contest winners`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TeamPromoter
            onUploaded={(value) => setFinalists(value)}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant='danger' onClick={resetFinalists}>
            Cancel
          </Button>
          <Button disabled={!finalists.length} onClick={finishContest}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Card>
        <Card.Header className='bg-dark text-center'>
          <Card.Title className='text-light'>{stage.name}</Card.Title>
        </Card.Header>
        <Card.Body>
          <div>
            <Form
              onSubmit={saveStage}
              initialValues={{ ...stage }}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => (
                  <form onSubmit={handleSubmit}>
                    <div>Dates</div>
                    <Field
                      name='startDate'
                      component='input'
                      type='date'
                      required
                    />
                    <Field
                      name='endDate'
                      component='input'
                      type='date'
                      required
                    />
                    <div>
                      <div className='mr-2'>Link</div>
                      <Field
                        name='platformLink'
                        component='input'
                        type='text'
                        placeholder='https://contest.yandex.ru/contest/xxxxx'
                      />
                    </div>
                    <div>
                      <button
                        type='submit'
                        className='btn btn-sm btn-success mt-2'>
                        Save changes
                    </button>
                    </div>
                  </form>
                )}
            />
          </div>
        </Card.Body>
        <Card.Footer className='text-center'>
          <Button
            className='mr-2 mt-2'
            size='sm'
            variant='info'
            onClick={() => setShowKeysLoader(true)}>
            <ShieldLockFill />
            Upload keys
          </Button>
          {!isLast && <Button
            className='mt-2 mr-2'
            size='sm'
            onClick={() => setShowFinalists(true)}>
            <Trophy />
            Promote finalists
          </Button>}
          {isLast && <Button className='mt-2 mr-2' size='sm' variant='dark' onClick={() => setShowWinners(true)}>
            <FlagFill />
            Finish contest
          </Button>
          }
        </Card.Footer>
      </Card>
    </>
  );
};

export default EditStage;
