import React from 'react';
import { Button, Badge, Form } from 'react-bootstrap';
import TextEditor from '../TextEditor';
import { Check, Pencil, Trash, XCircle } from 'react-bootstrap-icons';

import { editArticle, deleteArticle } from '../../actions/news';
import { connect } from 'react-redux';

const mapDispatchToProps = (dispatch) => ({
  editArticle: (article) => dispatch(editArticle.request(article)),
  deleteArticle: (articleID) => dispatch(deleteArticle.request(articleID)),
});

class ArticleView extends React.Component {
  state = {
    editMode: false,
    header: this.props.article.header,
    body: this.props.article.body,
  };

  handleSave = () => {
    const article = {
      id: this.props.article.id,
      header: this.state.header,
      body: this.state.body,
    };

    this.props.editArticle(article);
  };

  handleDelete = () => {
    if (
      window.confirm(`Are you sure you want to delete "${this.state.header}" ?`)
    ) {
      this.props.deleteArticle(this.props.article.id);
    }
  };

  render() {
    const { header, body, editMode } = this.state;
    const showControls = this.props.showControls;
    return (
      <div>
        <div className='mt-3 mb-3'>
          {showControls ? (
            <span>
              <span className='float-right'>
                {editMode ? (
                  <span>
                    <Button
                      className='mr-2'
                      size='sm'
                      title='Save changes'
                      onClick={this.handleSave}>
                      <Check />
                    </Button>
                    <Button
                      title='Cancel'
                      size='sm'
                      variant='secondary'
                      onClick={() => this.setState({ editMode: false })}>
                      <XCircle />
                    </Button>
                  </span>
                ) : (
                  <span>
                    <Button
                      className='mr-2'
                      title='Edit article'
                      size='sm'
                      variant='outline-success'
                      onClick={() => this.setState({ editMode: true })}>
                      <Pencil />
                    </Button>
                    <Button
                      title='Delete article'
                      size='sm'
                      variant='outline-danger'
                      onClick={this.handleDelete}>
                      <Trash />
                    </Button>
                  </span>
                )}
              </span>
              {editMode && (
                <>
                  <h4>Header</h4>
                  <Form.Control
                    placeholder='Enter header...'
                    type='text'
                    value={header}
                    onChange={(e) => this.setState({ header: e.target.value })}
                  />
                </>
              )}
            </span>
          ) : null}
          {!editMode && <h4>{header}</h4>}
          <Badge variant='light'>{this.props.article.publicationDate}</Badge>
          {body ? (
            <TextEditor
              className='mt-2'
              readOnly={!editMode}
              value={body}
              onChange={(v) => this.setState({ body: v })}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(ArticleView);
