import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Pencil, Trash, Check } from 'react-bootstrap-icons';
import {
  getPersonalData,
  deleteAccount,
  getAccount,
} from '../../actions/profile';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { Account } from '../../endpoints/users';
import { toast } from 'react-toastify';

const Occupation = ({ data }) => {
  if (data.company) {
    return (
      <div>
        <b>Employee of </b> {data.company}
      </div>
    );
  } else {
    return (
      <div>
        <b>Student of </b> {data.university}
        <br />
        {data.studyProgram}
        <br />
        {data.levelOfStudy}
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  accounts: state.profile.accounts,
  personalData: state.profile.personalData,
});

const mapDispatchToProps = (dispatch) => ({
  getAccount: (id) => dispatch(getAccount.request(id)),
  getPersonalData: (id) => dispatch(getPersonalData.request(id)),
  deleteAccount: (id) => dispatch(deleteAccount.request(id)),
});

const UserRow = ({
  id,
  accounts,
  personalData,
  getAccount,
  getPersonalData,
  deleteAccount,
  showControls,
  showActivate,
}) => {
  const account = accounts[id];
  const data = personalData[id];
  const handleDelete = () => {
    if (
      window.confirm(`Are you sure you want to delete "${account.username}" ?`)
    ) {
      deleteAccount(id);
    }
  };

  const shouldLoadAccount = !account
  const shouldLoadData = !data

  useEffect(() => {
    if (shouldLoadAccount) {
      getAccount(id);
    }
    if (shouldLoadData) {
      getPersonalData(id);
    }
  }, [getAccount, getPersonalData, id, shouldLoadAccount, shouldLoadData]);

  return (
    <tr>
      <td>{id}</td>
      {account ? (
        <>
          <td><Link to={`/profile/${id}`}>{account.username}</Link></td>
          <td>{account.name}</td>
        </>
      ) : (
        [1, 2].map((_,i) => <td key={`${id}-${i}`}><Skeleton /></td>)
      )}
      {data ? (
        <>
          <td>
            <a href={`mailto:${data.email}`}>{data.email}</a>
          </td>
          <td>{data.countryOfResidence}</td>
          <td>{data.birthday}</td>
          <td>
            <Occupation data={data} />
          </td>
          <td>{data.registrationDate}</td>
        </>
      ) : (
        [1, 2, 3, 4, 5].map((_, i) => (
          <td key={`${id}-${i}-data`}>
            <Skeleton />
          </td>
        ))
      )}
      {showControls && (
        <td>
          <Link
            className='btn btn-success btn-sm mr-1 mt-1'
            to={`/profile/${id}/edit`}>
            <Pencil />
          </Link>
          <Button className='mt-1 mr-1' size='sm' variant='danger' onClick={handleDelete}>
            <Trash />
          </Button>
          {showActivate && (
            <Button
              className='mt-1 mb-1 mr-1'
              title='Activate user'
              size='sm'
              variant='warning'
              onClick={() => Account.activate(id).then(() => toast.success(`Account ${account.username} activated`))}>
              <Check />
            </Button>
          )}
        </td>
      )}
    </tr>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRow);
