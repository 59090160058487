import { useEffect } from 'react';
import {
  getContestById,
  getSettings,
  getStagesByContest,
  getStageById,
} from '../actions/contests';
import { useSelector, useDispatch } from 'react-redux';

export function useContest(id) {
  const contests = useSelector((state) => state.contests.contests);
  const isOk = !!contests[id];

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isOk) {
      dispatch(getContestById.request(id));
    }
  }, [id, isOk, dispatch]);

  return [contests[id], isOk];
}

export function useSettings(id) {
  const settings = useSelector((state) => state.contests.settings);
  const isOk = !!settings[id];

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isOk) {
      dispatch(getSettings.request(id));
    }
  }, [id, isOk, dispatch]);

  return [settings[id], isOk];
}

export function useContestStages(id) {
  const dispatch = useDispatch();
  const contestStages = useSelector((state) => state.contests.contestStages);
  const isOk = !!contestStages[id];

  useEffect(() => {
    if (!isOk) {
      dispatch(getStagesByContest.request(id));
    }
  }, [id, isOk, dispatch]);

  return [contestStages[id], isOk];
}

export function useStage(id) {
  const dispatch = useDispatch();
  const stages = useSelector((state) => state.contests.stages);
  const isOk = !!stages[id];

  useEffect(() => {
    if (!isOk) {
      dispatch(getStageById.request(id));
    }
  }, [id, isOk, dispatch]);

  return [stages[id], isOk];
}
