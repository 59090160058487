import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { getTeamsByUser, getTeamById } from '../../actions/teams';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { joinContest } from '../../actions/contests';
import { toast } from 'react-toastify';

const mapStateToProps = (state) => ({
  user: state.auth.user,
  userTeams: state.teams.users,
  teams: state.teams.teams,
});

const mapDispatchToProps = (dispatch) => ({
  getTeamsByUser: (userId) => dispatch(getTeamsByUser.request(userId)),
  getTeamById: (teamId) => dispatch(getTeamById.request(teamId)),
  joinContest: (contestId, teamId) =>
    dispatch(joinContest.request({ teamId: teamId, contestId: contestId })),
});

const JoinButton = ({
  contestId,
  user,
  userTeams,
  teams,
  getTeamsByUser,
  getTeamById,
  joinContest,
}) => {
  const [show, setShow] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(undefined);

  useEffect(() => {
    getTeamsByUser(user.id);
  }, [user.id, getTeamsByUser]);

  const teamList = (userTeams[user.id] ? userTeams[user.id] : []).map(
    (teamId) => teams[teamId],
  );

  useEffect(() => {
    const uTeams = userTeams[user.id];
    if (uTeams) {
      uTeams.forEach((tId) => {
        if (!teams[tId]) {
          getTeamById(tId);
        }
      });
    }
  }, [teamList.length, getTeamById]);

  if (teamList.some((t) => t === undefined)) {
    return <Skeleton />;
  }

  const openTeams = teamList.filter((t) => t.status === 'OPEN');

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedTeam) {
      toast.warning('Select team to join contest');
      return;
    }
    joinContest(contestId, selectedTeam);
    setShow(false)
  };

  return (
    <div>
      <Modal closeButton onHide={() => setShow(false)} show={show}>
        <Modal.Header>
          <Modal.Title>Please, confirm action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          The action can not be undone.
          <p>
            <strong>
              If team is joined to contest, it's not allowed to edit team and
              invite or remove members.
            </strong>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-danger' onClick={() => setShow(false)}>
            No, let me think
          </Button>
          <Button variant='outline-success' onClick={handleSubmit}>
            Yes, I agree
          </Button>
        </Modal.Footer>
      </Modal>

      {openTeams.length ? (
        <>
          <div>
            Click on team to select it:
            <br/>
            {openTeams.map((t) => (
              <Button
              className='ml-2'
              onClick={() => setSelectedTeam(t.id)}
                variant={t.id === selectedTeam ? 'success' : 'outline-secondary'}>
                {t.name}
              </Button>
            ))}
          </div>
          <Button
            block
            size='lg'
            variant='success'
            className='mt-2'
            onClick={() => setShow(true)}>
            Become a participant
          </Button>
        </>
      ) : (
        <div>
          You don't have available teams. Please, find team to join or create
          new <br />{' '}
          <Link className='btn btn-lg btn-block btn-primary' to='/teams/new'>
            Create new
          </Link>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinButton);
