export const generatePassword = (length = 8) => {
  let charset =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
    retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const testPassword = (password) =>
  password.match(/[a-z]/g) &&
  password.match(/[A-Z]/g) &&
  password.match(/[0-9]/g) &&
  password.length >= 8;

export function generateCsv(commaSeparatedText, filename = 'report') {
  let textFile = null,
    makeTextFile = function (text) {
      var data = new Blob([text], { type: 'text/csv' });

      if (textFile !== null) {
        window.URL.revokeObjectURL(textFile);
      }

      textFile = window.URL.createObjectURL(data);

      return textFile;
    };

  let link = document.createElement('a');
  link.setAttribute('download', `${filename}.csv`);
  link.href = makeTextFile(commaSeparatedText);

  document.body.appendChild(link);

  window.requestAnimationFrame(function () {
    let event = new MouseEvent('click');
    link.dispatchEvent(event);
    document.body.removeChild(link);
  });
}

const hasRole = (user, role) => user.roles.includes(role);
export const isAdmin = (user) => hasRole(user, 'ROLE_ADMIN');
export const isUser = (user) => hasRole(user, 'ROLE_USER');

export function removeDuplicates(arr) {
  return Array.from(new Set(arr));
}

export function getNumberOfPages(total, resultsOnPage) {
  const numberOfPages = Math.ceil(total / resultsOnPage);
  return numberOfPages;
}