import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Login from './Login';
import Register from './Register';
import '../styles.css';
import IssueReport from './IssueReport';

const SignTabs = () => (
  <div className='mx-3'>
    <div className='mb-3' title='Contest Support System'>
      <div className='text-primary text-center'>
        <h1 className='font-weight-light'>IDAO Personal Account</h1>
      </div>
    </div>

    <Tabs className='login-register-panel' defaultActiveKey='login'>
      <Tab eventKey='login' title='Login'>
        <Login />
      </Tab>
      <Tab eventKey='register' title='Register'>
        <Register />
      </Tab>
      <Tab eventKey='issue' title='Report issue'>
        <IssueReport />
      </Tab>
    </Tabs>
  </div>
);

export default SignTabs;
