import React from 'react';
import { Alert, Form, Col } from 'react-bootstrap';

export default class IssueReport extends React.Component {
  render() {
    return (
      <div>
        <Form.Row
          onSubmit={e => e.preventDefault()}
          className='justify-content-md-center mt-2'>
          <Form.Group as={Col} md='4'>
            <Alert variant='primary'>
              Have any problem? Please, send us a report. If you don't see
              report form, please, follow{' '}
              <a
                href='https://forms.yandex.ru/u/5e1ad2b1b7ccf3060b02e3d4/'
                target='_blank'
                rel='noopener noreferrer'>
                the link
              </a>
            </Alert>

            <iframe
              title='issue-form'
              src='https://forms.yandex.ru/u/5e1ad2b1b7ccf3060b02e3d4/?iframe=1'
              frameBorder='0'
              name='ya-form-5e1ad2b1b7ccf3060b02e3d4'
              width='100%'
              height='1600'>
              Loading...
            </iframe>
          </Form.Group>
        </Form.Row>
      </div>
    );
  }
}
