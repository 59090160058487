import { watchLogin } from './auth';
import { watchRegister } from './register';
import { watchNews } from './news';
import { watchInit } from './init';
import { watchProfile } from './profile';
import { combineWatchers } from './helpers';
import { watchTeams } from './teams';
import { watchContests } from './contests';

const rootSaga = combineWatchers(
  watchInit,
  watchLogin,
  watchRegister,
  watchNews,
  watchProfile,
  watchTeams,
  watchContests,
);

export default rootSaga;
