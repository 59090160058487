import React from 'react';
import {toast} from 'react-toastify';
import {Alert, Form, Modal, Button, Col} from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {countries} from '../../properties';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import PersonalDataAgreement from './PersonalDataAgreement';
import {
    isNotEmpty,
    isLatinName,
    isEmail,
    isUsername,
    isStrongPassword,
    isDate,
} from '../../validators';
import {register} from '../../actions/register';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

const mapDispatchToProps = (dispatch) => ({
    register: (newUser) => dispatch(register.request(newUser)),
});

const mapStateToProps = (state) => ({
    isLoading: state.register.isLoading,
    isSuccess: state.register.isSuccess,
});

const RegisterContainer = ({isLoading, isSuccess, register}) => {
    if (isSuccess) {
        return <Redirect to='/auth/register/success'/>;
    }

    return (
        <div>
            <Modal show={isLoading}>
                <Modal.Header>Loading</Modal.Header>
                <Modal.Body>Please, wait</Modal.Body>
            </Modal>
            <Register onRegister={register}/>
        </div>
    );
};

class Register extends React.Component {
    state = {
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        password: '',
        passwordConfirmation: '',

        gender: 'male',
        birthday: '',
        phoneNumber: '',
        countryOfResidence: '',

        currentOccupation: 'student',
        university: '',
        studyProgram: '',
        levelOfStudy: 'Bachelor',

        company: '',

        notificationsEnabled: false,

        showPersonalDataAgreement: false,
    };

    handleClose = () => {
        this.setState({show: false});
    };

    handleShow = () => {
        this.setState({show: true});
    };

    isValidOccupation = () =>
        this.state.currentOccupation === 'student'
            ? this.isValidStudentOccupation()
            : isLatinName(this.state.company);

    isValidStudentOccupation = () => {
        return (
            isLatinName(this.state.university) && isLatinName(this.state.studyProgram)
        );
    };

    isValidForm = () =>
        isLatinName(this.state.firstName) &&
        isLatinName(this.state.lastName) &&
        isEmail(this.state.email) &&
        isStrongPassword(this.state.password) &&
        this.state.password === this.state.passwordConfirmation &&
        isNotEmpty(this.state.phoneNumber) &&
        // isDate(this.state.birthday) &&
        isUsername(this.state.username) &&
        isNotEmpty(this.state.gender) &&
        isNotEmpty(this.state.countryOfResidence) &&
        this.isValidOccupation();

    handleChange = (event) => {
        this.setState({
            [event.target.id]: event.target.value,
        });
    };

    handleCountryChange = (countries) => {
        this.setState({countryOfResidence: countries[0]});
    };

    processRegistration = (event) => {
        event.preventDefault()
        this.setState({showPersonalDataAgreement: false});
        if (!this.isValidForm()) {
            toast.error('Entered data is incorrect');
            return;
        }

        const user = {
            name: `${this.state.firstName} ${this.state.lastName}`,
            username: this.state.username,
        };

        const personalData = {
            email: this.state.email,
            gender: this.state.gender,
            birthday: this.state.birthday,
            phoneNumber: this.state.phoneNumber,
            countryOfResidence: this.state.countryOfResidence,
        };

        if (this.state.currentOccupation === 'employee') {
            personalData.company = this.state.company;
        } else {
            personalData.university = this.state.university;
            personalData.studyProgram = this.state.studyProgram;
            personalData.levelOfStudy = this.state.levelOfStudy;
        }

        const {password, notificationsEnabled} = this.state;

        this.props.onRegister({
            account: user,
            personalData: personalData,
            password: password,
            notificationsEnabled: notificationsEnabled,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({showPersonalDataAgreement: true});
    };

    render() {
        const isValid = this.isValidForm();

        const {
            phoneNumber,
            username,
            email,
            firstName,
            lastName,
            birthday,
            password,
            passwordConfirmation,
            company,
            university,
            studyProgram,
            showPersonalDataAgreement,
        } = this.state;

        return (
            <>
                <Modal show={showPersonalDataAgreement} size='lg'>
                    <Modal.Header>
                        <Modal.Title>Data processing agreement</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PersonalDataAgreement/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='outline-danger'
                            onClick={() =>
                                this.setState({showPersonalDataAgreement: false})
                            }>
                            Decline
                        </Button>
                        <Button
                            disabled={!isValid}
                            variant='outline-success'
                            onClick={this.processRegistration}>
                            Accept
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Form onSubmit={this.handleSubmit}>
                    <Form.Row className='justify-content-md-center mt-2'>
                        <Form.Group as={Col} md='8'>
                            <Alert variant='primary'>
                                First time here? Complete the registration form below to create
                                a participant account.
                            </Alert>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className='justify-content-md-center'>
                        <Form.Group as={Col} md='4' controlId='firstName'>
                            <Form.Label>First name</Form.Label>
                            <Form.Control
                                required
                                value={firstName}
                                onChange={this.handleChange}
                                type='text'
                                placeholder='First name'
                                isInvalid={firstName !== '' && !isLatinName(firstName)}
                            />
                            <Form.Control.Feedback type='invalid'>
                                Please, enter a valid name
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md='4' controlId='lastName'>
                            <Form.Label>Last name</Form.Label>
                            <Form.Control
                                required
                                value={lastName}
                                onChange={this.handleChange}
                                type='text'
                                placeholder='Last name'
                                isInvalid={lastName !== '' && !isLatinName(lastName)}
                            />
                            <Form.Control.Feedback type='invalid'>
                                Please, enter a valid name
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className='justify-content-md-center'>
                        <Form.Group as={Col} md='4' controlId='birthday'>
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control
                                placeholder='yyyy-mm-dd'
                                value={birthday}
                                onChange={this.handleChange}
                                type='date'
                                isInvalid={birthday > 0 && !isDate(birthday)}
                            />
                            <Form.Control.Feedback type='invalid'>
                                Please, enter valid birthday. Format: YYYY-MM-DD
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md='4' controlId='phoneNumber'>
                            <Form.Label>Phone number</Form.Label>
                            <PhoneInput
                                required
                                placeholder='Phone number'
                                showCountrySelect={false}
                                value={phoneNumber}
                                onChange={(value) => this.setState({phoneNumber: value})}
                                error={
                                    phoneNumber === ''
                                        ? phoneNumber.length > 4
                                            ? undefined
                                            : 'Invalid phone number'
                                        : undefined
                                }
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className='justify-content-md-center'>
                        <Form.Group as={Col} md='2' controlId='gender'>
                            <Form.Label>Gender</Form.Label>
                            <Form.Control as='select' required onChange={this.handleChange}>
                                <option value='male'>Male</option>
                                <option value='female'>Female</option>
                                <option value='not-specified'>Not specified</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} md='6' controlId='currentOccupation'>
                            <Form.Label>Current occupation</Form.Label>
                            <Form.Control as='select' required onChange={this.handleChange}>
                                <option value='student'>Student</option>
                                <option value='employee'>Employee</option>
                            </Form.Control>
                            <Form.Text>
                                Please notice that this year we decided to divide students and ML specialists into two
                                separate divisions. Only students are able to join in the main completion — the Student
                                Division. All other participants can join the Open Division to participate hors concours
                                (for their own interest).
                            </Form.Text>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className='justify-content-md-center'>
                        <Form.Group as={Col} md='8'>
                            <Form.Label>
                                {this.state.currentOccupation === 'student'
                                    ? 'University'
                                    : 'Company'}
                            </Form.Label>
                            {this.state.currentOccupation === 'student' ? (
                                <div>
                                    <Form.Group>
                                        <Form.Control
                                            required
                                            value={university}
                                            onChange={(event) =>
                                                this.setState({university: event.target.value})
                                            }
                                            type='text'
                                            placeholder='University'
                                            isInvalid={university !== '' && !isLatinName(university)}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            Please, enter your university
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Row className='justify-content-md-center'>
                                        <Form.Group as={Col} md='10'>
                                            <Form.Control
                                                required
                                                className='mt-1'
                                                value={studyProgram}
                                                onChange={(event) =>
                                                    this.setState({studyProgram: event.target.value})
                                                }
                                                type='text'
                                                placeholder='Study program'
                                                isInvalid={
                                                    studyProgram !== '' && !isLatinName(studyProgram)
                                                }
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                Please, enter your study program
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md='2' controlId='levelOfStudy'>
                                            <Form.Control
                                                as='select'
                                                required
                                                className='mt-1'
                                                onChange={this.handleChange}>
                                                <option value='Bachelor'>Bachelor</option>
                                                <option value='Master'>Master</option>
                                                <option value='PhD'>PhD</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                </div>
                            ) : (
                                <Form.Group>
                                    <Form.Control
                                        required
                                        value={company}
                                        onChange={(event) =>
                                            this.setState({company: event.target.value})
                                        }
                                        type='text'
                                        placeholder='Company'
                                        isInvalid={company !== '' && !isLatinName(company)}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        Please, enter your company
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className='justify-content-md-center'>
                        <Form.Group as={Col} md='8' controlId='countryOfResidence'>
                            <Form.Label>Country of residence</Form.Label>
                            <Typeahead
                                id='country'
                                required
                                placeholder='Country'
                                options={countries}
                                isInvalid={!isNotEmpty(this.state.countryOfResidence)}
                                onChange={this.handleCountryChange}
                            />
                            <Form.Text>
                                Please, select a country from the list
                            </Form.Text>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className='justify-content-md-center'>
                        <Form.Group as={Col} md='4' controlId='email'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                required
                                value={email}
                                onChange={this.handleChange}
                                type='email'
                                placeholder='Email'
                                isInvalid={email !== '' && !isEmail(email)}
                            />
                            <Form.Control.Feedback type='invalid'>
                                Please, enter valid email
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md='4' controlId='username'>
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                required
                                value={username}
                                onChange={this.handleChange}
                                type='text'
                                autoComplete='username'
                                placeholder='Username'
                                isInvalid={username !== '' && !isUsername(username)}
                            />
                            <Form.Control.Feedback type='invalid'>
                                Only letters and numbers allowed
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className='justify-content-md-center'>
                        <Form.Group as={Col} md='4' controlId='password'>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                required
                                value={password}
                                onChange={this.handleChange}
                                type='password'
                                placeholder='Password'
                                isInvalid={password !== '' && !isStrongPassword(password)}
                            />

                            <Form.Control.Feedback type='invalid'>
                                At least 8 characters, 1 uppercase character, 1 lowercase
                                character, 1 digit, 1 special character
                            </Form.Control.Feedback>
                            <PasswordStrengthMeter password={password}/>
                        </Form.Group>

                        <Form.Group as={Col} md='4' controlId='passwordConfirmation'>
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                                required
                                value={passwordConfirmation}
                                onChange={this.handleChange}
                                type='password'
                                placeholder='Confirm password'
                                isInvalid={
                                    passwordConfirmation !== '' &&
                                    password !== passwordConfirmation
                                }
                            />

                            <Form.Control.Feedback type='invalid'>
                                The values are different
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    {isValid ? null : (
                        <Form.Row className='justify-content-md-center'>
                            <Form.Group as={Col} md='8' controlId='email'>
                                <Alert variant='danger'>All fields above are requiered</Alert>
                            </Form.Group>
                        </Form.Row>
                    )}

                    <Form.Row className='justify-content-md-center'>
                        <Form.Group as={Col} md='8' controlId='subscribeCheckbox'>
                            <Form.Check
                                type='checkbox'
                                label='I consent to being informed of other activities carried out by HSE and IDAO partners via email.'
                            />
                            <div className='text-muted'>
                                Please, be reassured that if you do not give us your permission
                                to contact you to inform you of other activities carried out by
                                HSE it will not affect any other services HSE provide you with.
                            </div>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className='justify-content-md-center'>
                        <Form.Group as={Col} md='8'>
                            <Button
                                className='mt-3'
                                block
                                variant={isValid ? 'primary' : 'outline-primary'}
                                type='submit'>
                                Register
                            </Button>
                        </Form.Group>
                    </Form.Row>
                </Form>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterContainer);
