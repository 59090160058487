import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import TextEditor from '../TextEditor';
import { Link } from 'react-router-dom';
import { getOpenedContests, getContestById } from '../../actions/contests';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

const mapStateToProps = (state) => ({
  contests: state.contests.contests,
  openedContests: state.contests.opened,
});

const mapDispatchToProps = (dispatch) => ({
  getOpened: () => dispatch(getOpenedContests.request()),
  getContest: (id) => dispatch(getContestById.request(id)),
});

const OpenContests = ({ openedContests, contests, getOpened, getContest }) => {
  useEffect(() => {
    getOpened();
  }, []);

  return (
    <div>
      <h3>Available contests</h3>
      <div className='mt-3'>
        {openedContests.length ? (
          <div>
            {openedContests.map((id) => (
              <div>
                <ContestCard
                  key={id}
                  id={id}
                  contest={contests[id]}
                  getContest={getContest}
                />
              </div>
            ))}
          </div>
        ) : (
          <div>
            The new contest is coming. <br />
            All users will be informed when it's open.
          </div>
        )}
      </div>
    </div>
  );
};

const ContestCard = ({ id, contest, getContest }) => {
  useEffect(() => {
    if (!contest) {
      getContest(id);
    }
  }, []);

  if (!contest) {
    return <Skeleton />;
  }
  return (
    <Card className='mt-2'>
      <Card.Header className='bg-dark'>
        <Card.Title className='text-light text-center'>
          {contest.name}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Card.Text>
          Registration starts{' '}
          <span className='mx-auto' style={{ color: '#247ba0' }}>
            {' '}
            {contest.startDate}{' '}
          </span>
          ends{' '}
          <span className='mx-auto' style={{ color: '#247ba0' }}>
            {' '}
            {contest.endDate}
          </span>
        </Card.Text>
        <TextEditor
          markup='markdown'
          readOnly
          value={contest.description}
          block
        />
      </Card.Body>
      <Card.Footer className='text-center'>
        <Link to={`/contests/${id}/join`} className='btn btn-block btn-primary'>
          Open contest page
        </Link>
      </Card.Footer>
    </Card>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenContests);
