import React from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEmail } from '../../validators';
import { Unauthorized } from '../../endpoints/users';

export default class ResetPassword extends React.Component {
  state = {
    email: '',
    responsed: true,
  };

  handleRemindUsername = () => {
    Unauthorized.remindUsername(this.state.email)
      .then(() => toast.success('Username was sent to your email'))
      .finally(() => this.setState({ responsed: true }));
  };

  handleResetPassword = () => {
    this.setState({ responsed: false });
    Unauthorized.resetPassword(this.state.email)
      .then(() => toast.success('The instructions were sent to your email'))
      .finally(() => this.setState({ responsed: true }));
  };

  render() {
    const email = this.state.email;
    return (
      <div>
        <div className='mb-3' title='Contest Support System'>
          <div className='text-primary text-center'>
            <h1 className='font-weight-light'>IDAO Personal Account</h1>
          </div>
        </div>
        <hr />
        <Link to='/auth/login'>{'< Back to login'}</Link>
        <Form.Row className='justify-content-md-center'>
          <Form.Group as={Col} md='4'>
            <Form.Label>Email</Form.Label>
            <Form.Control
              isInvalid={email !== '' && !isEmail(email)}
              required
              type='email'
              value={email}
              onChange={(e) => this.setState({ email: e.target.value })}
              placeholder='Enter your email'></Form.Control>
            <Form.Control.Feedback type='invalid'>
              Please, enter correct email
            </Form.Control.Feedback>

            <br />
            <Button
              disabled={!isEmail(email) || !this.state.responsed}
              className='mt-2 mr-2'
              variant='primary'
              onClick={this.handleRemindUsername}>
              Remind username
            </Button>
            <Button
              disabled={!isEmail(email) || !this.state.responsed}
              className='mt-2'
              onClick={this.handleResetPassword}
              variant='success'>
              Reset password
            </Button>
          </Form.Group>
        </Form.Row>
      </div>
    );
  }
}
