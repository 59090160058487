import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import TeamCard from './TeamCard';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTeamsByUser, getInvites } from '../../actions/teams';
import Skeleton from 'react-loading-skeleton';
import './styles.css';
import InviteCard from './InviteCard';

const mapStateToProps = (state) => ({
  user: state.auth.user,
  userTeams: state.teams.users,
  userInvites: state.teams.invites,
});

const mapDispatchToProps = (dispatch) => ({
  getTeams: (userId) => dispatch(getTeamsByUser.request(userId)),
  getInvites: (userId) => dispatch(getInvites.request(userId)),
});

const ViewUserTeams = ({
  user,
  userTeams,
  userInvites,
  getTeams,
  getInvites,
}) => {
  const teamIds = userTeams[user.id];
  const invites = userInvites[user.id];
  useEffect(() => {
    getTeams(user.id);
    getInvites(user.id);
  }, [user, getTeams, getInvites]);

  if (!teamIds || !invites) {
    return <Skeleton />;
  }

  return (
    <div>
      <Container>
        <h3>Your teams</h3>

        <div className='mt-3'>
          <div className='list'>
            {teamIds ? (
              teamIds.map((id) => (
                <div key={id} className='list-item'>
                  <TeamCard key={id} id={id} />
                </div>
              ))
            ) : (
              <div>
                <div>You are not in any team. </div>
                <Link
                  to='/teams/new'
                  className='btn btn-outline-success btn-sm mt-2'>
                  Create new
                </Link>
              </div>
            )}
          </div>
        </div>

        <div className='mt-5'>
          <h3>Your invites</h3>
          {invites.length ? (
            <div className='list'>
              {invites.map((invite) => (
                <div className='list-item'>
                  <InviteCard key={invite} id={invite} />
                </div>
              ))}
            </div>
          ) : (
            <div>
              You have no invites.
              <p>But do not worry, you are never alone =)</p>
              <p>
                If you struggle to find a teammate, please join IDAO telegram
                chat (
                <a href='https://t.me/IDAOworld'>https://t.me/IDAOworld</a>) and
                find your future ML friends over there.
              </p>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewUserTeams);
