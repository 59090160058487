import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { getAccounts } from '../../../actions/profile';
import { connect } from 'react-redux';
import { Typeahead } from 'react-bootstrap-typeahead';

const mapStateToProps = (state) => ({
  accounts: state.profile.activated.map((a) => state.profile.accounts[a]),
});

const mapDispatchToProps = (dispatch) => ({
  getAccounts: () => dispatch(getAccounts.request()),
});

const NewToken = ({ onCancel, onCreate, accounts, getAccounts }) => {
  useEffect(() => {
    if (!accounts.length) {
      getAccounts();
    }
  }, [getAccounts, accounts.length]);

  const handleSelect = (selected) => {
    if (!selected.length || !selected[0]) return;
    setOwner(selected[0]);
  };

  const [name, setName] = useState('');
  const [owner, setOwner] = useState(null);
  return (
    <div>
      <Form>
        <Form.Group>
          <Form.Label>Token name</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter token name'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Form.Text className='text-muted'>
            Name will be displayed in column 'name'
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>Owner</Form.Label>
          <Typeahead
            id='user-search'
            placeholder='Enter user name'
            labelKey={(a) => `${a.name} (${a.username})`}
            options={accounts}
            onChange={handleSelect}
          />
          <Form.Text className='text-muted'>
            Owner will receive an email with token
          </Form.Text>
        </Form.Group>
      </Form>
      <div className='mt-2'>
        <Button variant='danger' onClick={onCancel} className='mr-2'>
          Cancel
        </Button>
        <Button
          variant='success'
          onClick={() => onCreate({ name: name, owner: owner })}>
          Create
        </Button>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewToken);
