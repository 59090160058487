import { put, call } from 'redux-saga/effects';
import { combineWatchers, createWatcher } from './helpers';
import {
  getContestById,
  GET_CONTEST_BY_ID,
  getDraftContests,
  getOpenedContests,
  getClosedContests,
  getArchivedContests,
  GET_DRAFT_CONTESTS,
  GET_OPENED_CONTESTS,
  GET_CLOSED_CONTESTS,
  GET_ARCHIVED_CONTESTS,
  openContest,
  closeContest,
  archiveContest,
  OPEN_CONTEST,
  CLOSE_CONTEST,
  ARCHIVE_CONTEST,
  deleteContest,
  DELETE_CONTEST,
  getStagesByContest,
  getStageTeams,
  GET_STAGES_BY_CONTEST,
  GET_STAGE_TEAMS,
  getStageById,
  GET_STAGE_BY_ID,
  joinContest,
  JOIN_CONTEST,
  getTeamStageKeys,
  GET_TEAM_STAGE_KEYS,
  removeTeamFromStage,
  REMOVE_TEAM_FROM_STAGE,
  getUserContests,
  GET_USER_CONTESTS,
  editContest,
  EDIT_CONTEST,
  getSettings,
  GET_SETTINGS,
  editSettings,
  EDIT_SETTINGS,
  editStage,
  EDIT_STAGE,
} from '../actions/contests';
import { Contest, Stage } from '../endpoints/contests';

function createGetList(action, status) {
  return function* () {
    try {
      const response = yield call(Contest.getByStatus, status);
      yield put(action.success(response));
    } catch (error) {
      yield put(action.failure(error));
    }
  };
}

function createChangeStatus(act, status) {
  return function* (action) {
    const id = action.payload;
    try {
      yield call(Contest.updateStatus, id, status);
      yield put(act.success(id, id));
    } catch (error) {
      yield put(act.failure(error, id));
    }
  };
}
function* callGetById(action) {
  const id = action.payload;
  try {
    const response = yield call(Contest.getById, id);
    yield put(getContestById.success(response, id));
  } catch (error) {
    yield put(getContestById.failure(error, id));
  }
}

function* callGetSettings(action) {
  const id = action.payload;
  try {
    const response = yield call(Contest.getSettings, id);
    yield put(getSettings.success(response, id));
  } catch (error) {
    console.error(error);
    yield put(getSettings.failure(error, id));
  }
}

function* callGetContestStages(action) {
  const id = action.payload;
  try {
    const response = yield call(Stage.getByContest, id);
    yield put(getStagesByContest.success(response, id));
  } catch (error) {
    yield put(getStagesByContest.failure(error, id));
  }
}

function* callGetStageById(action) {
  const id = action.payload;
  try {
    const response = yield call(Stage.getById, id);
    yield put(getStageById.success(response, id));
  } catch (error) {
    yield put(getStageById.failure(error, id));
  }
}

function* callGetStageTeams(action) {
  const id = action.payload;
  try {
    const response = yield call(Stage.getTeams, id);
    yield put(getStageTeams.success(response, id));
  } catch (error) {
    yield put(getStageTeams.failure(error, id));
  }
}

const callGetDrafts = createGetList(getDraftContests, 'DRAFT');
const callGetOpened = createGetList(getOpenedContests, 'OPENED');
const callGetClosed = createGetList(getClosedContests, 'CLOSED');
const callGetArchived = createGetList(getArchivedContests, 'ARCHIVED');

const callOpenContest = createChangeStatus(openContest, 'OPENED');
const callCloseContest = createChangeStatus(closeContest, 'CLOSED');
const callArchiveContest = createChangeStatus(archiveContest, 'ARCHIVED');

function* callGetUserContests(action) {
  const userId = action.payload;
  try {
    const response = yield call(Contest.getByUser, userId);
    yield put(getUserContests.success(response, userId));
  } catch (error) {
    yield put(getUserContests.failure(error, userId));
  }
}

function* callDeleteContest(action) {
  const id = action.payload;
  try {
    yield call(Contest.delete, id);
    yield put(deleteContest.success(id, id));
  } catch (error) {
    yield put(deleteContest.failure(error, id));
  }
}

function* callJoinContest(action) {
  const { teamId, contestId } = action.payload;
  try {
    yield call(Contest.join, contestId, teamId);
    yield put(joinContest.success(undefined, action.payload));
  } catch (error) {
    yield put(joinContest.failure(error, action.payload));
  }
}

function* callGetStageTeamKeys(action) {
  const { stageId, teamId } = action.payload;
  try {
    const response = yield call(Stage.getTeamKeys, stageId, teamId);
    yield put(getTeamStageKeys.success(response, action.payload));
  } catch (error) {
    yield put(getTeamStageKeys.failure(error, action.payload));
  }
}

function* callRemoveTeamFromStage(action) {
  const { stageId, teamId } = action.payload;
  try {
    yield call(Stage.removeTeam, stageId, teamId);
    yield put(removeTeamFromStage.success(undefined, action.payload));
  } catch (error) {
    yield put(removeTeamFromStage.failure(error, action.payload));
  }
}

function* callEditContest(action) {
  const contest = action.payload;
  try {
    yield call(Contest.edit, contest.id, contest);
    yield put(editContest.success(undefined, action.payload));
    yield put(getContestById.request(contest.id));
  } catch (error) {
    yield put(editContest.failure(error, action.payload));
  }
}

function* callEditSettings(action) {
  const settings = action.payload;

  try {
    yield call(Contest.editSettings, settings.contestId, settings);
    yield put(editSettings.success(undefined, action.payload));
    yield put(getSettings.request(settings.contestId));
  } catch (error) {
    yield put(editSettings.failure(error, action.payload));
  }
}

function* callEditStage(action) {
  const stage = action.payload;

  try {
    yield call(Stage.edit, stage.id, stage);
    yield put(editStage.success(undefined, stage));
    yield put(getStageById.request(stage.id));
  } catch (error) {
    yield put(editStage.failure(error, stage));
  }
}

const watchGetById = createWatcher(GET_CONTEST_BY_ID, callGetById, 'all');
const watchGetStages = createWatcher(
  GET_STAGES_BY_CONTEST,
  callGetContestStages,
  'all',
);
const watchGetTeams = createWatcher(GET_STAGE_TEAMS, callGetStageTeams, 'all');
const watchGetDrafts = createWatcher(GET_DRAFT_CONTESTS, callGetDrafts);
const watchGetOpened = createWatcher(GET_OPENED_CONTESTS, callGetOpened);
const watchGetClosed = createWatcher(GET_CLOSED_CONTESTS, callGetClosed);
const watchGetArchived = createWatcher(GET_ARCHIVED_CONTESTS, callGetArchived);

const watchGetStageById = createWatcher(
  GET_STAGE_BY_ID,
  callGetStageById,
  'all',
);
const watchOpenContest = createWatcher(OPEN_CONTEST, callOpenContest);
const watchCloseContest = createWatcher(CLOSE_CONTEST, callCloseContest);
const watchArchiveContest = createWatcher(ARCHIVE_CONTEST, callArchiveContest);
const watchDeleteContest = createWatcher(DELETE_CONTEST, callDeleteContest);
const watchJoinContest = createWatcher(JOIN_CONTEST, callJoinContest);
const watchGetStageTeamKeys = createWatcher(
  GET_TEAM_STAGE_KEYS,
  callGetStageTeamKeys,
  'all',
);

export const watchContests = combineWatchers(
  watchGetById,
  createWatcher(GET_SETTINGS, callGetSettings),
  watchGetDrafts,
  watchGetOpened,
  watchGetClosed,
  watchGetArchived,
  watchGetStages,
  watchGetTeams,

  watchGetStageById,

  watchOpenContest,
  watchCloseContest,
  watchArchiveContest,
  watchDeleteContest,
  watchJoinContest,
  watchGetStageTeamKeys,
  createWatcher(REMOVE_TEAM_FROM_STAGE, callRemoveTeamFromStage, 'all'),
  createWatcher(GET_USER_CONTESTS, callGetUserContests),
  createWatcher(EDIT_CONTEST, callEditContest),
  createWatcher(EDIT_SETTINGS, callEditSettings),
  createWatcher(EDIT_STAGE, callEditStage),
);
