import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { getAccount, getPersonalData } from '../../actions/profile';
import { isAdmin } from '../../tools';
import './style.css';
import { Account } from '../../endpoints/users';
import { logout } from '../../actions/auth';
import { toast } from 'react-toastify';
import { useUserTeams } from '../../loaders/teams';
import TeamCard from '../team/TeamCard';
import { Trash, Pencil } from 'react-bootstrap-icons';

const mapStateToProps = (state) => ({
  requester: state.auth.user,
  accounts: state.profile.accounts,
  allPersonalData: state.profile.personalData,
});

const mapDispatchToProps = (dispatch) => ({
  getAccount: (id) => dispatch(getAccount.request(id)),
  getPersonalData: (id) => dispatch(getPersonalData.request(id)),
  logout: () => dispatch(logout())
});

const ViewProfile = ({
  getAccount,
  getPersonalData,
  match,
  requester,
  accounts,
  allPersonalData,
  logout,
}) => {
  const accountID = parseInt(match.params.id);
  const showPersonalData = accountID === requester.id || isAdmin(requester);
  const account = accounts[accountID]
  const personalData = allPersonalData[accountID]
  const handleDelete = () => {
    if(window.confirm('Are you sure you want to delete account? Action can not be undone.')) {
      Account.delete(accountID).then(() =>{
        toast.success('Account was deleted');
        if(requester.id === accountID) {
          logout()
        }
  
      })
    }
  }

  useEffect(() => {
    getAccount(accountID);
    if (showPersonalData) {
      getPersonalData(accountID);
    }
  }, [accountID, showPersonalData, getAccount, getPersonalData]);

  const [teams, teamsLoaded] = useUserTeams(accountID)

  return (
    <div>
      <h3>Profile</h3>
      {!account ? (
        <Skeleton count='2' height={10} />
      ) : (
        <>
          <div>{account.name}</div>
          <div className='text-primary'>{account.username}</div>
        </>
      )}
      {!personalData && showPersonalData ? (
        <Skeleton count='4' height={50} />
      ) : showPersonalData && account && account.username && (
        <UserData data={personalData} onDelete={handleDelete} />
      )}

      {
        teamsLoaded && teams.length > 0 && <>
        <hr/>
        <h4>Teams</h4>
        <div className='list'>
        {teams.map(tId => <div className='list-item'><TeamCard id={tId}/></div>)}
        </div>
        </>
      }
    </div>
  );
};

const UserData = ({ data, onDelete }) => (
  <div>
    <div className='mt-3'>
      <h4>Personal information</h4>
      <table className='padding-table-columns'>
        <tbody>
          <tr>
            <td>Birthday</td>
            <td>{data.birthday}</td>
          </tr>
          <tr>
            <td>Country of residence</td>
            <td>{data.countryOfResidence}</td>
          </tr>
          <tr>
            <td>Gender</td>
            <td>{data.gender}</td>
          </tr>
          {data.university ? (
            <>
              <tr>
                <td>Education</td>
                <td>{data.university}</td>
              </tr>
              <tr>
                <td /> <td>{data.studyProgram}</td>
              </tr>
              <tr>
                <td />
                <td>{data.levelOfStudy}</td>
              </tr>
            </>
          ) : (
            <tr>
              <td>Company</td>
              <td>{data.company}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
    <div className='d-flex flex-column mt-3'>
      <h4>Contacts</h4>
      <a href={`mailto:${data.email}`}>{data.email}</a>
      <a href={`tel:${data.phoneNumber}`}>{data.phoneNumber}</a>
    </div>

    <div className='d-flex justify-content-start mt-2'>
      <Controls id={data.userId} onDelete={onDelete}/>
    </div>
  </div>
);

const Controls = ({ id, onDelete }) => (
  <>
    <Link className='btn btn-success mr-2' to={`/profile/${id}/edit`}>
      <Pencil/> Edit profile
    </Link>
    <button className='btn btn-danger' onClick={onDelete}>
      <Trash/>Delete account
    </button>
  </>
);

export default connect(mapStateToProps, mapDispatchToProps)(ViewProfile);
