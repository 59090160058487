import { call, put } from 'redux-saga/effects';
import { createWatcher } from './helpers';
import { Unauthorized } from '../endpoints/users';
import { REGISTER, register } from '../actions/register';

export const watchRegister = createWatcher(REGISTER, callRegister);

export function* callRegister(action) {
  const newUser = action.payload;
  try {
    yield call(Unauthorized.register, newUser);
    yield put(
      register.success(
        `Successfully registered ${newUser.account.username}`,
        newUser,
      ),
    );
  } catch (error) {
    yield put(register.failure(error, newUser));
  }
}
