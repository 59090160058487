import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTeamsByUser } from '../actions/teams';

export function useUserTeams(id) {
  const userTeams = useSelector((state) => state.teams.users);
  const isOk = !!userTeams[id];

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isOk) {
      dispatch(getTeamsByUser.request(id));
    }
  }, [id, isOk, dispatch]);

  return [userTeams[id], isOk];
}