import React, { useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import TextEditor from '../TextEditor';
import Skeleton from 'react-loading-skeleton';
import { getContestById, getUserContests } from '../../actions/contests';
import { getTeamsByUser } from '../../actions/teams';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const mapStateToProps = (state) => ({
  user: state.auth.user,
  contests: state.contests.contests,
  userContests: state.contests.userContests,
  userTeams: state.teams.users,
});

const mapDispatchToProps = (dispatch) => ({
  getContestById: (id) => dispatch(getContestById.request(id)),
  getUserContests: (id) => dispatch(getUserContests.request(id)),
});

const JoinedContests = ({
  user,
  contests,
  userContests,
  getUserContests,
  getContestById,
}) => {
  const contestList = userContests[user.id];

  useEffect(() => {
    getUserContests(user.id);
  }, [getUserContests, user.id]);

  useEffect(() => {
    if (contestList) {
      contestList
        .filter((c) => !contests[c])
        .map((c) => c.second)
        .forEach(getContestById);
    }
  }, [getContestById, !contestList]);

  if (!contestList) {
    return <Skeleton />;
  }

  const loadedContests = contestList
    .map((c) => ({ contest: contests[c.second], teamId: c.first }))
    .filter((c) => c.contest !== undefined)
    .filter((c) => c.contest.status !== 'ARCHIVED');

  return (
    <div>
      <h3>Joined contests</h3>
      <div className='mt-3'>
        {loadedContests.map((c) => (
          <ContestCard contest={c.contest} teamId={c.teamId} />
        ))}
      </div>
    </div>
  );
};

const ContestCard = ({ contest, teamId }) => {
  return (
    <Card>
      <Card.Header className='text-center bg-dark'>
        <Card.Title className='text-light'>{contest.name}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Card.Text>
          {contest.description !== undefined ? (
            <TextEditor
              className='mt-2 mb-2 ml-2'
              readOnly={true}
              markup='markdown'
              value={contest.description}
            />
          ) : (
            'Loading...'
          )}
        </Card.Text>
        <Card.Title></Card.Title>
      </Card.Body>
      <Card.Footer className='text-center'>
        <Link
          to={`/contests/${contest.id}/teams/${teamId}`}
          className='mr-2 btn btn-info btn-block'>
          Open participant page
        </Link>
      </Card.Footer>
    </Card>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinedContests);
