import React from 'react';
import {
  Form,
  Table,
} from 'react-bootstrap';

class TeamPromoter extends React.Component {
    state = {
      teamUploaded: false,
      logins: [],
    };
  
    handleFileSelected = event => {
      const file = event.target.files[0];
      let fileReader = new FileReader();
      fileReader.onloadend = e => {
        const content = fileReader.result;
        const pairs = content
          .trim()
          .split('\n')
          .map((line) => {
            const [contestLogin, place] = line.split(',');
            return { contestLogin: contestLogin, place: place };
          });
        this.setState({ teamUploaded: true, logins: pairs });
  
        this.props.onUploaded(pairs);
      };
      fileReader.readAsText(file);
    };
  
    render() {
      const logins = this.state.logins;
      return (
        <div>
          {this.state.teamUploaded ? (
            <div>
              Total: {logins.length}
              <Table>
                <thead>
                  <th>Place</th>
                  <th>Login</th>
                </thead>
                <tbody>
                  {logins.map(({ contestLogin, place }) => (
                    <tr>
                      <td>{place}</td>
                      <td>{contestLogin}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <div>
              <Form.Label>Select file with teams</Form.Label>
              <Form.Control
                size='lg'
                type='file'
                className='load-file-button'
                onChange={this.handleFileSelected}></Form.Control>
            </div>
          )}
        </div>
      );
    }
  }

  export default TeamPromoter