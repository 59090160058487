import React, { useEffect } from 'react';
import {
  Badge,
  Card,
  Jumbotron,
  Col,
  Row,
} from 'react-bootstrap';
import TextEditor from '../TextEditor';
import Skeleton from 'react-loading-skeleton';
import {
  getContestById,
  getStageById,
  getStagesByContest,
  getStageTeams,
} from '../../actions/contests';
import { connect } from 'react-redux';
import JoinButton from './JoinButton'

const mapStateToProps = (state) => ({
  user: state.auth.user,
  contests: state.contests.contests,
  stages: state.contests.stages,
  contestStages: state.contests.contestStages,
  stageTeams: state.contests.stageTeams,
  // contestSettings
});

const mapDispatchToProps = (dispatch) => ({
  getContestById: (id) => dispatch(getContestById.request(id)),
  getStagesByContest: (id) => dispatch(getStagesByContest.request(id)),
  getStageById: (id) => dispatch(getStageById.request(id)),
  getStageTeams: (id) => dispatch(getStageTeams.request(id)),
});

const JoinContest = ({
  match,
  contests,
  stages,
  stageTeams,
  contestStages,
  getContestById,
  getStagesByContest,
  getStageTeams,
}) => {
  const id = match.params.id;

  const contest = contests[id];

  useEffect(() => {
    if (!contest) {
      getContestById(id);
    }
  }, [id, getContestById]);

  const stageList = (contestStages[id] ? contestStages[id] : []).map(
    (stageId) => stages[stageId],
  );

  useEffect(() => {
    if (!stageList.length) {
      getStagesByContest(id);
    }
  }, [id, getStagesByContest]);

  useEffect(() => {
    stageList.forEach((s) => {
      const teams = stageTeams[s.id];
      if (!teams) {
        getStageTeams(s.id);
      }
    });
  }, [stageList.length, getStageTeams]);

  if (!contest) {
    return <Skeleton />;
  }

  const contestTeamsNumber = Math.max(
    ...stageList
      .filter((s) => s !== undefined)
      .map((s) => stageTeams[s.id])
      .filter((t) => t !== undefined)
      .map((t) => t.length),
  );

  return (
    <div>
      <h3>
        <span style={{ color: '#247ba0' }}>{contest.name}</span>
      </h3>
      <Badge
        className='mr-2'
        variant={contest.status === 'OPENED' ? 'success' : 'danger'}>
        Registration is {contest.status === 'OPENED' ? 'open' : 'closed'}
      </Badge>
      {contestTeamsNumber !== NaN && (
        <Badge variant='warning'>Teams joined: {contestTeamsNumber}</Badge>
      )}

      <TextEditor
        className='mt-2 mb-2 ml-2'
        readOnly={true}
        markup='markdown'
        value={contest.description}
      />
      <h5 className='mt-3'>Registration dates</h5>
      <div className='ml-2'>
        From <span style={{ color: '#247ba0' }}> {contest.startDate} </span>
        to <span style={{ color: '#247ba0' }}> {contest.endDate}</span>
      </div>

      <h5 className='mt-3'>Restrictions </h5>
      {/* <div className='ml-2'>
        Team size: {this.state.contestSettings.minTeamSize} to{' '}
        {this.state.contestSettings.maxTeamSize} members
      </div> */}

      <h5 className='mt-2'>Stages </h5>
      <div className='mt-3 ml-2 mr-3 list'>
        {stageList
          .filter((s) => s !== undefined)
          .map((stage) => (
            <div id={stage.id} className='list-item'>
              <Card>
                <Card.Header className='bg-dark text-center text-light'>
                  <Card.Title>{stage.name}</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>starts</Col>
                    <Col>{stage.startDate}</Col>
                  </Row>
                  <Row>
                    <Col>ends</Col>
                    <Col>{stage.endDate}</Col>
                  </Row>
                </Card.Body>
              </Card>
            </div>
          ))}
      </div>
      <hr />
      <Jumbotron>
        <div className='mt-2'>
          Only leader can join team to contest.
          <br />
          All team members must accept their invites.
          <br />
          Join is unavailable if registration was closed.
          <br />
          Every user can join contest only with one team.
          <br />
          <strong>
            If team is joined to contest, it's not allowed to edit team and
            invite or remove members.
          </strong>
        </div>
        <hr/>
        <JoinButton contestId={id} />
      </Jumbotron>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinContest);
