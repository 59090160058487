import React, { useState } from 'react';
import { Button, Table, Accordion } from 'react-bootstrap';
import { generatePassword, generateCsv } from '../../tools';
import { Field, Form } from 'react-final-form';
const KeyUploader = ({ onUploaded }) => {
  const [keys, setKeys] = useState([]);

  const handleExport = () => {
    const content = keys
      .map(({ login, password }) => `${login},${password}`)
      .join('\n');
    generateCsv(content, 'keys');
  };

  const handleChangeKeys = (keys) => {
    setKeys(keys);
    onUploaded(keys);
  };

  return (
    <span>
      {keys.length ? (
        <div>
          <Button variant='secondary mr-2' onClick={() => handleChangeKeys([])}>
            Reset
          </Button>

          <Button variant='info' onClick={handleExport}>
            Export
          </Button>
          <KeysView keys={keys} />
        </div>
      ) : (
        <Accordion>
          <Accordion.Toggle
            eventKey='file'
            className='btn btn-secondary btn-sm mr-2'>
            Upload file
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='file'>
            <FileUploader onUploaded={handleChangeKeys} />
          </Accordion.Collapse>
          or
          <Accordion.Toggle
            eventKey='gen'
            className='ml-2 btn btn-secondary btn-sm'>
            Generate keys
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='gen'>
            <KeyGenerator onGenerated={handleChangeKeys} />
          </Accordion.Collapse>
        </Accordion>
      )}
    </span>
  );
};

const FileUploader = ({ onUploaded }) => {
  const handleFileSelected = (event) => {
    const file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.onloadend = () => {
      const content = fileReader.result;
      const pairs = content
        .trim()
        .replace('\r', '')
        .split('\n')
        .map((line) => {
          let [login, password] = line.split(',');
          return { login: login, password: password };
        });
      onUploaded(pairs);
    };
    fileReader.readAsText(file);
  };

  return (
    <div>
      <label>Select file with keys</label>
      <input
        className='load-file-button'
        type='file'
        onChange={handleFileSelected}
      />
    </div>
  );
};

function countOrder(num) {
  let lead = num;
  let i = 0;
  for (; lead > 10; i++) {
    lead /= 10;
  }
  return i + 1;
}

function addLeadingZeros(numLength, num) {
  return num.toLocaleString('en', { minimumIntegerDigits: numLength }).replaceAll(",","");
}

function generateKeys(
  amount = 100,
  prefix = '',
  start = 0,
  shouldAddZeros = false,
) {
  const numOfLeadingZeros = countOrder(amount + start);
  const leadWithZeros = addLeadingZeros.bind(null, numOfLeadingZeros);
  return [...Array(amount).keys()]
    .map((i) => i + start)
    .map((n) => prefix + (shouldAddZeros ? leadWithZeros(n) : n))
    .map((login) => ({ login: login, password: generatePassword(16) }));
}

const KeyGenerator = ({ onGenerated }) => {
  const handleGenerate = ({ amount, prefix, start, haveZeros }) => {
    const keys = generateKeys(
      parseInt(amount),
      prefix,
      parseInt(start),
      haveZeros,
    );
    onGenerated(keys);
  };

  return (
    <div>
      <Form
        onSubmit={handleGenerate}
        initialValues={{ prefix: '', start: 1, amount: 30, haveZeros: false }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <label>Login prefix</label>
              <Field
                name='prefix'
                className='form-control'
                component='input'
                type='text'
                placeholder="'IDAO_' => 'IDAO_1', 'IDAO_2'"
              />
            </div>
            <div>
              <label>Start number</label>
              <Field
                name='start'
                className='form-control'
                component='input'
                type='number'
                placeholder="'10' => 'IDAO_10', 'IDAO_11' "
              />
            </div>
            <div>
              <label>How many keys generate</label>
              <Field
                className='form-control'
                name='amount'
                component='input'
                type='number'
              />
            </div>

            <div>
              <label>Add leading zeros</label>
              <Field
                name='haveZeros'
                className='form-control'
                component='input'
                type='checkbox'
              />
              <small>'IDAO_098' 'IDAO_099', 'IDAO_100'</small>
            </div>

            <div className='buttons'>
              <button
                className='btn btn-success mr-2'
                type='submit'
                disabled={submitting}>
                Generate
              </button>
              <button
                className='btn btn-secondary'
                type='button'
                onClick={form.reset}
                disabled={submitting}>
                Reset
              </button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

const KeysView = ({ keys }) => (
  <div>
    <Table>
      <thead>
        <th>#</th>
        <th>Login</th>
        <th>Password</th>
      </thead>
      <tbody>
        {keys.slice(0, 5).map(({ login, password }, index) => (
          <tr>
            <td>{index + 1}</td>
            <td>{login}</td>
            <td>{password}</td>
          </tr>
        ))}
        <tr>
          <td>...</td>
          <td>...</td>
          <td>...</td>
        </tr>
        {keys
          .slice(keys.length - 5, keys.length)
          .map(({ login, password }, index) => (
            <tr>
              <td>{index + keys.length - 4}</td>
              <td>{login}</td>
              <td>{password}</td>
            </tr>
          ))}
      </tbody>
    </Table>
    Total: {keys.length}
  </div>
);

export default KeyUploader;
