import React from 'react';
import { Download } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';

const ExportUsersButton = ({requestFullInfo}) => {
  const handleExport = () => requestFullInfo();
  return (
    <Button
      title='Generate report'
      className='mt-2 mb-2'
      variant='info'
      type='submit'
      onClick={handleExport}>
      <Download /> Export
    </Button>
  );
};

export default ExportUsersButton;
