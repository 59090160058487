import React from 'react';
import { Form, Field } from 'react-final-form';
import Skeleton from 'react-loading-skeleton';

import TextEditor from '../TextEditor';
import EditStage from './EditStage';
import { useDispatch } from 'react-redux';
import {
  useContest,
  useSettings,
  useContestStages,
} from '../../loaders/contests';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { editContest, editSettings } from '../../actions/contests';

const Input = styled.input`
  margin-left: 5pt;
`;

const EditContest = () => {
  const { id } = useParams();
  const [contest, isLoaded] = useContest(id);
  const [settings, settingsLoaded] = useSettings(id);
  const [stages, stagesLoaded] = useContestStages(id);
  const dispatch = useDispatch();

  const saveContest = (c) => dispatch(editContest.request(c));
  const saveSettings = (s) => dispatch(editSettings.request(s));

  if (!isLoaded) {
    return <Skeleton />;
  }

  return (
    <>
      <h3>Edit contest</h3>
      <Form
        onSubmit={saveContest}
        initialValues={{ ...contest }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <h4>Name</h4>
              <Field
                name='name'
                type='text'
                placeholder='Enter contest name...'>
                {({ input }) => (
                  <Input value={input.value} onChange={input.onChange} />
                )}
              </Field>
            </div>
            <div>
              <h4> Description</h4>
              <Field name='description'>
                {({ input }) => (
                  <TextEditor
                    value={input.value}
                    onChange={input.onChange}
                    placeholder='Enter contest description...'
                    markup='markdown'
                  />
                )}
              </Field>
            </div>
            <div>
              <h4>Registration dates</h4>
              <div>Start date</div>
              <Field name='startDate' component='input' type='date' />
              <div>End date</div>
              <Field name='endDate' component='input' type='date' />
            </div>
            <div>
              <button type='submit' className='btn btn-success mt-2'>
                Save changes
              </button>
            </div>
          </form>
        )}
      />

      <hr />
      <h3>Edit settings</h3>
      {settingsLoaded && (
        <Form
          onSubmit={saveSettings}
          initialValues={{ ...settings }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <h4>Team size</h4>
              <div>
                <label className='mr-2'>Min</label>
                <Field
                  name='minTeamSize'
                  component='input'
                  type='number'
                  placeholder='Minimum number of members in team'
                />
              </div>
              <div>
                <label className='mr-2'>Max</label>
                <Field
                  name='maxTeamSize'
                  component='input'
                  type='number'
                  placeholder='Maximum number of members in team'
                />
              </div>
              <div>
                <button type='submit' className='btn btn-success mt-2'>
                  Save changes
                </button>
              </div>
            </form>
          )}
        />
      )}
      <hr />
      <h3>Edit stages</h3>
      {stagesLoaded && (
        <div className='mt-3 mb-3 list'>
          {stages.map((stageId, index) => (
            <div key={stageId} className='list-item'>
              <EditStage id={stageId} nextStageId={index === stages.length - 1 ? 0 : stages[index + 1]} isLast={index === stages.length - 1} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default EditContest;
