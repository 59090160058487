import React, { useEffect, useState } from 'react';

import { Accordion, Badge, Button, Card, ListGroup } from 'react-bootstrap';
import axios from '../../config/api';

import { Link } from 'react-router-dom';
import { Check, Pencil, Trash, CloudDownload } from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import { generateCsv } from '../../tools';
import {
  getContestById,
  openContest,
  closeContest,
  archiveContest,
  deleteContest,
  getStagesByContest,
  getStageTeams,
} from '../../actions/contests';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import TextEditor from '../TextEditor';

const mapStateToProps = (state) => ({
  contests: state.contests.contests,
  contestStages: state.contests.contestStages,
  stages: state.contests.stages,
  stageTeams: state.contests.stageTeams,
});

const mapDispatchToProps = (dispatch) => ({
  getContest: (id) => dispatch(getContestById.request(id)),
  openContest: (id) => dispatch(openContest.request(id)),
  closeContest: (id) => dispatch(closeContest.request(id)),
  archiveContest: (id) => dispatch(archiveContest.request(id)),
  deleteContest: (id) => dispatch(deleteContest.request(id)),
  getStagesByContest: (id) => dispatch(getStagesByContest.request(id)),
  getStageTeams: (id) => dispatch(getStageTeams.request(id)),
});

const AdminContestCard = ({
  id,
  contests,
  stages,
  teams,
  contestStages,
  stageTeams,
  getContest,
  openContest,
  closeContest,
  archiveContest,
  deleteContest,
  getStagesByContest,
  getStageTeams,
}) => {
  useEffect(() => {
    getStagesByContest(id);
  }, [getStagesByContest]);
  const contest = contests[id];
  let stageList = contestStages[id];
  if (!stageList) {
    stageList = [];
  }

  stageList = stageList.map((s) => stages[s]).filter((s) => !!s);
  const settings = undefined;

  const handleDownload = () => downloadCsv(id);
  const handleDelete = () => {
    if (contest && contest.status !== 'ARCHIVED') {
      toast.warn('Contest must be archived before permanent delete');
      return;
    }
    if (
      window.confirm(
        `Do you want to permanently delete contest? This can't be undone`,
      )
    ) {
      deleteContest(id);
    }
  };
  const handleOpen = () => openContest(id);
  const handleClose = () => closeContest(id);
  const handleArchive = () => archiveContest(id);

  useEffect(() => {
    if (id) {
      getContest(id);
    }
  }, [id, getContest]);

  if (!contest) {
    return <Skeleton />;
  }

  return (
    <Card className='mt-2'>
      <Card.Header className='text-center bg-dark'>
        <h4 className='text-light'>{contest.name}</h4>
      </Card.Header>
      <Card.Body>
        <div className='float-right'>{getBadge(contest.status)}</div>
        <Card.Text className='text-muted' style={{ fontSize: '12px' }}>
          <h5>Registration</h5>
          <div className='ml-2'>
            {contest.startDate} – {contest.endDate}
          </div>

          {settings && (
            <>
              <h5 className='mt-3'>Settings</h5>
              <div className='ml-2'>
                <div>
                  {'Team size: '}
                  {settings.minTeamSize}-{settings.maxTeamSize} members
                </div>
              </div>
            </>
          )}

          <hr />
          <Card.Title>Description</Card.Title>
          <Accordion>
            <Accordion.Toggle
              eventKey={`contest-description-${id}-close`}
              className='btn btn-secondary btn-sm'>
              See/hide
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={`contest-description-${id}-close`}>
              <TextEditor value ={contest.description} readOnly/>
            </Accordion.Collapse>
          </Accordion>
          <hr />

          <Card.Title>Stages</Card.Title>
          <ListGroup>
            {stageList.map((s) => (
              <ListGroup.Item>
                <span className='font-weight-bolder'>{s.name}</span>
                <div>
                  {s.startDate} – {s.endDate}
                </div>
                <div>
                  <TeamCounter
                    contestId={id}
                    getTeams={getStageTeams}
                    stageTeams={stageTeams}
                    stageId={s.id}
                  />
                </div>
                {s.platformLink && (
                  <a
                    href={s.platformLink}
                    target='_blank'
                    rel='noopener noreferrer'>
                    {s.platformLink}
                  </a>
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Text>
      </Card.Body>

      <Card.Footer>
        <Button
          title='Delete contest'
          size='sm'
          onClick={handleDelete}
          className='mr-2'
          variant='danger'>
          <Trash /> Delete
        </Button>
        <Link
          className='btn btn-success btn-sm mr-2'
          to={`/contests/edit/${id}`}>
          <Pencil />
          Edit
        </Link>
        <Button
          size='sm'
          title='Download report'
          variant='info'
          onClick={handleDownload}>
          <CloudDownload /> Export teams
        </Button>
        <div className='mt-2'>
          <Button
            size='sm'
            title='Open registration'
            className='mr-2'
            disabled={contest.status === 'OPENED'}
            variant='primary'
            onClick={handleOpen}>
            <Check /> Open
          </Button>
          <Button
            size='sm'
            title='Close registration'
            className='mr-2'
            disabled={contest.status === 'CLOSED'}
            variant='danger'
            onClick={handleClose}>
            <Check /> Close
          </Button>

          <Button
            size='sm'
            title='Publish contest'
            className='mr-2'
            disabled={contest.status === 'ARCHIVED'}
            variant='secondary'
            onClick={handleArchive}>
            <Check /> Archive
          </Button>
        </div>
      </Card.Footer>
    </Card>
  );
};

const TeamCounter = ({ contestId, stageId, stageTeams, getTeams }) => {
  const teams = stageTeams[stageId];
  useEffect(() => {
    getTeams(stageId);
  }, [getTeams, stageId]);
  if (!teams) {
    return null;
  }

  return (
    <Link to={`/contests/${contestId}/stages/${stageId}/teams`}>
      <Badge variant='primary'>Teams: {teams.length}</Badge>
    </Link>
  );
};

const statusToBadge = {
  DRAFT: { style: 'dark', label: 'DRAFT' },
  OPENED: { style: 'primary', label: 'OPEN' },
  CLOSED: { style: 'danger', label: 'CLOSED' },
  ARCHIVED: { style: 'secondary', label: 'ARCHIVE' },
};

const getBadge = (status) => {
  const s = statusToBadge[status];
  return (
    <Badge variant={s.style}>
      <div className='small'>{s.label}</div>
    </Badge>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminContestCard);

function downloadCsv(id) {
  axios.get(`/reports/contests/${id}`).then((response) => {
    const header =
      'ID,USERNAME,NAME,TEAM,LOGIN,ROLE,EMAIL,BIRTHDAY,PHONE NUMBER,GENDER,COUNTRY OF RESIDENCE,UNIVERSITY,STUDY PROGRAM,LEVEL OF STUDY,COMPANY,REGISTRATION DATE';
    const data = response
      .map((obj) => {
        let u = obj.account;
        let pd = obj.personalData;
        let t = obj.team;
        let m = obj.member;
        let c = obj.credentials;
        return [
          u.id,
          u.username,
          u.name,
          t.name,
          c.login,
          m.role,
          pd.email,
          pd.birthday,
          pd.phoneNumber,
          pd.gender,
          pd.countryOfResidence,
          pd.university,
          pd.studyProgram,
          pd.levelOfStudy,
          pd.company,
          pd.registrationDate,
        ].map(el => `"${el}"`).join();
      })
      .join('\n');
    generateCsv(`${header}\n${data}`);
  });
}
