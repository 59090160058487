import React, { useState } from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import { Team } from '../../endpoints/teams';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

const CreateTeam = () => {
  const [name, setName] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true);
    Team.create({ name: name }).then((r) => {
      toast.success(`Team '${name}' created`);
      setRedirect(`/teams/${r.id}/edit`);
    }).finally(() => setLoading(false));
  };
// isValid={isLatinName(name)}
  return (
    <Container>
      {redirect && <Redirect to={redirect} />}
      <h3>Create new team</h3>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Team name</Form.Label>
          <Form.Control
            required
            type='text'   
            placeholder='Enter unique team name'
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>

        <Button variant='primary' type='submit' disabled={isLoading}>
          Next
        </Button>
      </Form>
    </Container>
  );
};

export default CreateTeam;
