import React, { useEffect } from 'react';
import {
  Badge,
  Card,
  Container,
  CardDeck,
  Col,
  Row,
} from 'react-bootstrap';
import TextEditor from '../TextEditor';
import TeamCard from './TeamCard';
import {
  getStagesByContest,
  getContestById,
  getStageById,
  getTeamStageKeys,
  getStageTeams,
} from '../../actions/contests';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  stages: state.contests.stages,
  contests: state.contests.contests,
  contestStages: state.contests.contestStages,
  stageTeams: state.contests.stageTeams,
  keys: state.contests.keys,
});

const mapDispatchToProps = (dispatch) => ({
  getContest: (id) => dispatch(getContestById.request(id)),
  getContestStages: (id) => dispatch(getStagesByContest.request(id)),
  getStage: (id) => dispatch(getStageById.request(id)),
  getStageTeams: (id) => dispatch(getStageTeams.request(id)),
  getKeys: (stageId, teamId) =>
    dispatch(getTeamStageKeys.request({ teamId: teamId, stageId: stageId })),
});

const ViewParticipantCard = ({
  contests,
  keys,
  stages,
  contestStages,
  stageTeams,
  match,
  getContest,
  getContestStages,
  getStageTeams,
  getKeys,
  getStage,
}) => {
  const contestId = parseInt(match.params.contestId);
  const teamId = parseInt(match.params.teamId);
  const contest = contests[contestId];
  const stageList = contestStages[contestId];

  useEffect(() => {
    if (!contest) {
      getContest(contestId);
    }
  }, [contestId, getContest]);

  useEffect(() => {
    if (!stageList) {
      getContestStages(contestId);
    }
  }, [contestId, getContestStages]);

  useEffect(() => {
    if (stageList) {
      stageList.forEach((sId) => {
        const stage = stages[sId];
        if (!stage) {
          getStage(sId);
        }
        const teamList = stageTeams[sId];
        if (!teamList) {
          getStageTeams(sId);
        }
      });
    }
  }, [stageList ? stageList : 0, getStage, getStageTeams]);

  useEffect(() => {
    if (stageList) {
      stageList.forEach((sId) => {
        const stageKeys = keys[sId];
        if (!stageKeys) {
          const teamList = stageTeams[sId];
          if (teamList) {
            const contains = teamList.includes(teamId);
            if (contains) {
              getKeys(sId, teamId);
            }
          }
        }
      });
    }
  }, [stageList, keys, stageTeams]);

  if (!contest || !stageList) {
    return <Skeleton />;
  }

  return (
    <Container>
      <h3>Participant card</h3>
      <div className='mt-3'>
        <h4>Contest {contest.name}</h4>
        <TextEditor
          className='mt-2'
          readOnly={true}
          markup='markdown'
          value={contest.description}
        />
      </div>

      <hr />

      <div className='mt-3'>
        <CardDeck>
          <div>
            <h4 className='ml-3'>Team</h4>
            <TeamCard id={teamId} />
          </div>

          {stageList &&
            stageList.map((s) => (
              <StageView
                key={s}
                teamId={teamId}
                stage={stages[s]}
                stageTeams={stageTeams}
                keys={keys}
              />
            ))}
        </CardDeck>
      </div>
    </Container>
  );
};

const StageView = ({ stage, keys, teamId, stageTeams }) => {
  if (!stage) {
    return <Skeleton />;
  }

  const showCredentials = stage.platformLink;
  let teamKeys = undefined;
  if (showCredentials) {
    if (keys[stage.id]) {
      teamKeys = keys[stage.id][teamId];
    }
  }

  const joinedTeams = stageTeams[stage.id] ? stageTeams[stage.id].length : 0;

  return (
    <Card>
      <Card.Header className='bg-dark text-center'>
        <Card.Title className='text-light'>{stage.name}</Card.Title>
        {joinedTeams && !isNaN(joinedTeams) && (
          <Card.Text>
            <Badge variant='success'>Teams: {joinedTeams}</Badge>
          </Card.Text>
        )}
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <strong>Starts</strong>{' '}
          </Col>
          <Col>{stage.startDate.toString()}</Col>
        </Row>
        <Row>
          <Col>
            <strong>Ends</strong>
          </Col>
          <Col>{stage.endDate.toString()}</Col>
        </Row>
        {showCredentials && teamKeys && (
          <span>
            <hr />
            <Row>
              <Col>
                <strong>Login</strong>
              </Col>
              <Col>{teamKeys.login}</Col>
            </Row>
            <Row>
              <Col>
                <strong>Password</strong>
              </Col>
              <Col>{teamKeys.password}</Col>
            </Row>
          </span>
        )}
      </Card.Body>
      <Card.Footer className='text-center'>
        {showCredentials && (
          <a
            className='btn btn-warning btn-sm'
            target='_blank'
            rel='noopener noreferrer'
            href={stage.platformLink}>
            Open task
          </a>
        )}
      </Card.Footer>
    </Card>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewParticipantCard);
