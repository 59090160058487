import axios from 'axios';
import rateLimit from 'axios-rate-limit';
import React from 'react';
import { toast } from 'react-toastify';

const requestHandler = (request) => {
  const token = localStorage.getItem('token');

  if (token) {
    request.headers['Authorization'] = token;


    // const currentTime = Date.now();
    // const expirationTime = Number(localStorage.getItem('tokenWillExpire'));

    // if (currentTime > expirationTime) {
    //   logout();
    //   return;
    // }

    // if (
    //   expirationTime - currentTime <
    //   0.3 * process.env.REACT_APP_TOKEN_EXPIRATION
    // ) {
    //   toast.warn(
    //     'Session will expire soon. Please, relogin in the nearest time',
    //     {
    //       toastId: 'EXPIRATION-TOKEN-TOAST-ID',
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //     },
    //   );
    // }
  }

  return request;
};

const errorHandler = (error) => {
  const message = error.response ? (
    <div>
      Error: {error.response.data.message} <br /> Details:{' '}
      {error.response.data.details}
    </div>
  ) : error.message ? (
    error.message
  ) : (
    'Network error'
  );
  toast.error(message);
  return Promise.reject({ ...error, message: message });
};

const successHandler = (response) => {
  return response.data;
};

// Init Axios
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKURL,
});

// Add interceptors
axiosInstance.interceptors.request.use((request) => requestHandler(request));

axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

export default rateLimit(axiosInstance, {
  maxRequests: 16,
  perMilliseconds: 1000,
  maxRPS: 16,
});
