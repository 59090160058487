import {
  CREATE_CONTEST,
  EDIT_CONTEST,
  EDIT_SETTINGS,
  GET_CONTEST_BY_ID,
  GET_DRAFT_CONTESTS,
  GET_OPENED_CONTESTS,
  GET_CLOSED_CONTESTS,
  GET_ARCHIVED_CONTESTS,
  GET_USER_CONTESTS,
  OPEN_CONTEST,
  CLOSE_CONTEST,
  ARCHIVE_CONTEST,
  DELETE_CONTEST,
  GET_STAGES_BY_CONTEST,
  GET_STAGE_BY_ID,
  GET_STAGE_TEAMS,
  JOIN_CONTEST,
  GET_TEAM_STAGE_KEYS,
  REMOVE_TEAM_FROM_STAGE,
  GET_SETTINGS,
  EDIT_STAGE,
} from '../actions/contests';
import { REQUEST, SUCCESS } from '../actions/helpers';
import { toast } from 'react-toastify';

const defaultState = {
  contests: {}, //contestId: contest
  drafts: [], //[contestIds]
  opened: [], //[contestIds]
  closed: [],
  archived: [], //[contestIds]

  // teams: {}, //{teamId, contests}
  stages: {}, //stageId: stage
  contestStages: {}, //contestId: [stageIds]
  stageTeams: {}, //stageId: [teamIds]
  participants: {}, //stageId: [teams]
  userContests: {}, //userId: [{contestId, teamId}]
  teamContests: {}, //teamId: [contestIds]
  settings: {}, //contestId: settings
  keys: {}, //stageId: teamId: keys
};

const contestReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_CONTEST_BY_ID[SUCCESS]: {
      const contest = action.payload.response;
      const contests = { ...state.contests, [contest.id]: contest };
      return { ...state, contests: contests };
    }
    case GET_SETTINGS[SUCCESS]: {
      const response = action.payload.response;
      const settings = { ...state.settings, [response.contestId]: response };
      return { ...state, settings: settings };
    }
    case GET_DRAFT_CONTESTS[SUCCESS]: {
      return { ...state, drafts: action.payload.response.map((c) => c.id) };
    }
    case GET_OPENED_CONTESTS[SUCCESS]: {
      return { ...state, opened: action.payload.response.map((c) => c.id) };
    }
    case GET_CLOSED_CONTESTS[SUCCESS]: {
      return { ...state, closed: action.payload.response.map((c) => c.id) };
    }
    case GET_ARCHIVED_CONTESTS[SUCCESS]: {
      return { ...state, archived: action.payload.response.map((c) => c.id) };
    }
    case OPEN_CONTEST[SUCCESS]: {
      const id = action.payload.requested;
      const drafts = state.drafts.filter((c) => c !== id);
      const opened = [...state.opened, id];
      const closed = state.closed.filter((c) => c !== id);
      return { ...state, opened: opened, drafts: drafts, closed: closed };
    }
    case CLOSE_CONTEST[SUCCESS]: {
      const id = action.payload.requested;
      const opened = state.opened.filter((c) => c !== id);
      const closed = [...state.closed, id];
      const archived = state.archived.filter((c) => c !== id);
      return { ...state, opened: opened, closed: closed, archived: archived };
    }
    case ARCHIVE_CONTEST[SUCCESS]: {
      const id = action.payload.requested;
      const closed = state.closed.filter((c) => c !== id);
      const archived = [...state.archived, id];
      return { ...state, archived: archived, closed: closed };
    }
    case GET_USER_CONTESTS[SUCCESS]: {
      const userId = action.payload.requested;
      const contests = action.payload.response;
      const userContests = { ...state.userContests, [userId]: contests };
      return { ...state, userContests: userContests };
    }
    case GET_STAGES_BY_CONTEST[SUCCESS]: {
      const id = action.payload.requested;
      const res = action.payload.response;
      const contestStages = {
        ...state.contestStages,
        [id]: res.map((s) => s.id).sort((a, b) => a - b),
      };
      const stages = { ...state.stages };
      res.forEach((s) => (stages[s.id] = s));
      return { ...state, contestStages: contestStages, stages: stages };
    }
    case GET_STAGE_BY_ID[SUCCESS]: {
      const id = action.payload.requested;
      const stage = action.payload.response;
      const stages = { ...state.stages, [id]: stage };
      return { ...state, stages };
    }
    case GET_STAGE_TEAMS[SUCCESS]: {
      const id = action.payload.requested;
      const teams = action.payload.response.sort((a, b) => b - a);
      const stageTeams = { ...state.stageTeams, [id]: teams };
      return { ...state, stageTeams: stageTeams };
    }
    case CREATE_CONTEST[SUCCESS]: {
      toast.success('Contest created');
      return state;
    }
    case EDIT_STAGE[SUCCESS]:
    case EDIT_SETTINGS[SUCCESS]:
    case EDIT_CONTEST[SUCCESS]: {
      toast.success('Changes saved');
      return state;
    }
    case DELETE_CONTEST[SUCCESS]: {
      const id = action.payload.requested;
      toast.success('Contest deleted');
      const contests = { ...state.contests };
      delete contests[id];
      return {
        ...state,
        contests: contests,
        archived: state.archived.filter((c) => c !== id),
      };
    }
    case JOIN_CONTEST[SUCCESS]: {
      const { contestId, teamId } = action.payload.requested;
      const teamContests = { ...state.teamContests };
      const contests = teamContests[teamId] ? teamContests[teamId] : [];
      contests.push(contestId);
      teamContests[teamId] = contests;
      toast.success('You joined contest');
      return { ...state, teamContests };
    }
    case GET_TEAM_STAGE_KEYS[SUCCESS]: {
      const { teamId, stageId } = action.payload.requested;
      const keys = { ...state.keys };
      if (!keys[stageId]) {
        keys[stageId] = {};
      }
      keys[stageId][teamId] = action.payload.response;
      return { ...state, keys: keys };
    }
    case REMOVE_TEAM_FROM_STAGE[SUCCESS]: {
      const { teamId, stageId } = action.payload.requested;
      toast.success('Team removed');
      const stageTeams = {
        ...state.stageTeams,
        [stageId]: state.stageTeams[stageId].filter((t) => t !== teamId),
      };
      return { ...state, stageTeams: stageTeams };
    }
    default:
      return state;
  }
};

export default contestReducer;
