import { LOGOUT, LOGIN } from '../actions/auth';
import { REQUEST, SUCCESS, FAILURE } from '../actions/helpers';

import jwt_decode from 'jwt-decode';

const defaultUser = { id: 0, username: '', roles: [] };

const loadUser = () => {
  const token = localStorage.getItem('token');
  if (token) {
    const { roles, sub, id, exp } = jwt_decode(token);
    if (Date.now() > exp * 1000) {
      localStorage.clear();
      return defaultUser;
    } else {
      return {
        id: id,
        username: sub,
        roles: roles,
      };
    }
  } else {
    return defaultUser;
  }
};
const user = loadUser();

const defaultState = {
  isAuth: !!user.id,
  user: user,
  isLoading: false,
  error: '',
};

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN[REQUEST]:
      return { ...state, isLoading: true };
    case LOGIN[FAILURE]:
      return { ...state, isLoading: false, error: action.payload.error };
    case LOGIN[SUCCESS]: {
      const token = action.payload.response;
      localStorage.setItem('token', token);
      const decoded = jwt_decode(token);
      const user = {
        username: decoded.sub,
        roles: decoded.roles,
        id: decoded.id,
      };
      return {
        ...state,
        isLoading: false,
        isAuth: true,
        user: user,
      };
    }
    case LOGOUT: {
      localStorage.removeItem('token');
      return {
        ...state,
        isAuth: false,
        user: defaultUser,
      };
    }

    default:
      return state;
  }
};

export default authReducer;
