import { createRequestTypes, createRequestActions } from './helpers';

const createTypes = (name) => createRequestTypes('TEAMS', name);

export const CREATE_TEAM = createTypes('CREATE_TEAM');
export const EDIT_TEAM = createTypes('EDIT_TEAM');
export const DELETE_TEAM = createTypes('DELETE_TEAM');
export const GET_TEAM_BY_ID = createTypes('GET_TEAM_BY_ID');
export const GET_ALL_TEAMS = createTypes('GET_ALL_TEAMS');
export const GET_TEAMS_BY_USER = createTypes('GET_TEAMS_BY_USER');

export const GET_TEAM_MEMBERS = createTypes('GET_TEAM_MEMBERS');
export const REMOVE_MEMBER = createTypes('REMOVE_MEMBER');

export const INVITE_USER = createTypes('INVITE_USER');
export const ACCEPT_INVITE = createTypes('ACCEPT_INVITE');
export const DECLINE_INVITE = createTypes('DECLINE_INVITE');
export const GET_INVITES = createTypes('GET_INVITES');

export const createTeam = createRequestActions(CREATE_TEAM);
export const editTeam = createRequestActions(EDIT_TEAM);
export const deleteTeam = createRequestActions(DELETE_TEAM);
export const getTeamById = createRequestActions(GET_TEAM_BY_ID);
export const getAllTeams = createRequestActions(GET_ALL_TEAMS);
export const getTeamsByUser = createRequestActions(GET_TEAMS_BY_USER);

export const getTeamMembers = createRequestActions(GET_TEAM_MEMBERS);
export const removeMember = createRequestActions(REMOVE_MEMBER);

export const inviteUser = createRequestActions(INVITE_USER);
export const acceptInvite = createRequestActions(ACCEPT_INVITE);
export const declineInvite = createRequestActions(DECLINE_INVITE);
export const getInvites = createRequestActions(GET_INVITES);
