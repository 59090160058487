import React from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import TextEditor from '../TextEditor';
import { connect } from 'react-redux';
import { createArticle } from '../../actions/news';

const mapDispatchToProps = (dispatch) => ({
  createArticle: (article) => dispatch(createArticle.request(article)),
});

class CreateArticle extends React.Component {
  state = {
    header: '',
    body: '',
  };

  saveDescription = (body) => {
    if (body.length >= 3000) alert('no way! too long!');
    else this.setState({ body: body });
  };

  handleSave = () => {
    const article = {
      header: this.state.header,
      body: this.state.body,
    };

    this.props.createArticle(article);
  };

  render() {
    const { header, body } = this.state;
    const disabled = !header || !body;

    return (
      <Container>
        <h3>Publish news</h3>
        <h4>Header</h4>
        <Form>
          <Form.Control
            placeholder='Enter header...'
            value={header}
            onChange={(event) => this.setState({ header: event.target.value })}
          />
        </Form>

        <h4 className='mt-2'>Body</h4>
        <TextEditor
          placeholder='Enter article body...'
          markup='markdown'
          onChange={this.saveDescription}
        />

        <Button
          disabled={disabled}
          variant='success'
          className='mt-2'
          onClick={this.handleSave}>
          Publish
        </Button>
      </Container>
    );
  }
}

export default connect(null, mapDispatchToProps)(CreateArticle);
