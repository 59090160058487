import api from '../config/api';

export const Team = {
  getAll: () => api.get('/teams/'),
  getById: (id) => api.get(`/teams/${id}`),
  create: (team) => api.post(`/teams/`, team),
  edit: (id, team) => api.put(`/teams/${id}`, team),
  delete: (id) => api.delete(`/teams/${id}`),

  getByUserId: (userId) => api.get(`/teams/members/${userId}`),
  // getAllByContest
};

export const Members = {
  getAllByTeamId: (id) => api.get(`/teams/${id}/members/`),
  remove: (teamId, userId) => api.delete(`/teams/${teamId}/members/${userId}`),
};

export const Invites = {
  invite: (teamId, userId) => api.post(`/teams/${teamId}/invites/${userId}`),
  accept: (teamId, userId) => api.put(`/teams/${teamId}/invites/${userId}`),
  decline: (teamId, userId) => api.delete(`/teams/${teamId}/invites/${userId}`),
  getByUserId: (id) => api.get(`/teams/invites/${id}`),
};
