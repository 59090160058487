import {
  createRequestTypes,
  action,
  REQUEST,
  SUCCESS,
  FAILURE,
} from './helpers';

const createTypes = (name) => createRequestTypes('INIT_APP', name);

export const INIT_APP = createTypes('INIT_APP');
export const initApp = {
  request: (user) => action(INIT_APP[REQUEST], user),
  success: () => action(INIT_APP[SUCCESS]),
  failure: (error) => action(INIT_APP[FAILURE], error),
};
