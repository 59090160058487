import React from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  CardDeck,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import TextEditor from '../TextEditor';

import axios from '../../config/api';
import { toast } from 'react-toastify';

class CreateContest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      startDate: undefined,
      endDate: undefined,
      stages: [],
      description: '',
      minTeamSize: 1,
      maxTeamSize: 3,
    };
  }

  handleSaveDescription(description) {
    if (description.length >= 10000) alert('no way! too long!');
    else this.setState({ description: description });
  }

  handleStageChanged(stageId, event) {
    const stages = [...this.state.stages];
    const index = stages.findIndex(s => s.id === stageId);
    const stage = stages[index];

    stage[event.target.id] = event.target.value;

    stages[index] = stage;

    this.setState({
      stages: stages,
    });
  }

  handleStageAdded() {
    const id = `f${(~~(Math.random() * 1e8)).toString(16)}`;
    let stage = {
      id: id,
      startDate: undefined,
      endDate: undefined,
    };

    this.setState({
      stages: [...this.state.stages, stage],
    });
  }

  handleStageRemoved(id) {
    let stages = this.state.stages;
    const index = stages.findIndex(s => s.id === id);

    stages.splice(index, 1);
    this.setState({
      stages: stages,
    });
  }

  handleSubmitClicked(event) {
    event.preventDefault();
    const contest = this.state;

    if (
      contest.stages.length === 0 ||
      contest.name === '' ||
      contest.startDate === undefined ||
      contest.endDate === undefined ||
      contest.description === ''
    ) {
      toast.error('Fill all fields and add at least 1 stage');
      return;
    }

    contest.stages.forEach((stage, index) => {
      stage.name = 'Stage ' + (index + 1);
      delete stage.id;
    });

    axios
      .post('/contests/', {
        contest: {
          name: contest.name,
          description: contest.description,
          startDate: contest.startDate,
          endDate: contest.endDate,
        },

        stages: contest.stages,

        settings: {
          maxTeamSize: this.state.maxTeamSize,
          minTeamSize: this.state.minTeamSize,
        },
      })
      .then(response => {
        toast.success('created');
      });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  render() {
    return (
      <div>
        <Container>
          <h3>Create contest</h3>
          <h5>Contest name</h5>
          <Form.Group controlId='name'>
            <Form.Control
              type='text'
              placeholder='Enter contest name'
              onChange={this.handleChange.bind(this)}
            />
          </Form.Group>
          <div>
            <h5>Contest registration dates</h5>
            <Form.Row>
              <Form.Group as={Col} md='2' controlId='startDate'>
                <Form.Label>Starts</Form.Label>
                <Form.Control
                  required
                  value={this.state.startDate}
                  onChange={this.handleChange}
                  type='date'
                />
              </Form.Group>
              <Form.Group as={Col} md='2' controlId='endDate'>
                <Form.Label>Ends</Form.Label>
                <Form.Control
                  required
                  value={this.state.endDate}
                  onChange={this.handleChange}
                  type='date'
                />
              </Form.Group>
            </Form.Row>
          </div>

          <div className='mt-3 mb-3'>
            <h5>Restrictions</h5>
            <div className='mb-2'>Number of members</div>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>from</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type='number'
                value={this.state.minTeamSize}
                onChange={e => {
                  const value = e.target.value;
                  if (value > this.state.maxTeamSize || value < 1) return;
                  this.setState({ minTeamSize: value });
                }}
              />
              <InputGroup.Prepend>
                <InputGroup.Text>to</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type='number'
                value={this.state.maxTeamSize}
                onChange={e => {
                  const value = e.target.value;
                  if (value < this.state.minTeamSize) return;
                  this.setState({ maxTeamSize: value });
                }}
              />
            </InputGroup>
          </div>

          <h5>Description</h5>
          <TextEditor
            placeholder={'Enter contest description...'}
            markup='markdown'
            onChange={this.handleSaveDescription.bind(this)}
          />
          <hr />

          <Button onClick={this.handleStageAdded.bind(this)}>Add stage</Button>

          <CardDeck className='justify-content-center'>
            {this.state.stages.map((stage, index) => (
              <div>
                <Card className='mt-2'>
                  <Card.Header>
                    <div className='float-right d-flex'>
                      <Button
                        className='mb-2'
                        size='sm'
                        variant='danger'
                        btn-block
                        onClick={this.handleStageRemoved.bind(this, stage.id)}>
                        X
                      </Button>
                    </div>{' '}
                    <Card.Title>Stage {index + 1}</Card.Title>
                  </Card.Header>
                  <Card.Body className='ml-2'>
                    <div>
                      <Form.Group controlId='startDate'>
                        <Form.Label>Starts</Form.Label>
                        <Form.Control
                          required
                          value={stage.startDate}
                          onChange={this.handleStageChanged.bind(
                            this,
                            stage.id,
                          )}
                          type='date'
                        />
                      </Form.Group>
                      <Form.Group controlId='endDate'>
                        <Form.Label>Ends</Form.Label>
                        <Form.Control
                          required
                          value={stage.endDate}
                          onChange={this.handleStageChanged.bind(
                            this,
                            stage.id,
                          )}
                          type='date'
                        />
                      </Form.Group>
                      {/* <DatePicker
                        from={stage.from}
                        to={stage.to}
                        onDateChange={this.handleDateChanged.bind(
                          this,
                          stage.id,
                        )}
                      /> */}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </CardDeck>
          <hr />

          <Button onClick={this.handleSubmitClicked.bind(this)}>Save</Button>
        </Container>
      </div>
    );
  }
}

export default CreateContest;
