import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  Container,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { connect } from 'react-redux';
import {
  getTeamById,
  inviteUser,
  removeMember,
  editTeam,
  getTeamMembers,
} from '../../actions/teams';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const mapStateToProps = (state) => ({
  accounts: state.profile.accounts,
  teams: state.teams.teams,
  members: state.teams.members,
});

const mapDispatchToProps = (dispatch) => ({
  getTeam: (teamId) => dispatch(getTeamById.request(teamId)),
  editTeam: (team) => dispatch(editTeam.request(team)),
  getMembers: (teamId) => dispatch(getTeamMembers.request(teamId)),
  invite: (teamId, userId) =>
    dispatch(inviteUser.request({ teamId: teamId, userId: userId })),
  removeMember: (teamId, userId) =>
    dispatch(removeMember.request({ teamId: teamId, userId: userId })),
});

const update = (id, getTeam, getMembers) => {
  getTeam(id);
  getMembers(id);
};

const EditTeam = ({
  accounts,
  teams,
  members,
  match,
  getTeam,
  editTeam,
  getMembers,
  invite,
  removeMember,
}) => {
  const id = parseInt(match.params.id);
  const team = teams[id];
  const teamMembers = members[id] ? members[id] : [];
  const membersSize = teamMembers.length;
  const [name, setName] = useState('');

  useEffect(() => {
    update(id, getTeam, getMembers);
  }, [id, getTeam, getMembers, membersSize]);

  useEffect(() => {
    setName(team ? team.name : '');
  }, [team, setName]);

  if (!accounts || accounts.length < 2 || !team || !teamMembers) {
    return (
      <Container>
        <Skeleton />
      </Container>
    );
  }

  const onSelectChange = (selected) => {
    if (members.length > 3) {
      toast.error('Too much members');
      return;
    }

    let newMember = selected[0];
    if (!newMember) return;
    if (teamMembers.some((m) => m.userId === newMember.userId)) return;

    invite(id, newMember.id);
  };

  const handleSave = (e) => {
    e.preventDefault();
    editTeam({ ...team, name: name });
  };

  return (
    <Container>
      <h3>Edit team</h3>
      <div>Team name</div>
      <InputGroup className='mt-2'>
        <InputGroup.Prepend>
          <InputGroup.Text>Name</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          required
          type='text'
          placeholder='Enter name'
          defaultValue={name}
          onChange={(event) => setName(event.target.value)}
        />

        <InputGroup.Append>
          <Button variant='dark' onClick={handleSave}>
            Save
          </Button>
        </InputGroup.Append>
      </InputGroup>

      <br />
      <div>Invite users</div>
      <InputGroup className='mt-2'>
        <InputGroup.Prepend>
          <InputGroup.Text>Users</InputGroup.Text>
        </InputGroup.Prepend>
        <Typeahead
          id='user-search'
          placeholder='Start typing name or username...'
          labelKey={(a) => `${a.name} (${a.username})`}
          options={Object.values(accounts)}
          onChange={onSelectChange}
        />
      </InputGroup>

      <Table bordered className='mt-3'>
        <thead className='thead-dark'>
          <tr>
            <th>Name</th>
            <th>Username</th>
            <th>Status</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {teamMembers.map((member) => (
            <tr>
              {accounts[member.userId] ? (
                <>
                  <td>
                      {accounts[member.userId].name}
                  </td>
                  <td>
                    <Link to={`/profile/${member.userId}`}>
                    {accounts[member.userId].username}
                    </Link>
                    </td>
                </>
              ) : (
                <>
                  <Skeleton />
                  <td />
                </>
              )}

              <td>{member.role === 'LEADER' ? 'Leader' : member.status}</td>
              <td>
                {member.role === 'LEADER' ? (
                  ''
                ) : (
                  <Button
                    size='sm'
                    variant='dark'
                    onClick={() => removeMember(id, member.userId)}>
                    x
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTeam);
