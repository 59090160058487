import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import React, { useEffect } from 'react';

import SignTabs from './components/login/SignTabs';
import NotFound from './components/NotFound';
import NavigationBar from './components/navigation/NavigationBar';
import ViewProfile from './components/profile/ViewProfile';
import ProfileSettings from './components/profile/ProfileSettings';

import CreateTeam from './components/team/CreateTeam';
import EditTeam from './components/team/EditTeam';

import CreateContest from './components/contest/CreateContest';
import OpenContests from './components/contest/OpenContests';
import ManageContests from './components/contest/ManageContests';
import ViewTeams from './components/team/ViewTeams';
import JoinContest from './components/contest/JoinContest';
import JoinedContests from './components/contest/JoinedContests';
import ViewNews from './components/news/ViewNews';
import BlockUsers from './components/user/BlockUsers';
import CreateArticle from './components/news/CreateArticle';
import { Verification } from './components/login/Verification';
import SendMail from './components/mail/SendMail';
import AdminPage from './components/user/AdminPage';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ViewActiveUsers from './components/admin/ViewActiveUsers';
import ViewRegisteredUsers from './components/admin/ViewRegisteredUsers';
import ViewAllTeams from './components/admin/ViewAllTeams';
import EditContest from './components/contest/EditContest';
import ViewParticipantCard from './components/team/ViewParticipantCard';
import ResetPassword from './components/login/ResetPassword';
import PasswordResetProcessor from './components/login/PasswordResetProcessor';
import ViewStageTeams from './components/team/ViewStageTeams';
import Statistics from './components/admin/Statistics';
import TokenPage from './components/admin/token';
import { Provider, connect } from 'react-redux';
import { store } from './store';
import {
  AdminRoute,
  ProtectedRoute,
  UnauthorizedRoute,
} from './components/routes';
import { logout } from './actions/auth';
import SuccessRegisterPage from './components/login/SuccessRegisterPage';
import { initApp } from './actions/init';
import Theme from './Theme';
import ViewUsersActivity from './components/admin/ViewUsersActivity';

toast.configure();

const AppContainer = () => (
  <Provider store={store}>
    <Theme>
      <ConnectedApp />
    </Theme>
  </Provider>
);

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  initApp: (user) => dispatch(initApp.request(user)),
  logout: () => dispatch(logout()),
});

const App = ({ isAuth, user, logout, initApp }) => {
  useEffect(() => {
    if (isAuth) {
      initApp();
    }
  }, [isAuth, initApp]);

  return (
    <Router>
      {isAuth && <NavigationBar logout={logout} user={user} />}
      <div className='container'>
        <Switch>
          <ProtectedRoute exact path='/' component={ViewNews} />

          <AdminRoute exact path='/users/block' component={BlockUsers} />
          <ProtectedRoute
            exact
            path='/profile/:id/edit'
            component={ProfileSettings}
          />
          <ProtectedRoute exact path='/profile/:id' component={ViewProfile} />
          <AdminRoute exact path='/users/all' component={ViewActiveUsers} />
          <AdminRoute
            exact
            path='/users/registered'
            component={ViewRegisteredUsers}
          />
          <AdminRoute exact path='/users/activity' component={ViewUsersActivity}/>

          <ProtectedRoute exact path='/teams/new' component={CreateTeam} />
          <AdminRoute exact path='/teams/all' component={ViewAllTeams} />
          <ProtectedRoute exact path='/teams/users' component={ViewTeams} />
          <ProtectedRoute exact path='/teams/:id/edit' component={EditTeam} />

          <ProtectedRoute exact path='/contests/' component={OpenContests} />
          <AdminRoute exact path='/contests/new' component={CreateContest} />
          <AdminRoute exact path='/contests/edit/:id' component={EditContest} />
          <AdminRoute exact path='/contests/edit' component={ManageContests} />

          <ProtectedRoute
            exact
            path='/contests/:id/join'
            component={JoinContest}
          />
          <ProtectedRoute
            exact
            path='/contests/my-active'
            component={JoinedContests}
          />
          <ProtectedRoute
            exact
            path='/contests/:contestId/teams/:teamId'
            component={ViewParticipantCard}
          />
          <ProtectedRoute
            exact
            path='/contests/:contestId/stages/:stageId/teams'
            component={ViewStageTeams}
          />

          <AdminRoute exact path='/news/new' component={CreateArticle} />
          <Route exact path='/news' component={NotFound} />

          <AdminRoute exact path='/notify/mail' component={SendMail} />
          <AdminRoute exact path='/admin' component={AdminPage} />
          <AdminRoute exact path='/statistics' component={Statistics} />
          <AdminRoute exact path='/settings/tokens' component={TokenPage} />

          <UnauthorizedRoute
            exact
            path='/verify/:token'
            component={Verification}
          />
          <UnauthorizedRoute
            exact
            path='/auth/register/success'
            component={SuccessRegisterPage}
          />
          <UnauthorizedRoute path='/auth/:tab' component={SignTabs} />
          <UnauthorizedRoute path='/auth' component={SignTabs} />
          <UnauthorizedRoute
            exact
            path='/reset/:token'
            component={PasswordResetProcessor}
          />
          <UnauthorizedRoute exact path='/reset' component={ResetPassword} />
          <Route path='*' component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
};

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
