import React from 'react';

import {
  Envelope,
  TextLeft,
  ExclamationOctagon,
  Person,
  Tools,
  ShieldLock,
  People,
  GraphUp,
  XOctagon,
  Calendar3,
} from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

const AdminPage = () => (
  <div className='mb-3'>
    <div>
      <AccountControls />
      <NotificationControls />
      <UserModerationControls />
      {/* <TeamModerationControls /> */}
      <StatisticsControls />
      <ApiControls />
    </div>
    <small className='font-weight-light'>
      IPA v{process.env.REACT_APP_VERSION}
    </small>
  </div>
);

const ControlGroup = ({ title, controls }) => (
  <div className='mb-3'>
    <h4 className='text-center font-weight-light'>{title}</h4>
    <div className='mt-2 mb-2 d-flex'>
      {controls.map((c) => (
        <Link
          className={`btn btn-${c.style} mx-2`}
          style={{ flexBasis: '100%', textAlign: 'center' }}
          to={c.link}>
          {c.icon} {c.title}
        </Link>
      ))}
    </div>
  </div>
);

class Control {
  constructor(title, icon, link, style) {
    this.title = title;
    this.icon = icon;
    this.link = link;
    this.style = style;
  }
}

const AccountControls = () => {
  const controls = [
    new Control('Edit profile', <ShieldLock />, '/profile/1/edit', 'dark'),
  ];

  return <ControlGroup title='Admin account' controls={controls} />;
};

const NotificationControls = () => {
  const controls = [
    new Control('Send mail', <Envelope />, '/notify/mail', 'warning'),
    new Control('Publish article', <TextLeft />, '/news/new', 'success'),
  ];

  return <ControlGroup title='Communication' controls={controls} />;
};

const UserModerationControls = () => {
  const controls = [
    new Control('Activated accounts', <Person />, '/users/all', 'light'),
    new Control(
      'Registered accounts',
      <ExclamationOctagon />,
      '/users/registered',
      'secondary',
    ),
    new Control('Blacklist', <XOctagon />, '/users/block', 'danger'),
    new Control('Login activity', <Calendar3/>, '/users/activity', 'primary')
  ];

  return <ControlGroup title='User moderation' controls={controls} />;
};

const StatisticsControls = () => {
  const controls = [
    new Control('View all users', <GraphUp />, '/statistics', 'warning'),
  ];

  return <ControlGroup title='Statistics' controls={controls} />;
};

const ApiControls = () => {
  const controls = [
    new Control(
      'Manage access tokens',
      <Tools />,
      '/settings/tokens',
      'primary',
    ),
  ];

  return <ControlGroup title='API' controls={controls} />;
};

export default AdminPage;
