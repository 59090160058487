import React, { useEffect } from 'react';

import { Badge, Button, Card, ListGroup } from 'react-bootstrap';

import {
  getTeamById,
  getTeamMembers,
  acceptInvite,
  declineInvite,
} from '../../actions/teams';
import { getAccount } from '../../actions/profile';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

const mapStateToProps = (state) => ({
  user: state.auth.user,
  invites: state.teams.invites,
  teams: state.teams.teams,
  members: state.teams.members,
  accounts: state.profile.accounts,
});

const mapDispatchToProps = (dispatch) => ({
  getTeam: (id) => dispatch(getTeamById.request(id)),
  getMembers: (id) => dispatch(getTeamMembers.request(id)),
  getAccount: (id) => dispatch(getAccount.request(id)),
  acceptInvite: (id, userId) =>
    dispatch(acceptInvite.request({ teamId: id, userId: userId })),
  declineInvite: (id, userId) =>
    dispatch(declineInvite.request({ teamId: id, userId: userId })),
});

const InviteCard = ({
  id,
  user,
  teams,
  members,
  accounts,
  getTeam,
  getMembers,
  getAccount,
  acceptInvite,
  declineInvite,
}) => {
  const team = teams[id];
  const teamMembers = members[id];

  useEffect(() => {
    if (!team) {
      getTeam(id);
    }

    if (!teamMembers) {
      getMembers(id);
    }
  }, [!!team, !!teamMembers, getTeam, getMembers]);

  useEffect(() => {
    if (teamMembers) {
      teamMembers.forEach((m) => {
        const account = accounts[m.userId];
        if (!account) {
          getAccount(m.userId);
        }
      });
    }
  }, [!teamMembers, getAccount]);

  const handleAccept = () => acceptInvite(id, user.id);
  const handleDecline = () => declineInvite(id, user.id);

  if (!team || !teamMembers) {
    return <Skeleton />;
  }

  return (
    <Card className='mt-3'>
      <Card.Header className='bg-dark text-center'>
        <Card.Title className='text-light'>{team.name}</Card.Title>
      </Card.Header>

      <Card.Body>
        <ListGroup>
          {teamMembers.map((m) => (
            <ListGroup.Item>
              {accounts[m.userId] ? (
                <>
                  {accounts[m.userId].name}
                  <div className='text-muted small mr-5'>{accounts[m.userId].username}</div>
                </>
              ) : (
                <Skeleton />
              )}
              {m.status === 'INVITED' && (
                <Badge variant='success'>invited</Badge>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
      <Card.Footer className='text-center'>
        <Button size='sm' variant='danger' onClick={handleDecline}>
          Decline
        </Button>
        <Button
          className='ml-2'
          size='sm'
          variant='info'
          onClick={handleAccept}>
          Accept
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteCard);
