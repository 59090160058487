import api from '../config/api';

export const Notification = {
  // notifyAll
  // notifyContest
  // notifyList
};

export const ApiToken = {
  getAll: () => api.get('/clients/'),
  create: (name, ownerId) =>
    api.post('/clients/', { name: name, ownerId: ownerId }),
  delete: (id) => api.delete(`/clients/${id}`),
  activate: (id) => api.put(`/clients/${id}/activation`),
  deactivate: (id) => api.put(`/clients/${id}/deactivation`),
};
