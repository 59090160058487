import React, { useEffect } from 'react';
import { Badge, Button, Card, ListGroup } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getTeamMembers,
  getTeamById,
  deleteTeam,
  removeMember,
} from '../../actions/teams';
import { getAccount } from '../../actions/profile';

const mapStateToProps = (state) => ({
  user: state.auth.user,
  accounts: state.profile.accounts,
  teams: state.teams.teams,
  teamsMembers: state.teams.members,
});

const mapDispatchToProps = (dispatch) => ({
  getAccount: (id) => dispatch(getAccount.request(id)),
  getTeam: (teamId) => dispatch(getTeamById.request(teamId)),
  getMembers: (teamId) => dispatch(getTeamMembers.request(teamId)),
  deleteTeam: (teamId) => dispatch(deleteTeam.request(teamId)),
  removeMember: (teamId, userId) =>
    dispatch(removeMember.request({ teamId: teamId, userId: userId })),
});

const TeamCard = ({
  id,
  user,
  accounts,
  teams,
  teamsMembers,
  getAccount,
  getTeam,
  getMembers,
  removeMember,
  deleteTeam,
}) => {
  const team = teams[id];
  const members = teamsMembers[id] ? teamsMembers[id] : [];

  useEffect(() => {
    if (!team) {
      getTeam(id);
    }
    if (!members.length) {
      getMembers(id);
    }
  }, [id, getTeam, getMembers]);

  useEffect(() => {
    members.forEach((m) => {
      if (!accounts[m.userId]) getAccount(m.userId);
    });
  }, [getAccount, members.length]);

  const handleDelete = () => {
    if (window.confirm(`Are you sure you want delete '${team.name}'?`)) {
      deleteTeam(id);
    }
  };

  const handleLeave = () => removeMember(id, user.id);

  return (
    <div className='card mt-2 mr-2'>
      {!team || !members ? (
        <Skeleton />
      ) : (
          <>
            <Card.Header className='bg-dark text-center'>
              <Card.Title className='text-light'>
                {team.name}
                {team.status === 'LOCKED' && (
                  <Badge className='float-right' variant='success'>
                    <small>In contest</small>
                  </Badge>
                )}
              </Card.Title>
            </Card.Header>

            <Card.Body>
              <ListGroup>
                {members.map((member) => (
                  <Link to={`/profile/${member.userId}`} className='btn btn-light text-left'><ListGroup.Item key={member.id}>
                    {member.role === 'LEADER' && (
                      <div className='float-right'>
                        <Badge variant='danger'>leader</Badge>
                      </div>
                    )}
                    {member.status === 'INVITED' && (
                      <div className='float-right'>
                        <Badge variant='success'>invited</Badge>
                      </div>
                    )}
                    {accounts[member.userId] && (
                      <>
                        {accounts[member.userId].name}
                        <div className='small mr-5' to={`/profile/${member.userId}`}>
                          {accounts[member.userId].username}
                        </div>
                      </>
                    )}
                  </ListGroup.Item>
                  </Link>
                ))}
              </ListGroup>
            </Card.Body>
            <Card.Footer className='text-center'>
              {team.status === 'OPEN' && (
                <>
                  {isLeader(user, members) ? (
                    <div>
                      <Button size='sm' variant='danger' onClick={handleDelete}>
                        Delete
                    </Button>
                      <Link
                        className='btn btn-success btn-sm ml-2'
                        to={`/teams/${id}/edit`}>
                        Edit
                    </Link>
                    </div>
                  ) : members.some(m => m.userId === user.id) && (
                    <Button size='sm' variant='danger' onClick={handleLeave}>
                      Leave
                    </Button>
                  )}
                </>
              )}
            </Card.Footer>
          </>
        )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamCard);

function isLeader(user, members) {
  const searchResult = members.find((m) => m.userId === user.id);
  return searchResult !== undefined && searchResult.role === 'LEADER';
}
