import {
  GET_NEWS,
  CREATE_ARTICLE,
  EDIT_ARTICLE,
  DELETE_ARTICLE,
} from '../actions/news';

import { REQUEST, FAILURE, SUCCESS } from '../actions/helpers';
import { toast } from 'react-toastify';

const defaultState = {
  news: [],
  isLoading: false,
  error: '',
};

const newsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_NEWS[REQUEST]:
    case CREATE_ARTICLE[REQUEST]:
    case EDIT_ARTICLE[REQUEST]:
    case DELETE_ARTICLE[REQUEST]:
      return { ...state, isLoading: true };
    case GET_NEWS[FAILURE]:
    case CREATE_ARTICLE[FAILURE]:
    case EDIT_ARTICLE[FAILURE]:
    case DELETE_ARTICLE[FAILURE]:
      return { ...state, isLoading: false, error: action.payload };
    case CREATE_ARTICLE[SUCCESS]:
    case EDIT_ARTICLE[SUCCESS]:
    case DELETE_ARTICLE[SUCCESS]:
      toast.success(action.payload.response);
      return { ...state, isLoading: false };
    case GET_NEWS[SUCCESS]:
      const news = action.payload.response.sort((a, b) => b.id - a.id);
      return { ...state, isLoading: false, news: news };

    default:
      return state;
  }
};

export default newsReducer;
