import React from 'react';
import {
  Badge,
  Button,
  Container,
  InputGroup,
  Table,
  Form,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { PersonalData } from '../../endpoints/users';
import { getAccounts, getPersonalData } from '../../actions/profile';
import { connect } from 'react-redux';
import UserRow from './UserRow';
import PageNavigation from './PageNavigation';
import { getNumberOfPages } from '../../tools';
import ExportUsersButton from './ExportUsersButton';
import { Reports } from '../../endpoints/reports';
import { generateCsv } from '../../tools';

const mapStateToProps = (state) => ({
  sortedAccounts: state.profile.activated.sort((a, b) => b - a),
  accounts: state.profile.accounts,
  accountsTotal: state.profile.activated.length,
  personalData: state.profile.personalData,
});

const mapDispatchToProps = (dispatch) => ({
  getAllAccounts: () => dispatch(getAccounts.request()),
  getPersonalData: (id) => dispatch(getPersonalData.request(id)),
});


const requestFullInfo = () => {
  Reports.getAll().then((response) => {
    const header =
      'ID,USERNAME,REGISTRATION DATE,NAME,EMAIL,BIRTHDAY,PHONE NUMBER,GENDER,COUNTRY OF RESIDENCE,UNIVERSITY,STUDY PROGRAM,LEVEL OF STUDY,COMPANY';
    const data = response
      .map((obj) => {
        let u = obj.account;
        let pd = obj.personalData;
        return [
          u.id,
          u.username,
          pd.registrationDate,
          u.name,
          pd.email,
          pd.birthday,
          pd.phoneNumber,
          pd.gender,
          pd.countryOfResidence,
          pd.university,
          pd.studyProgram,
          pd.levelOfStudy,
          pd.company,
        ].join();
      })
      .join('\n');
    generateCsv(`${header}\n${data}`);
  });
};


class ViewActiveUsers extends React.Component {
  state = {
    currentPage: 1,
    numberOfPages: 1,
    resultsOnPage: 50,
    enableActions: false,
    searchValue: '',
    searchType: 'username',
    search: '',
    selected: [],
  };

  setSelected = (selected) => {
    const numOfPages = getNumberOfPages(
      selected.length,
      this.state.resultsOnPage,
    );
    this.setState({
      currentPage: 1,
      numberOfPages: numOfPages,
      selected: selected,
    });
  };

  updateAccountList = () => {
    this.props.getAllAccounts();
  };

  static getDerivedStateFromProps(props, state) {
    if (state.searchValue === '') {
      const selected = props.sortedAccounts
      const numOfPages = getNumberOfPages(
        selected.length,
        state.resultsOnPage,
      );
      return {
        currentPage: 1,
        numberOfPages: numOfPages,
        selected: selected
      }
    }
    return state;
  }

  componentDidMount() {
    if (!this.props.accountsTotal) {
      this.updateAccountList();
    }
  }

  handlePageChange = (num) => {
    if (num > 0 && num <= this.state.numberOfPages) {
      this.setState({ currentPage: num });
    }
  };

  handleSearch = (searchType, searchValue) => {
    const { accounts, sortedAccounts } = this.props;
    if (!searchValue) {
      this.setSelected(sortedAccounts);
      return;
    }
    let selected = [];
    switch (searchType) {
      case 'username':
        selected = Object.values(accounts)
          .filter((u) => u.username.toLowerCase().match(searchValue))
          .map((a) => a.id);
        this.setSelected(selected);
        break;
      case 'name':
        selected = Object.values(accounts)
          .filter((u) => u.name.toLowerCase().match(searchValue))
          .map((a) => a.id);
        this.setSelected(selected);
        break;
      case 'email':
        PersonalData.searchByEmail(searchValue).then((r) => {
          this.setSelected(
            r
              .filter((d) => sortedAccounts.some((a) => d === a))
              .sort((a, b) => b - a),
          );
        });
        break;
      default:
        break;
    }
  };

  render() {
    const total = this.props.accountsTotal;
    const {
      searchType,
      searchValue,
      currentPage,
      resultsOnPage,
      numberOfPages,
      enableActions,
    } = this.state;

    const selected = searchValue ? this.state.selected : this.props.sortedAccounts

    const idsToDisplay = selected.slice(
      (currentPage - 1) * resultsOnPage,
      currentPage * resultsOnPage,
    );


    return (
      <Container>
        <h3>Activated users</h3>
        <h5>
          <div>
            <Badge variant='success'>Total count: {total}</Badge>
          </div>
          <div>
            <Badge variant='info'>Search results: {selected.length}</Badge>
          </div>
          <div>
            <Badge variant='warning'>Shown items: {idsToDisplay.length}</Badge>
          </div>
        </h5>
        <Form.Check
          checked={enableActions}
          onChange={() => this.setState({ enableActions: !enableActions })}
          type='checkbox'
          label='Enable actions'
        />

        <ExportUsersButton requestFullInfo={requestFullInfo} />

        <InputGroup className='w-100'>
          <InputGroup.Prepend>
            <InputGroup.Text>Search by</InputGroup.Text>
          </InputGroup.Prepend>
          <DropdownButton variant='secondary' title={this.state.searchType}>
            <Dropdown.Item
              onClick={() => this.setState({ searchType: 'username' })}>
              username
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => this.setState({ searchType: 'name' })}>
              name
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => this.setState({ searchType: 'email' })}>
              email
            </Dropdown.Item>
          </DropdownButton>
          <Form.Control
            value={searchValue}
            onChange={(e) => this.setState({ searchValue: e.target.value })}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                this.handleSearch(searchType, searchValue);
              }
            }}
            type='text'
            placeholder={`Enter ${this.state.searchType}`}
          />
          <InputGroup.Append>
            <Button
              variant='secondary'
              onClick={() => this.handleSearch(searchType, searchValue)}>
              <Search />
            </Button>
          </InputGroup.Append>
        </InputGroup>
        <Table className='mt-3' striped bordered hover>
          <thead className='thead-dark'>
            <tr>
              <th>id</th>
              <th>username</th>
              <th>name</th>
              <th>email</th>
              <th>country</th>
              <th>birthday</th>
              <th>occupation</th>
              <th>registered</th>
              {enableActions && <th>actions</th>}
            </tr>
          </thead>
          <tbody>
            {idsToDisplay.map((id) => (
              <UserRow id={id} key={id} showControls={enableActions} />
            ))}
          </tbody>
        </Table>
        <PageNavigation
          total={numberOfPages}
          current={currentPage}
          onChange={this.handlePageChange}
        />
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewActiveUsers);
